import axios from "axios"

export default class CompaniesService {
  getCompanies() {
    return axios.get("companies")
  }

  createCompany(company: any) {
    return axios.post("companies", company)
  }

  deleteCompany(id: string) {
    return axios.delete("companies/" + id)
  }

  editCompany(id: string, company: any) {
    return axios.put("companies/" + id, company)
  }
}
