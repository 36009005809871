import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ccf1d32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-col code" }
const _hoisted_2 = { class: "table-col alias" }
const _hoisted_3 = { class: "table-col" }
const _hoisted_4 = { class: "table-col" }
const _hoisted_5 = { class: "table-col code" }
const _hoisted_6 = {
  key: 0,
  class: "text-center inline-block toolkit"
}
const _hoisted_7 = {
  key: 1,
  class: "unknown"
}
const _hoisted_8 = { class: "table-col col-fixed justify-content-end flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkLabel = _resolveComponent("LinkLabel")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ComputerSyncTime = _resolveComponent("ComputerSyncTime")!
  const _component_ComputerUpdateTime = _resolveComponent("ComputerUpdateTime")!
  const _component_ComputerRunConfiguration = _resolveComponent("ComputerRunConfiguration")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LinkLabel, {
        id: $props.computer.id,
        value: $props.computer.serial,
        type: "computer"
      }, null, 8, ["id", "value"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: { name: 'computerProfile', params: { computer_id: $props.computer.id } }
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.computer.alias), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ComputerSyncTime, {
        "absolute-date-time": $setup.absoluteDateTime(new Date($props.computer.syncTime)),
        computer: $props.computer
      }, null, 8, ["absolute-date-time", "computer"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ComputerUpdateTime, {
        "absolute-date-time": $setup.absoluteDateTime(new Date($props.computer.versionUpdateTime)),
        computer: $props.computer
      }, null, 8, ["absolute-date-time", "computer"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      ($props.computer.toolkitVersion)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($props.computer.toolkitVersion), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_7, "Version unknown"))
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_ComputerRunConfiguration, {
        computer: $props.computer,
        "show-status-message": false,
        onValueChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('onComputerChanged')))
      }, null, 8, ["computer"])
    ])
  ], 64))
}