
import { computed, defineComponent, ref } from "vue"
import ComputerService from "@/services/ComputerService"
import RunConfigurationTag from "@/components/releases/run-configs/RunConfigurationTag.vue"
import { useConfirm } from "primevue/useconfirm"

export default defineComponent({
  name: "ComputerVersion",
  components: { RunConfigurationTag },
  props: {
    computer: Object,
    filteringEnabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["value-changed", "show-filter-context-menu"],
  setup(props, { emit }) {
    const confirm = useConfirm()
    const editOverlayComponent = ref()
    const targetVersion = ref()
    const computerService = new ComputerService()
    const upgradeReady = computed(() => {
      return (
        props.computer.targetVersion &&
        ((props.computer.currentVersion &&
          props.computer.targetVersion.createdTime.localeCompare(
            props.computer.currentVersion.createdTime
          ) > 0) ||
          !props.computer.currentVersion)
      )
    })

    const downgradeReady = computed(() => {
      return (
        props.computer.targetVersion &&
        props.computer.currentVersion &&
        props.computer.targetVersion.createdTime.localeCompare(
          props.computer.currentVersion.createdTime
        ) < 0
      )
    })

    function onEdit(event: any) {
      editOverlayComponent.value.toggle(event)
      event.stopPropagation()
    }

    function onCancelEdit() {
      editOverlayComponent.value.toggle()
    }

    function onConfirmEdit() {
      if (
        props.computer.targetVersion?.version.split(".")[1] >
        targetVersion.value.version.split(".")[1]
      ) {
        confirm.require({
          header: "Please confirm",
          message:
            "Are you sure you want to revert to a major older version ? This might include database changes.",
          accept: () =>
            computerService
              .setTargetRunConfiguration(targetVersion.value.version, [props.computer.id])
              .then(() => {
                emit("value-changed")
              }),
        })
      } else {
        computerService
          .setTargetRunConfiguration(targetVersion.value.version, [props.computer.id])
          .then(() => {
            valueHasChanged()
          })
      }
    }

    function valueHasChanged() {
      editOverlayComponent.value.toggle()
      emit("value-changed")
    }

    function showFilterContextMenu(event: any, version: string, versionType: string) {
      if (props.filteringEnabled) {
        emit("show-filter-context-menu", event, versionType, version)
      }
    }

    return {
      upgradeReady,
      editOverlayComponent,
      targetVersion,
      onEdit,
      onCancelEdit,
      onConfirmEdit,
      downgradeReady,
      showFilterContextMenu,
    }
  },
})
