
import ReportService from "@/services/ReportService"
import { ref, watch, defineComponent } from "vue"
import { useConfirm } from "primevue/useconfirm"
import { useToast } from "primevue/usetoast"
import useViewerUtils from "@/utils/viewer"
import axios from "axios"
import { ViewerLoadTaskQuality } from "@/models/viewer/ViewerLoadTaskQuality"
import { useStore } from "vuex"
import LinkLabel from "@/components/navigation/LinkLabel.vue"
import { state } from "@/utils/state"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"

export default defineComponent({
  name: "ReportBackgroundItem",
  props: {
    template: {
      type: Object,
      default: null,
    },
  },
  components: { LinkLabel, PrimaryButton },
  setup(props, { emit }) {
    const reportService = new ReportService()
    const showEdit = ref(false)
    let templateRef = ref()
    const confirm = useConfirm()
    const toast = useToast()
    const { loadImage } = useViewerUtils()
    const store = useStore()
    const imageAlias = ref("")
    const pdfImageUrl = ref(null)
    const form = ref({
      alias: "",
      type: "",
      language: "",
    })
    const roles = Object.values(store.getters.getRoles)

    function checkAdminStatus() {
      if (
        props.template.userId == null &&
        props.template.organizationId == null &&
        props.template.computerId == null
      ) {
        if (
          roles.includes("ADMIN") ||
          roles.includes("SUPER_SUPPORT") ||
          roles.includes("SUPER_ADMIN")
        ) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }

    watch(
      () => props.template,
      () => {
        loadTemplateData()
      }
    )

    loadTemplateData()

    function loadTemplateData() {
      form.value.alias = props.template.alias
      form.value.type = props.template.type
      form.value.language = props.template.language

      axios.defaults.baseURL = process.env.VUE_APP_VSOL_API

      loadImage(
        `${axios.defaults["baseURL"]}templates/${props.template.id}/pdf`,
        store.getters.getToken,
        ViewerLoadTaskQuality.THUMBNAIL,
        (progress: any) => {}
      ).then(
        (result) => {
          pdfImageUrl.value = result
        },
        () => {}
      )
    }

    function saveChanges() {
      reportService.updateTemplateData(props.template.id, form.value).then(() => {
        templateChanged()
      })
    }

    function templateChanged() {
      showEdit.value = false
      loadTemplateData()
      emit("template-changed")
    }

    function deleteTemplate(event: any) {
      confirm.require({
        target: event.currentTarget,
        message: "Are you sure you want to delete this background ?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          reportService.deleteTemplate(props.template.id).then(() => {
            emit("template-changed")
            toast.add({
              severity: "success",
              summary: "Successfully deleted background",
              life: 3000,
            })
          })
        },
      })
    }

    return {
      showEdit,
      deleteTemplate,
      templateChanged,
      templateRef,
      pdfImageUrl,
      imageAlias,
      form,
      saveChanges,
      state,
      roles,
      checkAdminStatus,
    }
  },
})
