
import {
  ref,
  reactive,
  watch,
  defineComponent,
  onMounted,
  onUnmounted,
  nextTick,
  computed,
} from "vue"
import ResultSet from "@/models/ResultSet"
import { useConfirm } from "primevue/useconfirm"
import RenderInstructionService from "@/services/RenderInstructionService"
import { useRoute } from "vue-router"
import RenderInstructionsMainFilter from "@/components/settings/render-instructions/RenderInstructionsMainFilter.vue"
import Button from "primevue/button"
import ConfirmPopup from "primevue/confirmpopup"
import OrganizationService from "@/services/OrganizationService"
import Column from "primevue/column"
import DataTable from "primevue/datatable"
import LinkLabel from "@/components/navigation/LinkLabel.vue"
import OrganizationUserComputerFilter from "@/components/data/OrganizationUserComputerFilter.vue"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"

export default defineComponent({
  components: {
    RenderInstructionsMainFilter,
    LinkLabel,
    OrganizationUserComputerFilter,
    PrimaryButton,
  },
  props: {
    service: {
      type: RenderInstructionService,
    },
    editFields: {
      type: Object,
    },
    columns: {
      type: Object,
      required: true,
    },
  },
  setup(props: any) {
    let part = 0
    const route = useRoute()
    const scrollComponent = ref()
    const loadedAllData = ref(false)
    const loadingPart = ref(false)
    const items = ref([])
    const selectedItems = ref()
    const confirm = useConfirm()
    const selectedOrganization = ref()
    const selectedUser = ref()
    const selectedComputer = ref()
    const selectedSpecies = ref()
    const selectedAnatomy = ref()
    const selectedDirection = ref()
    const selectedLeg = ref()
    const selectedStature = ref()
    const orgService = OrganizationService.getInstance()
    const loading = ref(true)
    const addDialog = ref(false)
    const editDialog = ref(false)
    const duplicateDialog = ref(false)
    const riImportDialog = ref(false)
    const showImportButton = computed(() => !!route.params.organization_id)

    onMounted(() => {
      loading.value = false
      scrollComponent.value = document.querySelector(".p-datatable-wrapper")
      if (scrollComponent.value) {
        scrollComponent.value.addEventListener("scroll", checkVisible, { passive: true })
      }
      window.addEventListener("resize", checkVisible, { passive: true })
    })

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener("scroll", checkVisible)
      }

      window.removeEventListener("resize", checkVisible)
    })

    function checkVisible() {
      if (loadedAllData.value) return
      if (loadingPart.value) return

      if (
        Math.ceil(scrollComponent.value.scrollTop * 1.3) >=
        scrollComponent.value.scrollHeight - scrollComponent.value.clientHeight
      ) {
        loadPart(
          part,
          "computer.id,desc&sort=user.id,desc&sort=organization.id,desc&sort=species,desc&sort=anatomy,desc&sort=direction,desc&sort=leg,desc&sort=stature,desc"
        )
      }
    }

    //when opening cvinput on organization level, the selectedOrganization first needs to load
    if (route.params.organization_id) {
      orgService.getOrganization(route.params.organization_id as string).then((org) => {
        selectedOrganization.value = org
        loading.value = false
      })
    } else {
      loading.value = false
    }

    checkIfNotInOrg()
    function checkIfNotInOrg() {
      if (!route.fullPath.includes("org")) {
        loadPart(
          0,
          "computer.id,desc&sort=user.id,desc&sort=organization.id,desc&sort=species,desc&sort=anatomy,desc&sort=direction,desc&sort=leg,desc&sort=stature,desc"
        )
      }
    }

    function loadPart(part: Number, sort: any) {
      loadingPart.value = true
      props.service
        .getRules(
          selectedComputer.value ? selectedComputer.value.id : null,
          selectedUser.value ? selectedUser.value.id : null,
          selectedOrganization.value ? selectedOrganization.value.id : null,
          selectedSpecies.value ? selectedSpecies.value : null,
          selectedAnatomy.value ? selectedAnatomy.value : null,
          selectedDirection.value ? selectedDirection.value : null,
          selectedLeg.value ? selectedLeg.value : null,
          selectedStature.value ? selectedStature.value : null,
          part,
          sort
        )
        .then((result: ResultSet<any>) => {
          onPartLoaded(part, sort, result)
        })
    }

    function onPartLoaded(loadedPart: Number, sort: any, result: ResultSet<any>) {
      loadingPart.value = false
      loadedAllData.value = !result.moreToCome

      if (result.rows.length == 0) {
        return
      }

      part++
      result.rows.map(function (element) {
        let item = { selected: false, data: element }
        items.value.push(item)
      })

      nextTick().then(() => {
        checkVisible()
      })
    }

    function reload() {
      loadingPart.value = true
      part = 0
      selectedItems.value = null
      loadedAllData.value = false
      items.value = []
      loadPart(
        part,
        "computer.id,desc&sort=user.id,desc&sort=organization.id,desc&sort=species,desc&sort=anatomy,desc&sort=direction,desc&sort=leg,desc&sort=stature,desc"
      )
    }

    function onNew() {
      addDialog.value = true
    }

    function onEdit() {
      if (selectedItems.value) {
        editDialog.value = true
      }
    }

    function onDuplicate() {
      if (selectedItems.value) {
        duplicateDialog.value = true
      }
    }

    watch(
      () => selectedOrganization.value,
      (newValue, oldValue) => {
        if (scrollComponent.value) {
          reload()
        }
      }
    )
    watch(
      () => selectedUser.value,
      (newValue, oldValue) => reload()
    )
    watch(
      () => selectedComputer.value,
      (newValue, oldValue) => reload()
    )
    watch(
      () => selectedSpecies.value,
      (newValue, oldValue) => reload()
    )
    watch(
      () => selectedAnatomy.value,
      (newValue, oldValue) => reload()
    )
    watch(
      () => selectedDirection.value,
      (newValue, oldValue) => reload()
    )
    watch(
      () => selectedLeg.value,
      (newValue, oldValue) => reload()
    )
    watch(
      () => selectedStature.value,
      (newValue, oldValue) => reload()
    )

    function onConfirmDelete(event: any) {
      confirm.require({
        target: event.currentTarget,
        message:
          "Do you want to delete " + selectedItems.value.length + " render instruction rules?",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: deleteSelected,
      })
    }

    function deleteSelected() {
      let idsToDelete = selectedItems.value.map((item: any) => item.data.id)

      props.service.deleteMultiple(idsToDelete).then(() => {
        reload()
      })
    }

    return {
      selectedItems,
      selectedUser,
      selectedOrganization,
      selectedComputer,
      selectedSpecies,
      selectedAnatomy,
      selectedDirection,
      selectedLeg,
      selectedStature,
      loadPart,
      onNew,
      onEdit,
      onConfirmDelete,
      onDuplicate,
      loading,
      items,
      addDialog,
      editDialog,
      duplicateDialog,
      reload,
      riImportDialog,
      showImportButton,
    }
  },
})
