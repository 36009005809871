
import { defineComponent } from "vue"
import LinkLabel from "../navigation/LinkLabel.vue"
export default defineComponent({
  components: { LinkLabel },
  props: {
    user: Object,
  },
  setup(props) {},
})
