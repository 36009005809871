import { ViewerLoadTaskQuality } from "@/models/viewer/ViewerLoadTaskQuality"

export default function useViewerUtils() {
  /**
   * Loads an image with progress callback.
   *
   * The `onprogress` callback will be called by XMLHttpRequest's onprogress
   * event, and will receive the loading progress ratio as an whole number.
   * However, if it's not possible to compute the progress ratio, `onprogress`
   * will be called only once passing -1 as progress value. This is useful to,
   * for example, change the progress animation to an undefined animation.
   *
   * https://stackoverflow.com/questions/14218607/javascript-loading-progress-of-an-image/22593907#22593907
   *
   * @param  {string}   imageUrl   The image to load
   * @param  {Function} onprogress
   * @return {Promise}
   */
  async function loadImage(
    imageUrl: string,
    token: string,
    quality: ViewerLoadTaskQuality,
    onprogress: any,
    headers?: any
  ) {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest()
      var notifiedNotComputable = false

      xhr.open("GET", imageUrl, true)
      xhr.setRequestHeader("Authorization", "Bearer " + token)
      if (headers && headers.length) {
        headers.forEach((header: any) => {
          xhr.setRequestHeader(header[0], header[1])
        })
      }
      xhr.responseType = "arraybuffer"

      xhr.onprogress = function (ev) {
        if (ev.lengthComputable) {
          onprogress((ev.loaded / ev.total) * 100)
        } else {
          if (!notifiedNotComputable) {
            notifiedNotComputable = true
            onprogress(-1)
          }
        }
      }

      xhr.onloadend = function () {
        if (!xhr.status.toString().match(/^2/)) {
          reject(xhr)
        } else {
          if (!notifiedNotComputable) {
            onprogress(100)
          }

          let options: any = { type: null }
          var headers = xhr.getAllResponseHeaders()
          var m = headers.match(/^Content-Type\:\s*(.*?)$/im)

          if (m && m[1]) {
            //options.type = "image/jpeg";
            options.type = m[1]
          }

          let blob = new Blob([this.response], options)

          resolve(window.URL.createObjectURL(blob))
        }
      }

      xhr.send()
    })
  }

  function str2ab(str: string) {
    //  return new TextEncoder().encode(str).buffer;

    //  var buf = new ArrayBuffer(str.length*2); // 2 bytes for each char
    var bufView = new Uint8Array(str.length)
    for (var i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i)
    }
    return bufView
  }

  return {
    loadImage,
  }
}
