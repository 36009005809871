import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-661117b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-msg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.errors, (error) => {
    return (_openBlock(), _createElementBlock("ul", {
      key: error.$uid,
      class: "inline-error"
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(error.$message), 1)
    ]))
  }), 128))
}