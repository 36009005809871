<!--suppress XmlInvalidId -->
<template>
  <h2>Change password</h2>
  <br>
  <div class="card shadow-3 p-3">
    <form
      class="formgrid grid"
      @submit.prevent="onSubmit"
    >
      <div class="field col-12 md:col-6">
        <label for="password">New Password</label>
        <InputText
          id="password"
          v-model="v$.password.$model"
          type="password"
          class="w-full"
          autocomplete="new-password"
        />
        <inline-form-error
          v-if="v$.password.$error"
          :errors="v$.password.$errors"
        />
      </div>
      <div class="field col-12 md:col-6">
        <label for="password-confirm">Confirm new password</label>
        <InputText
          id="password-confirm"
          v-model="v$.passwordConfirm.$model"
          type="password"
          class="w-full"
          autocomplete="new-password"
        />
        <inline-form-error
          v-if="v$.passwordConfirm.$error"
          :errors="v$.passwordConfirm.$errors"
        />
      </div>
      <div class="field col-12">
        <PrimaryButton
          class="mr-3"
          :label="'Save'"
        />
        <InlineMessage
          v-if="done"
          severity="success"
        >
          Done
        </InlineMessage>
      </div>
    </form>
  </div>
</template>

<script langs="ts">
import { ref } from "vue"
import { helpers, required, email, minLength, maxLength, sameAs } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import UserService from "@/services/UserService"
import EmployeeService from "@/services/EmployeeService"

export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  emits: ["submit", "cancel"],
  setup(props, { emit }) {
    const service = new EmployeeService()
    const done = ref(false)
    const form = ref({
      password: "",
      passwordConfirm: "",
    })

    const passwordEqual = () => {
      return form.value.password == "" || form.value.password == form.value.passwordConfirm
    }

    const rules = {
      password: {
        required: helpers.withMessage("The password is required", required),
        minLength: minLength(8),
        containsPasswordRequirement: helpers.withMessage(
          () => `The password requires an uppercase, lowercase, number and special character`,
          (value) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(value)
        ),
      },
      passwordConfirm: {
        required: helpers.withMessage("Both passwords must be the same", passwordEqual),
      },
    }

    const v$ = useVuelidate(rules, form)

    function onSubmit() {
      v$.value.$touch()

      if (v$.value.$invalid) return

      service.changePassword(form.value.password).then(() => {
        form.value.password = ""
        form.value.passwordConfirm = ""
        v$.value.$reset()
        onDone()
      })
    }

    function onDone() {
      done.value = true
      setTimeout(() => {
        done.value = false
      }, 2500)
    }

    return {
      done,
      v$,
      onSubmit,
      onDone,
    }
  },
}
</script>
