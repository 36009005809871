import { vsolCompanyId } from "@/constants/companies"
import { state } from "@/utils/state"

export function isCurrentUrlPublic() {
  return window.location.pathname.includes("/quotationlink/")
}

export function checkRole(to: any, roles: any) {
  let permissionGrantedCompanies = false
  let permissionGrantedRoles = false

  if (to.meta?.companies && to.meta.companies.length) {
    to.meta.companies.forEach((company: any) => {
      if (company === "VSOL" && state.isVsolEmployee) {
        permissionGrantedCompanies = true
      }
    })
  } else {
    permissionGrantedCompanies = true
  }

  if (to.meta?.roles && to.meta.roles.length && roles) {
    permissionGrantedRoles = to.meta.roles.some((role: any) => Object.values(roles).includes(role))
  } else {
    permissionGrantedRoles = true
  }

  return permissionGrantedCompanies && permissionGrantedRoles
}

export function checkPermissionsOfElements(records: any, roles: any, isVsolEmployee: boolean) {
  let previousObservedElement: any = null
  records.forEach((record: any) => {
    // if the record is the element with the permissions attribute
    if (record.target.getAttribute("permissions")) {
      const permissionsArray = JSON.parse(record.target.getAttribute("permissions"))
      if (
        (roles && Object.values(roles).every((role: any) => permissionsArray.includes(role))) ||
        (permissionsArray.includes("VSOL") && !isVsolEmployee)
      ) {
        record.target.style.display = "none"
      }
    }
    // if the record is a parent element of the element(s) with the permissions attribute
    else {
      // check if previous record isn't the same as new record because when the record doesn't have the permissions attribute itself, the parent element is given and
      // if there are more than 1 elements with the permissions attribute in that parent element, the parent element is given x times an element with the attribute is present in it.
      if (previousObservedElement !== record.target) {
        previousObservedElement = record.target
        // get array of elements with the attribute that are present in parent element + loop over it
        const foundElsWithPermission = previousObservedElement.querySelectorAll("[permissions]")
        if (foundElsWithPermission && foundElsWithPermission.length) {
          foundElsWithPermission.forEach((el: any) => {
            const permissionsArray = JSON.parse(el.getAttribute("permissions"))
            if (
              (roles &&
                Object.values(roles).every((role: any) => permissionsArray.includes(role))) ||
              (permissionsArray.includes("VSOL") && !isVsolEmployee)
            ) {
              el.style.display = "none"
            }
          })
        }
      }
    }
  })
}
