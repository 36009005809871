import User from "@/models/User"
import _ from "lodash"
import { format, isAfter, isSameDay } from "date-fns"
import Company from "@/models/Company"

export class Task {
  constructor(
    public assignees: Assignee[],
    public calendar: boolean,
    public category: string,
    public numberOfChildren: number,
    public completedBy: User,
    public completionTime: string,
    public computer: any,
    public createdBy: User,
    public createdTime: Date,
    public deadline: string,
    public deleted: boolean,
    public description: string,
    public id: string,
    public labels: Label[],
    public marked: boolean,
    public numberOfDependencies: number,
    public organization: Organization,
    public parent: ParentTask,
    public plannedDate: string,
    public priority: string,
    public subject: string,
    public updatedTime: Date,
    public watchers: User[]
  ) {}

  static readonly defaultTask = new Task(
    [],
    false,
    null,
    0,
    null,
    null,
    null,
    null,
    new Date(),
    null,
    false,
    "",
    null,
    [],
    false,
    0,
    null,
    null,
    format(new Date(), "yyyy-MM-dd"),
    "NONE",
    "",
    null,
    []
  )

  static fromDefault(): Task {
    return _.cloneDeep(this.defaultTask)
  }

  isCompleted() {
    return this.completedBy !== null && this.completionTime != null
  }

  isOverdue(parentDeadLine?: string) {
    if (this.isCompleted()) return false

    const ownPlanned = this.plannedDate ? new Date(this.plannedDate) : null
    const ownDeadline = this.deadline ? new Date(this.deadline) : null
    const _parentDeadLine = parentDeadLine ? new Date(parentDeadLine) : null
    let deadline = ownDeadline

    // Use parent deadline to compare if present
    if (!ownDeadline && _parentDeadLine) {
      deadline = _parentDeadLine
    }

    if (deadline) {
      deadline.setHours(16)
      deadline.setMinutes(30)
      deadline.setSeconds(0)
    }

    if (!ownPlanned || !deadline) return false

    return isSameDay(ownPlanned, deadline) || isAfter(ownPlanned, deadline)
  }

  isPriority() {
    return this.priority && this.priority === "URGENT"
  }

  toString() {
    return this.id + ": " + this.subject
  }
}

export class ParentTask {
  constructor(public sequenceNumber: number, public parentId: ParentId) {}
}

export class ParentId {
  constructor(public id: string) {}
}

export interface Organization {
  id: string
  code: string
  name: string
  company: Company
}

export interface Assignee {
  seen: boolean
  user: User
}

export interface Label {
  alias: string
  code: string
  createdTime: Date
  deleted: boolean
  id: string
  ranking: number
  updatedTime: Date
}

export interface SelectedLabel extends Label {
  selected: boolean
}

export interface WeekDay {
  day: string
  date: string
  rawDate: Date
  dragOver?: boolean
}
