export default {
  state: () =>
    <any>{
      orgHistory: [],
      userHistory: [],
      computerHistory: [],
    },

  mutations: {
    addOrg(state: any, organization: any) {
      const uniqueOrgs = state.orgHistory.filter((org: any) => org.id !== organization.id)
      state.orgHistory = [organization].concat(uniqueOrgs)
    },

    addUser(state: any, user: any) {
      const uniqueUsers = state.userHistory.filter((usr: any) => usr.id !== user.id)
      state.userHistory = [user].concat(uniqueUsers)
    },

    addComputer(state: any, computer: any) {
      const uniqueComputers = state.computerHistory.filter((comp: any) => comp.id !== computer.id)
      state.computerHistory = [computer].concat(uniqueComputers)
    },
  },

  actions: {
    setNewOrgHistory(context: any, organization: any) {
      const newOrg = {
        id: organization.id,
        code: organization.code,
        name: organization.name,
        companyCode: organization.company?.code,
      }
      context.commit("addOrg", newOrg)
    },

    setNewUserHistory(context: any, user: any) {
      const newUser = {
        id: user.id,
        username: user.username,
      }
      context.commit("addUser", newUser)
    },

    setNewComputerHistory(context: any, computer: any) {
      const newComp = {
        id: computer.id,
        serial: computer.serial,
        alias: computer.alias,
      }
      context.commit("addComputer", newComp)
    },
  },

  getters: {
    getOrgHistory(state: any) {
      return state.orgHistory
    },

    getLastOrgs(state: any) {
      return state.orgHistory.slice(0, 30)
    },

    getLastXOrgs: (state: any) => (x: number) => {
      return state.orgHistory.slice(Math.max(state.orgHistory.length - x, 0))
    },

    getUserHistory(state: any) {
      return state.userHistory
    },

    getLastUsers(state: any) {
      return state.userHistory.slice(Math.max(state.userHistory.length - 30, 0))
    },

    getLastXUsers: (state: any) => (x: number) => {
      return state.userHistory.slice(Math.max(state.userHistory.length - x, 0))
    },

    getComputerHistory(state: any) {
      return state.computerHistory
    },

    getLastComputers(state: any) {
      return state.computerHistory.slice(Math.max(state.computerHistory.length - 30, 0))
    },

    getLastXComputers: (state: any) => (x: number) => {
      return state.computerHistory.slice(Math.max(state.computerHistory.length - x, 0))
    },
  },
}
