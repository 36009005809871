
import Mark from "mark.js"
import SettingsCollectionRecordValues from "./SettingsCollectionRecordValues.vue"
import SettingsCollectionForm from "./SettingsCollectionForm.vue"
import { ref, watch } from "vue"

export default {
  props: {
    subCategory: {
      type: Object,
      default: null,
    },
    query: {
      type: String,
      default: "",
    },
    user: {
      type: Object,
      default: null,
    },
    computer: {
      type: Object,
      default: null,
    },
    organization: {
      type: Object,
      default: null,
    },
    searchValue: {
      type: String,
      default: null,
    },
    permissions: {
      type: Object,
      default: null,
    },
  },
  emits: ["open-setting"],
  setup(props: any, { emit }: any) {
    watch(
      () => props.searchValue,
      () => {
        if (props.searchValue !== "" && props.searchValue.length > 1) {
          for (let setting of props.subCategory.settings) {
            if (setting.name.includes(props.searchValue)) {
              setting.collapsed = true
            } else {
              setting.collapsed = false
            }
            handleMark()
          }
        } else {
          addCollapseBooleanToSetting()
        }
      }
    )

    let markInstance: Mark
    function handleMark() {
      //markjs
      window.setTimeout(() => {
        markInstance && markInstance.unmark()
        markInstance = new Mark(document.querySelectorAll(".header-layer"))

        if (props.searchValue && props.searchValue.length) {
          markInstance.mark(props.searchValue, {
            exclude: [".settings-toggler"],
          })
        }
      })
    }

    addCollapseBooleanToSetting()
    function addCollapseBooleanToSetting() {
      for (let setting of props.subCategory.settings) {
        switch (setting.name) {
          case "dicom.tag.institution-name":
          case "mail.bcc":
          case "mail.reply-to":
          case "mail.sender.name":
          case "ui.horses-only":
          case "ui.language":
          case "report.enabled": {
            setting.collapsed = true
            break
          }
          default: {
            setting.collapsed = false
          }
        }
      }
    }

    function formatSettingName(name: string) {
      let settingname
      if (name.split(".").length > 2) {
        settingname = name.split(".")[1] + "." + name.split(".")[2]
        if (name.split(".").length > 3) {
          settingname = settingname + "." + name.split(".")[3]
        }
      } else {
        settingname = name.split(".")[1]
      }

      return settingname
    }

    function collapseAction(setting: any) {
      setting.collapsed = !setting.collapsed
      emit("open-setting", setting)
    }

    let showAddDialog = ref(false)
    let settingToAdd = ref()

    function addNewValueForSetting(setting: any) {
      showAddDialog.value = true
      settingToAdd.value = setting
    }

    function reloadData(valuesReferences: any) {
      showAddDialog.value = false
      for (let i = 0; i < valuesReferences.length; i++) {
        valuesReferences[i].loadSettingValues()
        valuesReferences[i].noValuesReset()
      }
    }

    return {
      formatSettingName,
      collapseAction,
      showAddDialog,
      settingToAdd,
      addNewValueForSetting,
      reloadData,
    }
  },
  components: { SettingsCollectionRecordValues, SettingsCollectionForm },
}
