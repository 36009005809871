<!--suppress XmlInvalidId -->
<template>
  <div class="card p-3 mb-0">
    <form
      class="formgrid grid"
      autocomplete="off"
      @submit.prevent="onSubmit"
    >
      <div class="field col-12 md:col-6">
        <label for="firstName">First name</label>
        <InputText
          id="firstName"
          v-model="v$.firstName.$model"
          type="text"
          class="w-full"
          data-lpignore="true"
        />
        <inline-form-error
          v-if="v$.firstName.$error"
          class="mb-4"
          :errors="v$.firstName.$errors"
        />
      </div>
      <div class="field col-12 md:col-6">
        <label for="lastName">Last name</label>
        <InputText
          id="lastName"
          v-model="v$.lastName.$model"
          type="text"
          class="w-full"
          data-lpignore="true"
        />
        <inline-form-error
          v-if="v$.lastName.$error"
          class="mb-4"
          :errors="v$.lastName.$errors"
        />
      </div>
      <div class="field col-12 md:col-6">
        <label for="username">Username</label>
        <InputText
          id="username"
          v-model="v$.username.$model"
          type="text"
          class="w-full"
          data-lpignore="true"
        />
        <inline-form-error
          v-if="v$.username.$error"
          class="mb-4"
          :errors="v$.username.$errors"
        />
      </div>
      <div
        v-if="createUserWithRole"
        class="field col-12 md:col-6"
      >
        <label for="role">Role</label>
        <!--<InputText id="role" type="password" class="w-full" v-model="v$.password.$model" />
        <inline-form-error class="mb-4" :errors="v$.password.$errors" v-if="v$.password.$error" />-->
        <Dropdown
          v-model="v$.role.$model"
          class="w-full"
          :options="roleTypes"
        />
      </div>
      <div
        class="field col-12 md:col-6"
        :class="{ fill: createUserWithRole }"
      >
        <label for="email">Email address</label>
        <InputText
          id="email"
          v-model="v$.email.$model"
          type="text"
          class="w-full"
          data-lpignore="true"
        />
        <inline-form-error
          v-if="v$.email.$error"
          class="mb-4"
          :errors="v$.email.$errors"
        />
      </div>
      <div
        v-if="!user"
        class="field col-12 md:col-6"
        :class="{ fill: createUserWithRole }"
      >
        <label for="password">Password</label>
        <InputText
          id="password"
          v-model="v$.password.$model"
          type="password"
          class="w-full"
          data-lpignore="true"
        />
        <inline-form-error
          v-if="v$.password.$error"
          class="mb-4"
          :errors="v$.password.$errors"
        />
      </div>
      <!--       <div class="field w-full col-12 md:col-6">
        <label for="comments">Comments</label>
        <Textarea rows="5" class="w-full" id="comments" v-model="v$.comments.$model" />
      </div> -->
      <div class="field col-12 flex">
        <PrimaryButton
          class="mr-3"
          type="submit"
          :label="'Save'"
        />
        <TertiaryButton
          v-if="!user && !createUserWithRole"
          :label="'Cancel'"
          class="p-button-link"
          @click="$emit('cancel')"
        />
        <InlineMessage
          v-if="done"
          severity="success"
        >
          Done
        </InlineMessage>
      </div>
    </form>
  </div>
</template>

<script langs="ts">
import { ref } from "vue"
import { helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    createUserWithRole: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submit", "cancel"],
  setup(props, { emit }) {
    const roleTypes = ref(["USER", "ADMIN"])
    const done = ref(false)
    const form = ref({
      firstName: props.user == null ? "" : props.user.firstName,
      lastName: props.user == null ? "" : props.user.lastName,
      username: props.user == null ? "" : props.user.username,
      email: props.user == null ? "" : props.user.email,
      password: props.user == null ? "" : props.user.password,
      role: "USER",
      comments: props.user == null ? "" : props.user.comments,
    })

    const rules = {
      firstName: { codeRequired: helpers.withMessage("The first name is required", required) },
      lastName: { codeRequired: helpers.withMessage("The last name is required", required) },
      username: { codeRequired: helpers.withMessage("The username is required", required) },
      email: { codeRequired: helpers.withMessage("The email address is required", required) },
      password:
        props.user != null
          ? {}
          : { required: helpers.withMessage("The password is required", required) },
      role: {},
      comments: {},
    }

    const v$ = useVuelidate(rules, form)

    function onSubmit() {
      v$.value.$touch()

      if (v$.value.$invalid) return

      let result = {
        firstName: form.value.firstName,
        lastName: form.value.lastName,
        username: form.value.username,
        email: form.value.email,
        password: form.value.password,
        comments: form.value.comments,
      }

      if (props.createUserWithRole) {
        result.role = form.value.role
      }

      emit("submit", result)

      v$.value.$reset()
    }

    function onDone() {
      done.value = true
      setTimeout(() => {
        done.value = false
      }, 2500)
    }

    return {
      done,
      v$,
      onSubmit,
      onDone,
      roleTypes,
    }
  },
}
</script>

<style scoped>
.fill {
  width: 100%;
}
</style>
