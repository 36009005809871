
import RenderInstructionService from "@/services/RenderInstructionService"
import { ref, watch } from "vue"

export default {
  props: {
    modelValue: String,
    excludes: {
      type: Object,
      default: [],
    },
    placeholder: {
      type: String,
      default: "Select a lut file",
    },
    invalid: Boolean,
  },
  emits: ["update:modelValue"],
  setup(props: any, { emit }: any) {
    const service = new RenderInstructionService("luts")
    const allFileOptions = ref([])
    const selectedLutFile = ref()
    const filteredFileOptions = ref([])

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        selectedLutFile.value = newValue
      }
    )

    loadLutFileOptions()
    function loadLutFileOptions() {
      service.getLutFiles().then((response) => {
        allFileOptions.value = response
        filteredFileOptions.value = response
        selectedLutFile.value = props.modelValue

        filterExcludes()
      })
    }

    const searchLutFile = (event: any) => {
      if (!event.query.trim().length) {
        filteredFileOptions.value = allFileOptions.value
      } else {
        filteredFileOptions.value = allFileOptions.value.filter((processingFileOption) => {
          return processingFileOption.toLowerCase().indexOf(event.query.toLowerCase()) >= 0
        })
      }

      filterExcludes()
    }

    function filterExcludes() {
      filteredFileOptions.value = filteredFileOptions.value.filter((lutFileOption) => {
        return !props.excludes.includes(lutFileOption)
      })
    }

    return {
      selectedLutFile,
      allFileOptions,
      filteredFileOptions,
      searchLutFile,
    }
  },
}
