
import { ref, reactive, computed } from "vue"
import useArrayUtils from "@/utils/array"
import useReleaseUtils from "@/utils/releases"
import ReleasesService from "@/services/ReleasesService"
import { useStore } from "vuex"

export default {
  props: {
    status: {
      type: Object,
      default: null,
    },
    runConfig: {
      type: Object,
      default: null,
    },
    showStatusMessage: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["changed"],
  setup(props: any, { emit }: any) {
    const editOverlayComponent = ref()
    const service = new ReleasesService()
    const comments = ref(props.runConfig.comments)

    const states = reactive([
      { key: "UNKNOWN", name: "Unknown", icon: "ProgressQuestion" },
      { key: "TESTING", name: "Testing", icon: "ProgressQuestion" },
      { key: "NOK", name: "Not ok", icon: "minusCircle" },
      { key: "OK", name: "Ok", icon: "checkboxMarkedCircle" },
    ])

    const store = useStore()
    const roles = store.getters.getRoles

    const checkRoles = computed(() => {
      const _roles = Object.values(roles)
      if (_roles && _roles.length) {
        return (
          _roles.includes("SUPER_ADMIN") ||
          _roles.includes("SUPER_DEV") ||
          _roles.includes("SUPER_SUPPORT") ||
          _roles.includes("ADMIN")
        )
      }
    })

    function onEdit(event: any) {
      editOverlayComponent.value.toggle(event)
    }

    function onCancelEdit() {
      editOverlayComponent.value.toggle()
    }

    function changeStatus(status: any) {
      service.updateRunConfiguration(props.runConfig.version, status, comments.value).then(() => {
        editOverlayComponent.value.toggle()
        emit("changed")
      })
    }

    function saveComment(status: any) {
      service.updateRunConfiguration(props.runConfig.version, status, comments.value).then(() => {
        emit("changed")
      })
    }

    return {
      editOverlayComponent,
      comments,
      onEdit,
      onCancelEdit,
      changeStatus,
      states,
      saveComment,
      checkRoles,
    }
  },
}
