
import ReportEditor from "@/components/reports/ReportEditor.vue"
import ReportTemplatesItem from "@/components/reports/ReportTemplatesItem.vue"
import { reactive, watch, defineComponent } from "vue"
import { ref } from "vue"
import ReportService from "@/services/ReportService"
import ReportTemplateAddModal from "@/components/reports/tools/ReportTemplateAddModal.vue"
import RolesService from "@/services/RolesService"
import OrganizationService from "@/services/OrganizationService"
import PrimaryButton from "../buttons/PrimaryButton.vue"

export default defineComponent({
  name: "ReportTemplates",
  components: { ReportTemplateAddModal, ReportTemplatesItem, ReportEditor, PrimaryButton },
  props: {
    organization: {
      type: String,
      default: null,
    },
    language: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  emits: ["reset-languages"],
  setup(props, { emit }) {
    const templates = ref([])
    const organizationUsers = ref([])
    const selectedUser = ref(null)
    const reportService = new ReportService()
    const organizationService = OrganizationService.getInstance()
    const showAdd = ref(false)
    emit("reset-languages")
    watch(
      () => props.language,
      () => {
        resetDropdown()
        loadTemplates()
      }
    )
    watch(
      () => props.type,
      () => {
        loadTemplates()
      }
    )

    watch(
      () => selectedUser.value,
      () => {
        if (selectedUser.value == null) {
          loadTemplates()
        } else {
          loadTemplatesForUser()
        }
      }
    )

    function loadTemplatesForUser() {
      if (props.organization) {
        reportService
          .getReportTemplatesByTypeForOrganizationUser(
            props.type as string,
            props.language as string,
            props.organization as string,
            selectedUser.value.id
          )
          .then((result) => {
            templates.value = result.data
            showAdd.value = false
          })
      }
    }

    loadTemplates()

    function loadTemplates() {
      if (props.organization) {
        reportService
          .getReportTemplatesByType(
            props.type as string,
            props.language as string,
            props.organization as string
          )
          .then((result) => {
            templates.value = result.data
            showAdd.value = false
          })
      }
    }

    loadOrganizationUsers()

    function loadOrganizationUsers() {
      if (props.organization) {
        organizationService.getUsersForOrganization(props.organization as string).then((result) => {
          let rows = result.rows
          for (let row of rows) {
            organizationUsers.value.push(row.user)
          }
        })
      }
    }

    function resetDropdown() {
      selectedUser.value = null
    }

    return {
      templates,
      showAdd,
      loadTemplates,
      organizationUsers,
      selectedUser,
      resetDropdown,
    }
  },
})
