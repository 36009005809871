
import LinkLabel from "@/components/navigation/LinkLabel.vue"
export default {
  components: { LinkLabel },
  props: {
    panelSetting: {
      type: Object,
      default: null,
    },
    computer: {
      type: Object,
      default: null,
    },
  },
}
