
import ComputerService from "@/services/ComputerService"
import Button from "primevue/button"
import SplitButton from "primevue/splitbutton"
import { computed, ref, defineComponent } from "vue"

export default defineComponent({
  name: "TeamViewerButton",
  props: {
    computerId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const computer = ref(null)
    const computerService = new ComputerService()
    const teamViewerItem = computed(() => [
      {
        label: computer.value ? computer.value.teamViewerClientId : null,
        icon: "pi pi-copy",
        command: () => {
          copyTeamviewerId()
        },
      },
    ])

    loadComputer()

    function loadComputer() {
      computerService.getComputer(props.computerId as string).then((response) => {
        computer.value = response.data
      })
    }

    function copyTeamviewerId() {
      navigator.clipboard.writeText(computer.value.teamViewerClientId)
    }

    function onClickTeamviewer() {
      window.open("tvcontrol1://control?device=" + computer.value.teamViewerClientId, "_blank")
    }

    return {
      computer,
      teamViewerItem,
      onClickTeamviewer,
    }
  },
})
