import axios from "axios"
import ResultSet from "@/models/ResultSet"

import User from "@/models/User"

export default class UserService {
  pageSize: number = 20

  getUsers(part: Number = 0, sort: any, filter: string): Promise<ResultSet<User>> {
    let oThis = this
    return axios
      .get(
        "users?size=" + this.pageSize + "&page=" + part + "&sort=" + sort + "&filter=" + filter,
        { baseURL: process.env.VUE_APP_VSOL_API }
      )
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  getErpUsers(part: Number = 0, sort: any, filter: string): Promise<ResultSet<any>> {
    let oThis = this
    return axios
      .get("users?size=" + this.pageSize + "&page=" + part + "&sort=" + sort + "&filter=" + filter)
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  getComputersForUser(userId: string, part: Number = 0) {
    let oThis = this
    return axios
      .get("users/" + userId + "/computers", { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  getOrganizationsForUser(userId: string, part: Number = 0) {
    let oThis = this
    return axios
      .get("users/" + userId + "/roles?size=" + this.pageSize + "&page=" + part, {
        baseURL: process.env.VUE_APP_VSOL_API,
      })
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  getUser(userID: string) {
    return axios.get("users/" + userID, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  addUser(userData: any) {
    return axios
      .post("users", userData, { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => res.data)
  }

  addErpUser(company: string, userData: any) {
    return axios.post("users?defaultCompanyId=" + company, userData).then((res) => res.data)
  }

  updateUser(userID: string, userData: any) {
    delete userData.password

    return axios.put("users/" + userID, userData, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  updateErpUser(userID: string, userData: any) {
    delete userData.password
    return axios.put("users/" + userID, userData)
  }

  deleteUser(userID: string) {
    return axios.delete("users/" + userID, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  async getAllUsers() {
    return await axios
      .get("users/list", { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => res.data)
  }

  requestPasswordReset(email: string) {
    return axios.get("/users/resetPassword?email=" + email, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }
}
