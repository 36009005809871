<template>
  <div class="relative">
    <AutoComplete
      v-model="modelValue"
      class="w-full"
      :class="{ 'p-invalid': invalid }"
      :suggestions="filteredComputerOptions"
      :dropdown="true"
      :placeholder="placeholder"
      :disabled="disabled"
      :field="(item) => computerToString(item)"
      force-selection
      @complete="searchComputer($event)"
      @item-select="$emit('update:modelValue', modelValue)"
      @item-unselect="$emit('update:modelValue', modelValue)"
    >
      <template #item="slotProps">
        <div class="p-2">
          {{ computerToString(slotProps.item) }}
        </div>
      </template>
    </AutoComplete>
    <Button
      v-if="modelValue && !disabled"
      icon="pi pi-times"
      class="p-button-rounded p-button-secondary p-button-text clear"
      @click="$emit('update:modelValue', null)"
    />
  </div>
</template>

<script>
import ComputerService from "@/services/ComputerService"
import { ref, watch } from "vue"
import OrganizationService from "@/services/OrganizationService"
import UserService from "@/services/UserService"

export default {
  props: {
    modelValue: Object,
    excludes: {
      type: Object,
      default: [],
    },
    includes: {
      type: Array,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Select a computer",
    },
    invalid: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const computerService = new ComputerService()
    const allComputerOptions = ref([])
    const filteredComputerOptions = ref([])

    initialize()

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        //    emit("update:modelValue", newValue);
      }
    )

    watch(
      () => props.includes,
      (newValue, oldValue) => initialize()
    )

    function initialize() {
      if (props.includes == null) {
        // Load all options
        loadComputerOptions()
      } else {
        // Only use includes
        allComputerOptions.value = props.includes
      }
    }

    function loadComputerOptions() {
      computerService.getAllComputers().then((response) => {
        allComputerOptions.value = response
        filteredComputerOptions.value = response
        filterExcludes()
      })
    }

    function searchComputer(event) {
      if (!event.query.trim().length) {
        filteredComputerOptions.value = [...allComputerOptions.value]
      } else {
        filteredComputerOptions.value = allComputerOptions.value.filter((computerOption) => {
          return (
            computerToString(computerOption).toLowerCase().indexOf(event.query.toLowerCase()) >= 0
          )
        })
      }
      filterExcludes()
    }

    function filterExcludes() {
      filteredComputerOptions.value = filteredComputerOptions.value.filter((computerOption) => {
        return !props.excludes.includes(computerOption.id)
      })
    }

    function computerToString(computer) {
      return computer.serial + " - " + computer.alias
    }

    return {
      filteredComputerOptions,
      searchComputer,
      computerToString,
    }
  },
}
</script>
<style scoped>
.clear {
  position: absolute;
  top: 5px;
  right: 40px;
}
</style>
