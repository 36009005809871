
import { computed } from "@vue/reactivity"
import { onMounted, ref, defineComponent } from "vue"
import { RouterLink, useRoute } from "vue-router"

export default defineComponent({
  name: "NavigationPanelMenuItem",
  props: {
    item: {
      type: Object,
    },
    widthSpacer: {
      type: Number,
      default: 0,
    },
  },
  emits: ["openMenu"],
  setup(props, { emit }) {
    const route = useRoute()
    const menuVisible = ref(false)
    const openMenuIcon = ref("chevronDown")
    const spacerWidth = computed(() => (props.widthSpacer as number) + 24)

    function toggleMenu() {
      menuVisible.value = menuVisible.value ? false : true
      openMenuIcon.value = menuVisible.value ? "chevronUp" : "chevronDown"
    }

    function makeMenuVisible() {
      menuVisible.value = true
      openMenuIcon.value = menuVisible.value ? "chevronUp" : "chevronDown"
    }

    onMounted(async () => {
      setTimeout(() => {
        if (route.path.includes(props.item.route)) {
          emit("openMenu")
        }
      }, 500)
    })

    return {
      toggleMenu,
      menuVisible,
      openMenuIcon,
      spacerWidth,
      makeMenuVisible,
    }
  },
})
