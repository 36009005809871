
import SettingService from "@/services/SettingService"
import { computed, reactive, ref, watch } from "vue"
import LinkLabel from "@/components/navigation/LinkLabel.vue"
import { useToast } from "primevue/usetoast"
import SettingsCollectionForm from "./SettingsCollectionForm.vue"
import { useStore } from "vuex"

export default {
  components: { LinkLabel, SettingsCollectionForm },
  props: {
    setting: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    computer: {
      type: Object,
      default: null,
    },
    organization: {
      type: Object,
      default: null,
    },
    searchValue: {
      type: String,
      default: null,
    },
    permissions: {
      type: Object,
      default: null,
    },
  },
  emits: ["category-changed", "no-values", "no-values-reset", "reloaded"],
  setup(props: any, { emit }: any) {
    const settingService = new SettingService()
    let settingValues = ref([])
    /*     let filteredResults = ref([]) */
    let editDialog = ref(false)
    let settingToEdit = ref()
    let settingToDelete = ref("")
    let settingToDeleteName = ref("")
    let confirmDeleteDialog = ref(false)
    let toast = useToast()
    const store = useStore()
    const roles = store.getters.getRoles

    const checkRoles = computed(() => {
      if (!props.organization && !props.computer && !props.user) {
        const _roles = Object.values(roles)
        if (_roles && _roles.length) {
          return _roles.includes("SUPER_ADMIN") || _roles.includes("SUPER_DEV")
        }
      } else return true
    })

    function noValuesReset() {
      emit("no-values-reset")
    }

    loadSettingValues()
    function loadSettingValues() {
      settingValues.value = []
      // filteredResults.value = []
      if (!props.computer && !props.user && !props.organization) {
        settingService.getAllRules(props.setting.name, 0, "").then((result) => {
          settingValues.value = result.rows
          if (settingValues.value.length === 0) {
            emit("no-values")
          }
          props.setting.filled = settingValues.value.length
          //console.log(props.setting)
          /*           if (props.searchValue === "") {
            filteredResults.value = settingValues.value
          } else {
            filteredResults.value = settingValues.value.filter((item) =>
              item.value.toLowerCase().includes(props.searchValue.toLowerCase())
            )
          } */
        })
      } else {
        settingService
          .getRules(
            props.setting.name,
            props.organization ? props.organization.id : null,
            props.computer ? props.computer.id : null,
            props.user ? props.user.id : null,
            0,
            ""
          )
          .then((result) => {
            settingValues.value = result.rows
            if (settingValues.value.length === 0) {
              emit("no-values")
            }
            props.setting.filled = settingValues.value.length
            /*             if (props.searchValue === "") {
              filteredResults.value = settingValues.value
            } else {
              filteredResults.value = settingValues.value.filter((item) =>
                item.value.toLowerCase().includes(props.searchValue.toLowerCase())
              )
            } */
          })
      }
    }

    function showEditDialog(setting: any) {
      editDialog.value = true
      settingToEdit.value = setting
    }

    function settingChanged() {
      editDialog.value = false
      loadSettingValues()
    }

    function confirmSettingDelete(setting: any) {
      confirmDeleteDialog.value = true
      settingToDeleteName.value = setting.name
      settingToDelete.value = setting.id
    }

    function deleteSetting() {
      settingService.deleteSetting(settingToDelete.value).then(() => {
        settingChanged()
        confirmDeleteDialog.value = false
        settingToDelete.value = ""
        settingToDeleteName.value = ""
        toast.add({
          severity: "success",
          summary: "Success",
          detail: "Setting deleted",
          life: 3000,
        })
      })
    }

    return {
      settingValues,
      showEditDialog,
      editDialog,
      settingToEdit,
      confirmDeleteDialog,
      confirmSettingDelete,
      settingToDelete,
      deleteSetting,
      settingToDeleteName,
      settingChanged,
      loadSettingValues,
      noValuesReset,
      checkRoles,
    }
  },
}
