import ResultSet from "@/models/ResultSet"
import axios from "axios"

export default class CallHistoryService {
  private pageSize: number = 20

  getCallHistory(
    page: number,
    sort: string,
    organizationId: string,
    personId: string,
    incoming: boolean,
    outgoing: boolean,
    pageSize?: number
  ) {
    let url =
      "callHistory?page=" +
      page.toString() +
      "&size=" +
      (pageSize ? pageSize.toString() : this.pageSize.toString()) +
      "&incoming=" +
      incoming +
      "&outgoing=" +
      outgoing +
      "&sort=" +
      sort
    if (organizationId) {
      url += "&organizationId=" + organizationId
    }
    if (personId) {
      url += "&personId=" + personId
    }
    return axios.get(url).then((res) => new ResultSet<any>(res.data, this.pageSize))
  }
}
