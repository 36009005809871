
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"
import ReportEditor from "@/components/reports/ReportEditor.vue"
import ReportService from "@/services/ReportService"
import useVuelidate from "@vuelidate/core"
import { ref, defineComponent } from "vue"

export default defineComponent({
  name: "ReportTemplateEditModal",
  components: { ReportEditor, PrimaryButton },
  props: {
    templatePart: {
      type: Object,
      default: null,
    },
  },
  emits: ["template-changed"],
  setup(props, { emit }) {
    const editorContent = ref("")
    const reportService = new ReportService()
    const form = ref({
      alias: "",
      internalAlias: "",
      type: "",
      language: "",
    })
    window.setTimeout(() => {
      editorContent.value = " "
    })
    loadTemplate()

    function loadTemplate() {
      reportService.getTemplateByType(props.templatePart.id).then((result) => {
        form.value.alias = result.data.alias
        form.value.internalAlias = result.data.internalAlias
        form.value.type = result.data.type
        form.value.language = result.data.language
      })
      reportService.readReportTemplate(props.templatePart.id).then((result) => {
        editorContent.value = result.data
      })
    }

    function saveChanges() {
      reportService.updateTemplateHTML(props.templatePart.id, editorContent.value).then(() => {
        reportService.updateTemplateData(props.templatePart.id, form.value).then(() => {
          emit("template-changed")
        })
      })
    }

    return { editorContent, form, saveChanges }
  },
})
