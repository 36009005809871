<template>
  <router-link
    v-ripple
    :to="{ name: routeName }"
    class="drawer-item p-ripple"
  >
    <mdicon :name="icon" />
    <span>{{ label }}</span>
  </router-link>
</template>

<script>
export default {
  name: "NavigationItem",
  props: {
    routeName: String,
    label: String,
    icon: String,
  },
  emits: ["clicked"],
  setup(props, { emit }) {},
}
</script>

<style scoped>
.drawer-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  padding: 7px 0 7px 7px;
  width: 225px;
}

.drawer-item:hover {
  color: #3367d6;
  background-color: #f3f6ff;
}

.drawer-item .mdi {
  padding-right: 10px;
}

.drawer-item.router-link-active {
  color: #3367d6;
  background-color: #f3f6ff;
}
</style>
