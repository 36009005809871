<template>
  <Dropdown
    v-model="selectedItem"
    class="w-full"
    :class="{ 'p-invalid': invalid }"
    :options="allOptions"
    :placeholder="placeholder"
    :loading="loading"
    :show-clear="true"
    @change="$emit('update:modelValue', selectedItem)"
  >
    <template #value="slotProps">
      <RunConfigurationTag
        v-if="slotProps.value"
        :run-config="slotProps.value"
        :show-status="true"
        :editable="false"
      />
    </template>
    <template #option="slotProps">
      <RunConfigurationTag
        :run-config="slotProps.option"
        :show-status="true"
        :editable="false"
      />
    </template>
  </Dropdown>
</template>

<script>
import ComputerService from "@/services/ComputerService"
import { ref, watch } from "vue"
import RunConfigurationCollectionCard from "@/components/releases/run-configs/RunConfigurationCollectionCard"
import ReleasesService from "@/services/ReleasesService"

export default {
  name: "RunConfigurationAutoComplete",
  components: { RunConfigurationCollectionCard },
  props: {
    modelValue: Object,
    excludes: {
      type: Object,
      default: [],
    },
    placeholder: {
      type: String,
      default: "Select a run configuration",
    },
    invalid: Boolean,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const selectedItem = ref()
    const service = new ReleasesService()
    const allOptions = ref([])
    const loading = ref(false)
    const commentsEnabled = process.env.VUE_APP_RUN_CONFIG_COMMENTS == "true"

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        selectedItem.value = newValue
      }
    )

    loadComputerOptions()

    function loadComputerOptions() {
      loading.value = true
      service.getAllRunConfigurations(!commentsEnabled).then((response) => {
        allOptions.value = response
        selectedItem.value = allOptions.value[0]
        loading.value = false
        emit("update:modelValue", selectedItem.value)
      })
    }

    return {
      selectedItem,
      allOptions,
      loading,
    }
  },
}
</script>
<style scoped></style>
