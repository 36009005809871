<template>
  <span
    v-if="absoluteDateTime"
    title="Last update time"
  >
    <mdicon
      name="update"
      :size="18"
      class="mr-2"
    />
    <label class="updateTime">{{ absoluteDateTime }}</label>
  </span>
</template>

<script>
export default {
  name: "ComputerUpdateTime",
  props: {
    absoluteDateTime: {},
    computer: {},
  },
}
</script>

<style scoped>
.updateTime {
  font-size: 13px;
}
</style>
