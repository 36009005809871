
export default {
  name: "ToolkitTag",
  props: {
    toolkit: {
      type: Object,
      required: true,
    },
  },
}
