import { createApp } from "vue"

import ProgressSpinner from "primevue/progressspinner" // Does not work if included later
import ToastService from "primevue/toastservice"
import ConfirmationService from "primevue/confirmationservice"
import App from "./App.vue"
import Ripple from "primevue/ripple"
import PrimeVue from "primevue/config"
import router from "./router/router"
import store from "./store"
import axios from "axios"
import VueAxios from "vue-axios" // AJAX

import "primeflex/primeflex.css" // Prime flex (https://www.primefaces.org/primeflex/setup)
import "primevue/resources/themes/mdc-light-indigo/theme.css" //theme
import "primevue/resources/primevue.min.css" //core css
import "primeicons/primeicons.css" //icons

/******************* CSS VARIABLES ******************/
import "./styles/reset.css"
import "./styles/main.css"
import "./styles/font.css"
import "./styles/prime-override.css"
import "./styles/datatable.css"
import "./styles/entities.css"
import "./styles/colors.css"
import "./styles/tab-body.css"
import "./styles/spinner.css"
import "./styles/flatpickr.css"
import "./styles/paddings-margins.css"

/********************* ICONS  ******************/
import mdiVue from "mdi-vue/v3"
import {
  mdiAccount,
  mdiAccountTie,
  mdiAccountCircle,
  mdiAccountGroup,
  mdiAccountMultiple,
  mdiAccountMultiplePlus,
  mdiAccountQuestion,
  mdiAlert,
  mdiAlertOutline,
  mdiAlertDecagram,
  mdiAlertCircle,
  mdiAngleAcute,
  mdiArrowCollapse,
  mdiArrowExpand,
  mdiArrowExpandHorizontal,
  mdiArrowLeft,
  mdiArrowLeftBold,
  mdiArrowRight,
  mdiArrowTopRightBottomLeft,
  mdiAt,
  mdiBlur,
  mdiBorderLeftVariant,
  mdiBrightness6,
  mdiBug,
  mdiCalculatorVariantOutline,
  mdiCalendarMonth,
  mdiCalendarCheck,
  mdiCamera,
  mdiCameraBurst,
  mdiCameraOutline,
  mdiCar,
  mdiCardAccountDetailsOutline,
  mdiCash,
  mdiCat,
  mdiCellphoneBasic,
  mdiCellphoneLink,
  mdiCheck,
  mdiCheckboxBlankCircleOutline,
  mdiCheckboxMultipleBlankCircleOutline,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCircleMedium,
  mdiCircleSmall,
  mdiClockPlusOutline,
  mdiClose,
  mdiCog,
  mdiCogOutline,
  mdiContrastCircle,
  mdiCropRotate,
  mdiCrown,
  mdiDatabaseImportOutline,
  mdiDelete,
  mdiDog,
  mdiDomain,
  mdiDownload,
  mdiEmail,
  mdiEmailOutline,
  mdiEyeOffOutline,
  mdiEyeCheckOutline,
  mdiEyeOutline,
  mdiExport,
  mdiFaceAgent,
  mdiFileEyeOutline,
  mdiFileImageMinusOutline,
  mdiFileImageOutline,
  mdiFileImageRemoveOutline,
  mdiFilterVariant,
  mdiFitToPageOutline,
  mdiFlagVariant,
  mdiFlipHorizontal,
  mdiFlipVertical,
  mdiFolderImage,
  mdiFormatListChecks,
  mdiFormatListNumbered,
  mdiFormatText,
  mdiFullscreen,
  mdiFullscreenExit,
  mdiGridLarge,
  mdiHamburger,
  mdiHeartOffOutline,
  mdiHelp,
  mdiHistory,
  mdiHome,
  mdiHomeMapMarker,
  mdiHomeGroupPlus,
  mdiHorseVariant,
  mdiHorseshoe,
  mdiImage,
  mdiInformationOutline,
  mdiInformationVariant,
  mdiInformation,
  mdiInvertColors,
  mdiLabelMultipleOutline,
  mdiMap,
  mdiMapMarker,
  mdiMagnify,
  mdiMagnifyMinusOutline,
  mdiMagnifyPlusOutline,
  mdiMailboxOutline,
  mdiMathNorm,
  mdiMenu,
  mdiMenuDown,
  mdiMenuRight,
  mdiMenuUp,
  mdiContentCopy,
  mdiNumeric,
  mdiOfficeBuilding,
  mdiOpenInNew,
  mdiOrderBoolDescending,
  mdiPackageDown,
  mdiPackageUp,
  mdiPackageVariantClosed,
  mdiPartyPopper,
  mdiPencil,
  mdiLinkVariant,
  mdiFileDocument,
  mdiPhone,
  mdiPhoneIncoming,
  mdiPhoneMissed,
  mdiPhoneOutgoing,
  mdiPlay,
  mdiPlus,
  mdiPresentationPlay,
  mdiPrinter,
  mdiRabbit,
  mdiRotateLeft,
  mdiRotateRight,
  mdiRuler,
  mdiShieldAccount,
  mdiSkipNext,
  mdiSortAlphabeticalAscending,
  mdiSortAlphabeticalDescending,
  mdiSortCalendarAscending,
  mdiSortCalendarDescending,
  mdiStar,
  mdiStarOutline,
  mdiSwapVertical,
  mdiTarget,
  mdiTeamviewer,
  mdiToggleSwitch,
  mdiTriangleWave,
  mdiUndo,
  mdiVectorLine,
  mdiViewDashboard,
  mdiWeatherCloudyClock,
  mdiXml,
  mdiCrop,
  mdiTransitionMasked,
  mdiFormatRotate90,
  mdiCloseCircle,
  mdiMinusCircle,
  mdiProgressQuestion,
  mdiCheckboxMarkedCircle,
  mdiRhombus,
  mdiRemoteDesktop,
  mdiSmokeDetectorAlert,
  mdiEthernetCable,
  mdiPower,
  mdiDesktopClassic,
  mdiAccountOutline,
  mdiConnection,
  mdiMidiPort,
  mdiLeak,
  mdiAlarmPanel,
  mdiQrcode,
  mdiLightningBoltCircle,
  mdiAlignHorizontalCenter,
  mdiWall,
  mdiMemory,
  mdiCpu64Bit,
  mdiMapClock,
  mdiClockOutline,
  mdiDesktopTowerMonitor,
  mdiLaptop,
  mdiWifi,
  mdiUpdate,
  mdiContrast,
  mdiHammerWrench,
  mdiBattery,
  mdiRestartAlert,
  mdiChevronDown,
  mdiChevronUp,
  mdiCircle,
  mdiUnfoldLessHorizontal,
  mdiCodeGreaterThan,
  mdiCodeGreaterThanOrEqual,
  mdiCodeLessThan,
  mdiCodeLessThanOrEqual,
  mdiEqualBox,
  mdiTableColumnPlusAfter,
  mdiTrashCan,
  mdiFolderGoogleDrive,
  mdiWhatsapp,
  mdiArrowRightBold,
  mdiClockAlertOutline,
  mdiCart,
  mdiCartCheck,
  mdiArrowLeftRight,
  mdiContentSave,
  mdiMagnifyExpand,
  mdiAccountPlus,
  mdiHospitalBuilding,
  mdiPodiumBronze,
  mdiPodiumGold,
  mdiPodiumSilver,
  mdiAccountVoice,
  mdiPoll,
  mdiSourceBranch,
  mdiTagPlusOutline,
  mdiCheckboxMarkedCircleOutline,
} from "@mdi/js"

/* Import primevue components */
import Accordion from "primevue/accordion"
import AccordionTab from "primevue/accordiontab"
import AutoComplete from "primevue/autocomplete"
import Avatar from "primevue/avatar"
import AvatarGroup from "primevue/avatargroup"
import Button from "primevue/button"
import Calendar from "primevue/calendar"
import Checkbox from "primevue/checkbox"
import Chips from "primevue/chips"
import Column from "primevue/column"
import ConfirmPopup from "primevue/confirmpopup"
import ConfirmDialog from "primevue/confirmdialog"
import ContextMenu from "primevue/contextmenu"
import DataTable from "primevue/datatable"
import DataView from "primevue/dataview"
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions"
import Dialog from "primevue/dialog"
import Dropdown from "primevue/dropdown"
import Inplace from "primevue/inplace"
import InputText from "primevue/inputtext"
import Menu from "primevue/menu"
import MultiSelect from "primevue/multiselect"
import OverlayPanel from "primevue/overlaypanel"
import Password from "primevue/password"
import ProgressBar from "primevue/progressbar"
import Rating from "primevue/rating"
import RadioButton from "primevue/radiobutton"
import ScrollPanel from "primevue/scrollpanel"
import SelectButton from "primevue/selectbutton"
import Slider from "primevue/slider"
import SplitButton from "primevue/splitbutton"
import Skeleton from "primevue/skeleton"
import Tag from "primevue/tag"
import Textarea from "primevue/textarea"
import TreeTable from "primevue/treetable"
import TriStateCheckbox from "primevue/tristatecheckbox"
import Toast from "primevue/toast"
import PanelMenu from "primevue/panelmenu"
import TabMenu from "primevue/tabmenu"
import Message from "primevue/message"
import Listbox from "primevue/listbox"
import Divider from "primevue/divider"

/* Import custom components */
import TransitionHeight from "@/components/transitions/TransitionHeight.vue"

import NavigationHeader from "@/components/navigation/NavigationHeader.vue"
import NavigationItem from "@/components/navigation/NavigationItem.vue"

import TopNav from "@/components/topnav/TopNav.vue"
import SideBar from "@/components/sidebar/SideBar.vue"

import ActiveCalls from "@/components/calls/ActiveCalls.vue"

import PrimaryButton from "@/components/buttons/PrimaryButton.vue"
import SecondaryButton from "@/components/buttons/SecondaryButton.vue"
import TertiaryButton from "@/components/buttons/TertiaryButton.vue"

import DataCollection from "@/components/data/DataCollection.vue"
import FilterOverlay from "@/components/data/FilterOverlay.vue"
import DataCollectionListRow from "@/components/data/DataCollectionListRow.vue"
import DataCollectionGridCard from "@/components/data/DataCollectionGridCard.vue"
import UserComputerOrganizationFilter from "@/components/data/OrganizationUserComputerFilter.vue"

import OrganizationAutoComplete from "@/components/organizations/OrganizationAutoComplete.vue"
import OrganizationSwitcher from "@/components/organizations/OrganizationSwitcher.vue"
import OrganizationForm from "@/components/organizations/OrganizationForm.vue"
import OrganizationCollectionRow from "@/components/organizations/OrganizationCollectionRow.vue"

import ComputerAutoComplete from "@/components/computers/tools/ComputerAutoComplete.vue"
import ComputerForm from "@/components/computers/ComputerForm.vue"
import ComputerCollectionRow from "@/components/computers/ComputerCollectionRow.vue"
import ComputerCollectionFieldsFilter from "@/components/computers/tools/ComputerCollectionFieldsFilter.vue"

import UserAutoComplete from "@/components/users/UserAutoComplete.vue"
import UserForm from "@/components/users/UserForm.vue"
import UserCollectionRow from "@/components/users/UserCollectionRow.vue"

import NetworkForm from "@/components/networks/NetworkForm.vue"
import NetworkCollection from "@/components/networks/NetworkCollection.vue"
import NetworkCollectionRow from "@/components/networks/NetworkCollectionRow.vue"

import RoleForm from "@/components/roles/RoleForm.vue"
import RolesCollection from "@/components/roles/RolesCollection.vue"
import RolesCollectionRow from "@/components/roles/RolesCollectionRow.vue"

import InlineFormError from "@/components/data/InlineFormError.vue"

import RunConfigurationTag from "@/components/releases/run-configs/RunConfigurationTag.vue"
import RunConfigurationCollectionCard from "@/components/releases/run-configs/RunConfigurationCollectionCard.vue"
import RunConfigurationAutoComplete from "@/components/releases/run-configs/RunConfigurationAutoComplete.vue"
import RunConfigurationStatus from "@/components/releases/run-configs/RunConfigurationStatus.vue"
import VersionCollectionCard from "@/components/releases/run-configs/VersionCollectionCard.vue"

import ProfilePasswordForm from "@/components/profile/ProfilePasswordForm.vue"
import EmployeeForm from "@/components/team/EmployeeForm.vue"
import EmployeeRole from "@/components/team/EmployeeRole.vue"

import SettingsForm from "@/components/settings/general/SettingsForm.vue"
import SettingsCollectionRecord from "@/components/settings/general/SettingsCollectionRecord.vue"
import SettingsCollectionRecordValues from "@/components/settings/general/SettingsCollectionRecordValues.vue"
import SettingsCollectionForm from "@/components/settings/general/SettingsCollectionForm.vue"

import PanelSettingsCollection from "@/components/settings/hardware/PanelSettingsCollection.vue"
import PanelSettingsRow from "@/components/settings/hardware/PanelSettingsRow.vue"

import RenderInstructionRuleCollection from "@/components/settings/render-instructions/RenderInstructionRuleCollection.vue"
import RenderInstructionRuleForm from "@/components/settings/render-instructions/RenderInstructionRuleForm.vue"
import ProcessingFileAutoComplete from "@/components/settings/processings/ProcessingFileAutoComplete.vue"

import AnnotationRuleForm from "@/components/settings/render-instructions/annotations/AnnotationRuleForm.vue"
import CroppingRuleForm from "@/components/settings/render-instructions/croppings/CroppingRuleForm.vue"
import OrientationRuleForm from "@/components/settings/render-instructions/orientations/OrientationRuleForm.vue"
import CVInputRuleForm from "@/components/settings/render-instructions/cvinput/CVInputRuleForm.vue"
import WindowingsForm from "@/components/settings/render-instructions/windowings/WindowingsForm.vue"
import LutRuleForm from "@/components/settings/render-instructions/luts/LutRuleForm.vue"
import RilutRuleForm from "@/components/settings/render-instructions/riluts/RilutRuleForm.vue"

import ProcessingRuleValue from "@/components/settings/processings/ProcessingRuleValue.vue"
import ProcessingRuleForm from "@/components/settings/processings/ProcessingRuleForm.vue"
import OrientationRuleValue from "@/components/settings/render-instructions/orientations/OrientationRuleValue.vue"
import AnnotationRuleValue from "@/components/settings/render-instructions/annotations/AnnotationRuleValue.vue"
import CroppingRuleValue from "@/components/settings/render-instructions/croppings/CroppingRuleValue.vue"
import CVInputRuleValue from "@/components/settings/render-instructions/cvinput/CVInputRuleValue.vue"
import LutRuleValue from "@/components/settings/render-instructions/luts/LutRuleValue.vue"
import RilutRuleValue from "@/components/settings/render-instructions/riluts/RilutRuleValue.vue"

import SpeciesDropdown from "@/components/settings/render-instructions/SpeciesDropdown.vue"
import AnatomyDropdown from "@/components/settings/render-instructions/AnatomyDropdown.vue"
import DirectionDropdown from "@/components/settings/render-instructions/DirectionDropdown.vue"
import LegDropdown from "@/components/settings/render-instructions/LegDropdown.vue"
import StatureDropdown from "@/components/settings/render-instructions/StatureDropdown.vue"
import UserInitials from "@/components/ui/UserInitials.vue"

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import InlineMessage from "primevue/inlinemessage"
import Tooltip from "primevue/tooltip"
import TreeSelect from "primevue/treeselect"
import Badge from "primevue/badge"
import BadgeDirective from "primevue/badgedirective"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCF2LmrY-TYwgZMDfakFjtmREnDrBGn7c0",
  authDomain: "backoffice-258de.firebaseapp.com",
  projectId: "backoffice-258de",
  storageBucket: "backoffice-258de.appspot.com",
  messagingSenderId: "213073072698",
  appId: "1:213073072698:web:46d2b67c730d3745481b03",
  measurementId: "G-J9L0WGLTLS",
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
getAnalytics(firebaseApp)
const app = createApp(App)
app.use(store)
app.use(router)
app.use(ConfirmationService)
app.use(ToastService)
app.use(PrimeVue, { ripple: true })
app.use(VueAxios, axios)
app.use(mdiVue, {
  icons: {
    mdiAccount,
    mdiAccountTie,
    mdiAccountOutline,
    mdiAccountQuestion,
    mdiArrowLeftRight,
    mdiAlarmPanel,
    mdiAlignHorizontalCenter,
    mdiCheckboxMarkedCircle,
    mdiCheckboxMarkedCircleOutline,
    mdiProgressQuestion,
    mdiMinusCircle,
    mdiCloseCircle,
    mdiAccountCircle,
    mdiAccountGroup,
    mdiAccountMultiple,
    mdiAccountMultiplePlus,
    mdiAlert,
    mdiAlertOutline,
    mdiAlertDecagram,
    mdiAlertCircle,
    mdiAngleAcute,
    mdiArrowCollapse,
    mdiArrowExpand,
    mdiArrowExpandHorizontal,
    mdiArrowLeft,
    mdiArrowLeftBold,
    mdiArrowRight,
    mdiArrowRightBold,
    mdiArrowTopRightBottomLeft,
    mdiAt,
    mdiBlur,
    mdiBorderLeftVariant,
    mdiBrightness6,
    mdiBug,
    mdiCalculatorVariantOutline,
    mdiCalendarCheck,
    mdiCamera,
    mdiCameraBurst,
    mdiCameraOutline,
    mdiCar,
    mdiCardAccountDetailsOutline,
    mdiCash,
    mdiCat,
    mdiCellphoneBasic,
    mdiCellphoneLink,
    mdiCalendarMonth,
    mdiCheck,
    mdiClockPlusOutline,
    mdiFolderGoogleDrive,
    mdiCheckboxBlankCircleOutline,
    mdiCheckboxMultipleBlankCircleOutline,
    mdiChevronLeft,
    mdiChevronRight,
    mdiCircleMedium,
    mdiCircleSmall,
    mdiConnection,
    mdiClose,
    mdiDesktopClassic,
    mdiCog,
    mdiRhombus,
    mdiRemoteDesktop,
    mdiCogOutline,
    mdiContrastCircle,
    mdiCropRotate,
    mdiCrown,
    mdiDatabaseImportOutline,
    mdiDelete,
    mdiDog,
    mdiDomain,
    mdiDownload,
    mdiEmail,
    mdiEmailOutline,
    mdiEyeOffOutline,
    mdiEyeCheckOutline,
    mdiEyeOutline,
    mdiEthernetCable,
    mdiExport,
    mdiFaceAgent,
    mdiFileEyeOutline,
    mdiFileImageMinusOutline,
    mdiFileImageOutline,
    mdiFileImageRemoveOutline,
    mdiFilterVariant,
    mdiFitToPageOutline,
    mdiFlagVariant,
    mdiFlipHorizontal,
    mdiFlipVertical,
    mdiFolderImage,
    mdiFormatListChecks,
    mdiFormatListNumbered,
    mdiFormatText,
    mdiFullscreen,
    mdiFullscreenExit,
    mdiGridLarge,
    mdiHamburger,
    mdiHeartOffOutline,
    mdiHelp,
    mdiHistory,
    mdiHome,
    mdiHomeMapMarker,
    mdiHomeGroupPlus,
    mdiHorseVariant,
    mdiHorseshoe,
    mdiImage,
    mdiInformationOutline,
    mdiInformationVariant,
    mdiInformation,
    mdiInvertColors,
    mdiLabelMultipleOutline,
    mdiMap,
    mdiMapMarker,
    mdiMagnify,
    mdiMagnifyMinusOutline,
    mdiMagnifyPlusOutline,
    mdiMailboxOutline,
    mdiMathNorm,
    mdiMenu,
    mdiMenuDown,
    mdiMidiPort,
    mdiMenuRight,
    mdiMenuUp,
    mdiNumeric,
    mdiLeak,
    mdiLightningBoltCircle,
    mdiOfficeBuilding,
    mdiOpenInNew,
    mdiOrderBoolDescending,
    mdiPower,
    mdiPackageDown,
    mdiPackageUp,
    mdiPackageVariantClosed,
    mdiPartyPopper,
    mdiPencil,
    mdiLinkVariant,
    mdiFileDocument,
    mdiPhone,
    mdiPhoneIncoming,
    mdiPhoneMissed,
    mdiPhoneOutgoing,
    mdiPlay,
    mdiPlus,
    mdiPresentationPlay,
    mdiPrinter,
    mdiQrcode,
    mdiRabbit,
    mdiRotateLeft,
    mdiRotateRight,
    mdiRuler,
    mdiSmokeDetectorAlert,
    mdiShieldAccount,
    mdiSkipNext,
    mdiMemory,
    mdiCpu64Bit,
    mdiMapClock,
    mdiClockOutline,
    mdiDesktopTowerMonitor,
    mdiLaptop,
    mdiSortAlphabeticalAscending,
    mdiSortAlphabeticalDescending,
    mdiSortCalendarAscending,
    mdiSortCalendarDescending,
    mdiStar,
    mdiTagPlusOutline,
    mdiWifi,
    mdiStarOutline,
    mdiSourceBranch,
    mdiSwapVertical,
    mdiTarget,
    mdiTeamviewer,
    mdiToggleSwitch,
    mdiTriangleWave,
    mdiUndo,
    mdiVectorLine,
    mdiWall,
    mdiContentCopy,
    mdiViewDashboard,
    mdiWeatherCloudyClock,
    mdiXml,
    mdiCrop,
    mdiContentSave,
    mdiTransitionMasked,
    mdiFormatRotate90,
    mdiUpdate,
    mdiContrast,
    mdiHammerWrench,
    mdiBattery,
    mdiRestartAlert,
    mdiChevronDown,
    mdiChevronUp,
    mdiCircle,
    mdiUnfoldLessHorizontal,
    mdiCodeGreaterThan,
    mdiCodeGreaterThanOrEqual,
    mdiCodeLessThan,
    mdiCodeLessThanOrEqual,
    mdiEqualBox,
    mdiTableColumnPlusAfter,
    mdiTrashCan,
    mdiWhatsapp,
    mdiClockAlertOutline,
    mdiCart,
    mdiCartCheck,
    mdiMagnifyExpand,
    mdiAccountPlus,
    mdiHospitalBuilding,
    mdiPodiumBronze,
    mdiPodiumSilver,
    mdiPodiumGold,
    mdiAccountVoice,
    mdiPoll,
  },
})
app.directive("tooltip", Tooltip)

app.directive("ripple", Ripple)
//app.component('InputSwitch ', InputSwitch);
app.component("Accordion", Accordion)
app.component("AccordionTab", AccordionTab)
app.component("AutoComplete", AutoComplete)
app.component("Avatar", Avatar)
app.component("AvatarGroup", AvatarGroup)
app.component("Badge", Badge)
app.directive("badge", BadgeDirective)
app.component("Button", Button)
app.component("PrimaryButton", PrimaryButton)
app.component("SecondaryButton", SecondaryButton)
app.component("TertiaryButton", TertiaryButton)
app.component("Calendar", Calendar)
app.component("Checkbox", Checkbox)
app.component("Chips", Chips)
app.component("Column", Column)
app.component("ConfirmPopup", ConfirmPopup)
app.component("ConfirmDialog", ConfirmDialog)
app.component("ContextMenu", ContextMenu)
app.component("DataTable", DataTable)
app.component("DataView", DataView)
app.component("DataViewLayoutOptions", DataViewLayoutOptions)
app.component("Dialog", Dialog)
app.component("Dropdown", Dropdown)
app.component("Inplace", Inplace)
app.component("InputText", InputText)
app.component("InlineMessage", InlineMessage)
app.component("Menu", Menu)
app.component("MultiSelect", MultiSelect)
app.component("Message", Message)
app.component("OverlayPanel", OverlayPanel)
app.component("Password", Password)
app.component("ProgressBar", ProgressBar)
app.component("ProgressSpinner", ProgressSpinner)
app.component("Rating", Rating)
app.component("ScrollPanel", ScrollPanel)
app.component("SelectButton", SelectButton)
app.component("Slider", Slider)
app.component("SplitButton", SplitButton)
app.component("RadioButton", RadioButton)
app.component("Skeleton", Skeleton)
app.component("Tag", Tag)
app.component("Textarea", Textarea)
app.component("TreeTable", TreeTable)
app.component("TreeSelect", TreeSelect)
app.component("TriStateCheckbox", TriStateCheckbox)
app.component("Toast", Toast)
app.component("TabMenu", TabMenu)
app.component("ActiveCalls", ActiveCalls)
app.component("PanelMenu", PanelMenu)
app.component("Listbox", Listbox)
app.component("Divider", Divider)

app.component("NavigationHeader", NavigationHeader)
app.component("NavigationItem", NavigationItem)

app.component("TopNav", TopNav)
app.component("SideBar", SideBar)

app.component("DataCollection", DataCollection)
app.component("DataCollectionListRow", DataCollectionListRow)
app.component("DataCollectionGridCard", DataCollectionGridCard)
app.component("UserComputerOrganizationFilter", UserComputerOrganizationFilter)
app.component("InlineFormError", InlineFormError)

app.component("OrganizationAutoComplete", OrganizationAutoComplete)
app.component("OrganizationForm", OrganizationForm)
app.component("OrganizationCollectionRow", OrganizationCollectionRow)
app.component("OrganizationSwitcher", OrganizationSwitcher)

app.component("ComputerAutoComplete", ComputerAutoComplete)
app.component("ComputerForm", ComputerForm)
app.component("ComputerCollectionRow", ComputerCollectionRow)
app.component("ComputerCollectionFieldsFilter", ComputerCollectionFieldsFilter)
app.component("UserAutoComplete", UserAutoComplete)
app.component("UserForm", UserForm)
app.component("UserCollectionRow", UserCollectionRow)

app.component("RoleForm", RoleForm)
app.component("RolesCollection", RolesCollection)
app.component("RolesCollectionRow", RolesCollectionRow)

app.component("NetworkForm", NetworkForm)
app.component("NetworkCollection", NetworkCollection)
app.component("NetworkCollectionRow", NetworkCollectionRow)

app.component("RunConfigurationTag", RunConfigurationTag)
app.component("RunConfigurationCollectionCard", RunConfigurationCollectionCard)
app.component("RunConfigurationAutoComplete", RunConfigurationAutoComplete)
app.component("RunConfigurationStatus", RunConfigurationStatus)

app.component("VersionCollectionCard", VersionCollectionCard)

app.component("ProfilePasswordForm", ProfilePasswordForm)
app.component("EmployeeForm", EmployeeForm)
app.component("EmployeeRole", EmployeeRole)

app.component("SettingForm", SettingsForm)
app.component("SettingsCollectionRecord", SettingsCollectionRecord)
app.component("SettingsCollectionRecordValues", SettingsCollectionRecordValues)
app.component("SettingsCollectionForm", SettingsCollectionForm)
app.component("FilterOverlay", FilterOverlay)
app.component("RenderInstructionRuleCollection", RenderInstructionRuleCollection)
app.component("RenderInstructionRuleForm", RenderInstructionRuleForm)
app.component("ProcessingFileAutoComplete", ProcessingFileAutoComplete)

app.component("AnnotationRuleForm", AnnotationRuleForm)
app.component("CroppingRuleForm", CroppingRuleForm)
app.component("CVInputRuleForm", CVInputRuleForm)
app.component("OrientationRuleForm", OrientationRuleForm)
app.component("WindowingsForm", WindowingsForm)
app.component("LutRuleForm", LutRuleForm)
app.component("RilutRuleForm", RilutRuleForm)

app.component("ProcessingRuleValue", ProcessingRuleValue)
app.component("ProcessingRuleForm", ProcessingRuleForm)
app.component("OrientationRuleValue", OrientationRuleValue)
app.component("AnnotationRuleValue", AnnotationRuleValue)
app.component("CroppingRuleValue", CroppingRuleValue)
app.component("CVInputRuleValue", CVInputRuleValue)
app.component("LutRuleValue", LutRuleValue)
app.component("RilutRuleValue", RilutRuleValue)

app.component("PanelSettingsCollection", PanelSettingsCollection)
app.component("PanelSettingsRow", PanelSettingsRow)

app.component("SpeciesDropdown", SpeciesDropdown)
app.component("AnatomyDropdown", AnatomyDropdown)
app.component("DirectionDropdown", DirectionDropdown)
app.component("LegDropdown", LegDropdown)
app.component("StatureDropdown", StatureDropdown)

app.component("TransitionHeight", TransitionHeight)
app.component("UserInitials", UserInitials)

app.mount("#app")
