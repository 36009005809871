<template>
  <div class="relative">
    <AutoComplete
      v-model="modelValue"
      class="w-full"
      :class="{ 'p-invalid': invalid }"
      :suggestions="filteredUserOptions"
      :dropdown="true"
      :placeholder="placeholder"
      :disabled="disabled"
      :field="(item) => userToString(item)"
      force-selection
      @complete="searchUser($event)"
      @item-select="$emit('update:modelValue', modelValue)"
      @item-unselect="$emit('update:modelValue', modelValue)"
    >
      <template #item="slotProps">
        <div class="p-2">
          {{ userToString(slotProps.item) }}
        </div>
      </template>
    </AutoComplete>
    <Button
      v-if="modelValue && !disabled"
      icon="pi pi-times"
      class="p-button-rounded p-button-secondary p-button-text clear"
      @click="$emit('update:modelValue', null)"
    />
  </div>
</template>

<script>
import UserService from "@/services/UserService"
import { ref, watch } from "vue"
import OrganizationService from "@/services/OrganizationService"
import ComputerService from "@/services/ComputerService"

export default {
  props: {
    modelValue: Object,
    excludes: {
      type: Array,
      default: [],
    },
    includes: {
      type: Array,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Select a user",
    },
    invalid: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const userService = new UserService()
    const allUserOptions = ref([])
    const filteredUserOptions = ref([])

    initialize()

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        //emit("update:modelValue", newValue);
      }
    )

    watch(
      () => props.includes,
      (newValue, oldValue) => initialize()
    )

    function initialize() {
      if (props.includes == null) {
        // Load all options
        loadUserOptions()
      } else {
        // Only use includes
        allUserOptions.value = props.includes
      }
    }

    function loadUserOptions() {
      userService.getAllUsers().then((response) => {
        allUserOptions.value = response
        filteredUserOptions.value = response
        filterExcludes()
      })
    }

    function searchUser(event) {
      if (!event.query.trim().length) {
        filteredUserOptions.value = [...allUserOptions.value]
      } else {
        filteredUserOptions.value = allUserOptions.value.filter((userOption) => {
          return userToString(userOption).toLowerCase().indexOf(event.query.toLowerCase()) >= 0
        })
      }
      filterExcludes()
    }

    function filterExcludes() {
      filteredUserOptions.value = filteredUserOptions.value.filter((userOption) => {
        return !props.excludes.includes(userOption.id)
      })
    }

    function userToString(user) {
      let result = user.username

      if (
        (user.firstName && user.firstName.length > 0) ||
        (user.lastName != null && user.lastName.length > 0)
      ) {
        result += " -"
      }

      if (user.firstName && user.firstName.length > 0) {
        result += " " + user.firstName
      }

      if (user.lastName != null && user.lastName.length > 0) {
        result += " " + user.lastName
      }

      return result
    }

    return {
      filteredUserOptions,
      searchUser,
      userToString,
    }
  },
}
</script>
<style scoped>
.clear {
  position: absolute;
  top: 5px;
  right: 40px;
}
</style>
