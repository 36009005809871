import axios from "axios"
import ResultSet from "@/models/ResultSet"
import ChangeCompanyService from "./ChangeCompanyService"
import { Organization } from "@/models/Organization"

export default class OrganizationService extends ChangeCompanyService {
  pageSize: number = 10000

  private static instance: OrganizationService

  private constructor() {
    super("organizations")
  }

  public static getInstance(): OrganizationService {
    if (!OrganizationService.instance) {
      OrganizationService.instance = new OrganizationService()
    }
    return OrganizationService.instance
  }

  async getOrganization(organizationID: string): Promise<Organization> {
    const response = await axios.get("organizations/" + organizationID)
    return response.data
  }

  getOrganizations(
    part: Number = 0,
    sort: any,
    filter: string,
    companyId?: string,
    pageSize?: number
  ): Promise<ResultSet<Organization>> {
    let oThis = this
    let url =
      "organizations?size=" +
      (pageSize ? pageSize : this.pageSize) +
      "&page=" +
      part +
      "&sort=" +
      sort +
      "&filter=" +
      filter

    if (companyId) {
      url += "&companyId=" + companyId
    }

    return axios
      .get(url)
      .then((res) => new ResultSet<any>(res.data, pageSize ? pageSize : oThis.pageSize))
  }

  getComputersForOrganization(organizationId: string, part: Number = 0) {
    let oThis = this
    return axios
      .get("organizations/" + organizationId + "/networks?size=" + this.pageSize + "&page" + part, {
        baseURL: process.env.VUE_APP_VSOL_API,
      })
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  addComputerToOrganization(orgId: string, computerId: string) {
    return axios
      .post("organizations/" + orgId + "/computer?computerId=" + computerId)
      .then((res) => res.data)
  }

  deleteComputerFromOrganization(orgId: string, computerId: string) {
    return axios.delete("organizations/" + orgId + "/computer?computerId=" + computerId)
  }

  getUsersForOrganization(organizationId: string, part: Number = 0) {
    let oThis = this
    return axios
      .get("organizations/" + organizationId + "/roles?size=" + this.pageSize + "&page" + part, {
        baseURL: process.env.VUE_APP_VSOL_API,
      })
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  addOrganization(organizationData: any, companyId?: string) {
    const url = `organizations${companyId ? "?companyId=" + companyId : ""}`
    return axios.post(url, organizationData).then((res) => res.data)
  }

  updateOrganization(organizationID: string, organizationData: any) {
    return axios.put("organizations/" + organizationID, organizationData)
  }

  deleteOrganization(organizationID: string) {
    return axios.delete("organizations/" + organizationID)
  }

  getAllOrganizations() {
    return axios.get("organizations/list").then((res) => res.data)
  }

  putOrganizationToXavier(organizationID: string) {
    axios.put("organizations/" + organizationID + "/xavier")
  }

  getAddresses(organizationId: string, part: Number, sort: string) {
    return axios
      .get("addresses?organizationId=" + organizationId + "&page=" + part + "&sort=" + sort)
      .then((res) => new ResultSet<any>(res.data, this.pageSize))
  }

  addAddress(organizationId: string, addressData: any) {
    return axios
      .post("addresses?organizationId=" + organizationId, addressData)
      .then((res) => res.data)
  }

  editAddress(id: string, addressData: any) {
    return axios.put("addresses/" + id, addressData).then((res) => res.data)
  }

  deleteAddresses(id: string) {
    let url = "addresses?cascade=false&ids=" + id

    return axios.delete(url).then((res) => res.data)
  }

  addAddressTypeToAddress(addressId: string, category: string, standard: boolean) {
    const url =
      "addresses/" + addressId + "/addresstype?category=" + category + "&standard=" + standard
    return axios.put(url).then((res) => res.data)
  }

  deleteAddressTypeFromAddress(addressId: string, category: string, standard: boolean) {
    const url =
      "addresses/" + addressId + "/addresstype?category=" + category + "&standard=" + standard
    return axios.delete(url).then((res) => res.data)
  }

  getContactInfo(organizationId: string, part: Number, sort: string) {
    return axios
      .get(
        "contacts?organizationId=" +
          organizationId +
          "&page=" +
          part +
          "&sort=" +
          sort +
          "&size=" +
          this.pageSize
      )
      .then((res) => new ResultSet<any>(res.data, this.pageSize))
  }

  addContactInfo(organizationId: string, contactInfoData: any) {
    return axios
      .post("contacts?organizationId=" + organizationId, contactInfoData)
      .then((res) => res.data)
  }

  editContactInfo(id: string, contactInfoData: any) {
    return axios.put("contacts/" + id, contactInfoData).then((res) => res.data)
  }

  deleteContactInfo(id: string) {
    let url = "contacts?cascade=false&ids=" + id

    return axios.delete(url).then((res) => res.data)
  }

  cloneEquineFromOrg(to: string) {
    let url = "organizations/clone?from=a63ed0ea-5560-4e0e-a373-2214a026701b&to=" + to

    return axios.post(url, null, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  cloneSmallAnimalFromOrg(to: string) {
    let url = "organizations/clone?from=f4bfaa97-bdae-4c76-89a6-b8138af618df&to=" + to

    return axios.post(url, null, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  //COMPANY

  addCompanyToOrg(orgId: string, companyId: string) {
    let url = "organizations/" + orgId + "/company?companyId=" + companyId
    return axios.post(url)
  }

  //DICOM MANAGEMENT
  addDicomMapping(id: string, mappingData: any) {
    return axios.post("dicommappings?dcID=" + id, mappingData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  deleteDicomMapping(id: string) {
    return axios.delete("dicommappings/" + id, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  updateDicomMapping(id: string, mappingData: any) {
    return axios.put("dicommappings/" + id, mappingData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  addDicomRules(dcId: string, ruleData: any) {
    return axios.post("dicomrules?dcID=" + dcId, ruleData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  deleteDicomRule(id: string) {
    return axios.delete("dicomrules/" + id, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  updateDicomRule(id: string, dcData: any) {
    return axios.put("dicomrules/" + id, dcData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  getDicomTagConfig(id: string) {
    return axios.get("dicomtagconfigs/" + id, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  deleteDicomTagConfig(id: string) {
    return axios.delete("dicomtagconfigs/" + id, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  getDicomTagConfigsForOrg(organizationId: string, part: Number = 0) {
    return axios.get(
      "dicomtagconfigs/list/support?organizationId=" + organizationId + "&part=" + part,
      {
        baseURL: process.env.VUE_APP_VSOL_API,
      }
    )
  }

  addDicomTagConfigsForOrg(organizationId: string, configData: any) {
    return axios.post("dicomtagconfigs/support?organizationId=" + organizationId, configData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  addDicomTagConfigsForComputer(computerId: string, configData: any) {
    return axios.post("dicomtagconfigs/support?computerId=" + computerId, configData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  updateDicomTagConfigs(configIds: [], configData: any) {
    return axios.put("dicomtagconfigs/support?ids=" + configIds, configData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  getDicomMappingConfig(id: string) {
    return axios.get("dicommappingconfigs/" + id, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  deleteDicomMappingConfig(id: string) {
    return axios.delete("dicommappingconfigs/" + id, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  getDicomMappingConfigsForOrg(organizationId: string, part: Number = 0) {
    return axios.get(
      "dicommappingconfigs/list/support?organizationId=" + organizationId + "&part=" + part,
      {
        baseURL: process.env.VUE_APP_VSOL_API,
      }
    )
  }

  addDicomMappingConfigForOrg(organizationId: string, configData: any) {
    return axios.post("dicommappingconfigs/support?organizationId=" + organizationId, configData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  addDicomMappingConfigForComputer(computerId: string, configData: any) {
    return axios.post("dicommappingconfigs/support?computerId=" + computerId, configData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  updateDicomMappingConfigs(configIds: [], configData: any) {
    return axios.put("dicommappingconfigs/support?ids=" + configIds, configData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }
}
