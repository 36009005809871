<template>
  <h2>Filters</h2>
  <OrganizationAutoComplete
    v-model="selectedOrganization"
    :includes="organizationOptions"
    placeholder="Select an organization"
    :disabled="inOrganization"
  />
  <UserAutoComplete
    v-model="selectedUser"
    :includes="userOptions"
    placeholder="Select a user"
    class="mb-1"
  />
  <ComputerAutoComplete
    v-model="selectedComputer"
    :includes="computerOptions"
    placeholder="Select a computer"
    class="mb-1"
  />
</template>

<script>
import { ref, watch } from "vue"
import ComputerService from "@/services/ComputerService"
import OrganizationService from "@/services/OrganizationService"
import UserService from "@/services/UserService"
import useArrayUtils from "@/utils/array"
import { useRoute } from "vue-router"

export default {
  name: "OrganizationUserComputerFilter",
  props: {
    selectedUser: {
      type: Object,
      default: null,
    },
    selectedOrganization: {
      type: Object,
      default: null,
    },
    selectedComputer: {
      type: Object,
      default: null,
    },
  },
  emits: ["update:selectedUser", "update:selectedComputer", "update:selectedOrganization"],
  setup(props, { emit }) {
    const userService = new UserService()
    const { intersectionById } = useArrayUtils()
    const computerService = new ComputerService()
    const organizationService = OrganizationService.getInstance()
    const userOptions = ref([])
    const computerOptions = ref([])
    const organizationOptions = ref([])
    const route = useRoute()
    const inOrganization = ref(route.params.organization_id ? true : false)

    updateUserOptions()
    updateComputerOptions()
    updateOrganizationOptions()

    watch(
      () => props.selectedUser,
      (newValue, oldValue) => {
        updateUserOptions()
        updateComputerOptions()
        updateOrganizationOptions()
        emit("update:selectedUser", newValue)
      }
    )

    watch(
      () => props.selectedOrganization,
      (newValue, oldValue) => {
        updateUserOptions()
        updateComputerOptions()
        updateOrganizationOptions()
        emit("update:selectedOrganization", newValue)
      }
    )

    watch(
      () => props.selectedComputer,
      (newValue, oldValue) => {
        updateUserOptions()
        updateComputerOptions()
        updateOrganizationOptions()
        emit("update:selectedComputer", newValue)
      }
    )

    function updateUserOptions() {
      if (props.selectedUser != null) {
        userOptions.value = [props.selectedUser]
      } else {
        if (props.selectedOrganization == null && props.selectedComputer == null) {
          userOptions.value = null
        } else if (props.selectedOrganization != null && props.selectedComputer != null) {
          let computerUsers = null
          let organizationUsers = null

          Promise.all([
            computerService.getUsersForComputer(props.selectedComputer.id).then((response) => {
              computerUsers = response.rows
            }),
            organizationService
              .getUsersForOrganization(props.selectedOrganization.id)
              .then((response) => {
                organizationUsers = response.rows.map((role) => role.user)
              }),
          ]).then(() => {
            userOptions.value = intersectionById(computerUsers, organizationUsers)
          })
        } else if (props.selectedComputer != null) {
          computerService.getUsersForComputer(props.selectedComputer.id).then((response) => {
            userOptions.value = response.rows
          })
        } else if (props.selectedOrganization != null) {
          organizationService
            .getUsersForOrganization(props.selectedOrganization.id)
            .then((response) => {
              userOptions.value = response.rows.map((role) => role.user)
            })
        }
      }
    }

    function updateComputerOptions() {
      if (props.selectedComputer != null) {
        computerOptions.value = [props.selectedComputer]
      } else {
        if (props.selectedOrganization == null && props.selectedUser == null) {
          computerOptions.value = null
        } else if (props.selectedOrganization != null && props.selectedUser != null) {
          let userComputers = null
          let organizationComputers = null

          Promise.all([
            userService.getComputersForUser(props.selectedUser.id).then((response) => {
              userComputers = response.rows
            }),

            organizationService
              .getComputersForOrganization(props.selectedOrganization.id)
              .then((response) => {
                organizationComputers = response.rows.map((role) => role.computer)
              }),
          ]).then(() => {
            computerOptions.value = intersectionById(userComputers, organizationComputers)
          })
        } else if (props.selectedUser != null) {
          userService.getComputersForUser(props.selectedUser.id).then((response) => {
            computerOptions.value = response.rows
          })
        } else if (props.selectedOrganization != null) {
          organizationService
            .getComputersForOrganization(props.selectedOrganization.id)
            .then((response) => {
              computerOptions.value = response.rows.map((role) => role.computer)
            })
        }
      }
    }

    function updateOrganizationOptions() {
      if (props.selectedOrganization != null) {
        organizationOptions.value = [props.selectedOrganization]
      } else {
        if (props.selectedComputer == null && props.selectedUser == null) {
          organizationOptions.value = null
        } else if (props.selectedComputer != null && props.selectedUser != null) {
          let userOrganizations = null
          let computerOrganizations = null

          Promise.all([
            computerService
              .getOrganizationsForComputer(props.selectedComputer.id)
              .then((response) => {
                computerOrganizations = response.rows.map((network) => network.organization)
              }),

            userService.getOrganizationsForUser(props.selectedUser.id).then((response) => {
              userOrganizations = response.rows.map((role) => role.organization)
            }),
          ]).then(() => {
            organizationOptions.value = intersectionById(computerOrganizations, userOrganizations)
          })
        } else if (props.selectedUser != null) {
          userService.getOrganizationsForUser(props.selectedUser.id).then((response) => {
            organizationOptions.value = response.rows.map((role) => role.organization)
          })
        } else if (props.selectedComputer != null) {
          computerService
            .getOrganizationsForComputer(props.selectedComputer.id)
            .then((response) => {
              organizationOptions.value = response.rows.map((network) => network.organization)
            })
        }
      }
    }

    return {
      userOptions,
      organizationOptions,
      computerOptions,
      inOrganization,
    }
  },
}
</script>
