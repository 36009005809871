import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import store from "../store/index"
import { ref } from "vue"
import ReportTemplates from "@/components/reports/ReportTemplates.vue"
import ReportBackgroundList from "@/components/reports/tools/ReportBackgroundList.vue"
import ReportClosingList from "@/components/reports/tools/ReportClosingList.vue"
import { isCurrentUrlPublic, checkRole } from "@/helpers/UrlPermissionHelper"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () => import("../components/Login.vue"),
    meta: { requiresAuth: false, fullScreen: true },
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: () => import("../components/Forbidden.vue"),
    meta: { fullScreen: true },
  },
  {
    path: "/quotationlink/:id?",
    name: "salesQuotationLink",
    component: () => import("../components/sales/salesQuotationLink.vue"),
    meta: { requiresAuth: false, fullScreen: true, public: true },
  },
  {
    path: "/users",
    name: "usr",
    component: () => import("../components/users/Users.vue"),
    children: [
      {
        path: "",
        name: "users",
        component: () => import("../components/users/UserCollection.vue"),
      },
      {
        path: "sort/:sortOptions",
        name: "usersSorted",
        component: () => import("../components/users/UserCollection.vue"),
      },
      { path: "new", name: "newUser", component: () => import("../components/users/UserNew.vue") },
      {
        path: ":user_id",
        name: "user",
        component: () => import("../components/users/User.vue"),
        children: [
          {
            path: "profile",
            name: "userProfile",
            component: () => import("../components/users/UserProfile.vue"),
          },
          {
            path: "settings",
            name: "userSettings",
            component: () => import("../components/users/UserSettings.vue"),
            redirect: { name: "userSettingsCollection" },
            children: [
              {
                path: "",
                name: "userSettingsCollection",
                component: () => import("../components/settings/general/SettingsCollection.vue"),
              },
            ],
          },
          {
            path: "roles",
            name: "userRoles",
            component: () => import("../components/users/UserRoles.vue"),
          },
          {
            path: "roles/sort/:sortOptions",
            name: "userRolesSorted",
            component: () => import("../components/users/UserRoles.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../components/settings/SettingsHome.vue"),
    redirect: { name: "settingsHome" },
    children: [
      {
        path: "general",
        name: "settingsHome",
        component: () => import("../components/settings/general/Settings.vue"),
        redirect: { name: "settingsCollection" },
        children: [
          {
            path: "",
            name: "settingsCollection",
            component: () => import("../components/settings/general/SettingsCollection.vue"),
          },
        ],
      },
      {
        path: "processing",
        name: "processing",
        component: () => import("../components/settings/processings/ProcessingRule.vue"),
        children: [
          {
            path: "",
            name: "processingCollection",
            component: () =>
              import("../components/settings/processings/ProcessingRuleCollection.vue"),
          },
        ],
      },
      {
        path: "customprocessing",
        name: "customprocessing",
        component: () =>
          import("../components/settings/processings/custom/CustomProcessingsCollection.vue"),
        children: [
          {
            path: "",
            name: "customProcessingsCollection",
            component: () =>
              import("../components/settings/processings/custom/CustomProcessingsCollection.vue"),
          },
        ],
      },
      {
        path: "rendering",
        name: "rendering",
        component: () =>
          import("../components/settings/render-instructions/RenderInstructions.vue"),
        redirect: { name: "orientationsCollection" },
        children: [
          {
            path: "annotations",
            name: "annotations",
            component: () =>
              import("../components/settings/render-instructions/annotations/AnnotationRules.vue"),
            children: [
              {
                path: "",
                name: "annotationsCollection",
                component: () =>
                  import(
                    "../components/settings/render-instructions/annotations/AnnotationRulesCollection.vue"
                  ),
              },
            ],
          },
          {
            path: "windowings",
            name: "windowings",
            component: () =>
              import("../components/settings/render-instructions/windowings/Windowings.vue"),
            children: [
              {
                path: "",
                name: "windowingsCollection",
                component: () =>
                  import(
                    "../components/settings/render-instructions/windowings/WindowingsCollection.vue"
                  ),
              },
            ],
          },
          {
            path: "ricustomprocessing",
            name: "ricustomprocessing",
            component: () =>
              import(
                "../components/settings/render-instructions/ricustom/RiCustomProcessingsCollection.vue"
              ),
          },
          {
            path: "luts",
            name: "luts",
            component: () =>
              import("../components/settings/render-instructions/luts/LutRuleCollection.vue"),
          },
          {
            path: "riluts",
            name: "riluts",
            component: () =>
              import("../components/settings/render-instructions/riluts/RilutRuleCollection.vue"),
          },
          {
            path: "orientations",
            name: "orientations",
            component: () =>
              import(
                "../components/settings/render-instructions/orientations/OrientationRules.vue"
              ),
            children: [
              {
                path: "",
                name: "orientationsCollection",
                component: () =>
                  import(
                    "../components/settings/render-instructions/orientations/OrientationRuleCollection.vue"
                  ),
              },
            ],
          },
          {
            path: "cvinput",
            name: "cvinput",
            component: () =>
              import("../components/settings/render-instructions/cvinput/CVInputRules.vue"),
            children: [
              {
                path: "",
                name: "CVInputCollection",
                component: () =>
                  import(
                    "../components/settings/render-instructions/cvinput/CVInputRuleCollection.vue"
                  ),
              },
            ],
          },
          {
            path: "croppings",
            name: "cropping",
            component: () =>
              import("../components/settings/render-instructions/croppings/CroppingRules.vue"),
            children: [
              {
                path: "",
                name: "croppingCollection",
                component: () =>
                  import(
                    "../components/settings/render-instructions/croppings/CroppingRuleCollection.vue"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/hardware",
    name: "hardwareSettings",
    component: () => import("../components/settings/hardware/HardwareSettings.vue"),
    redirect: { name: "panelSettings" },
    meta: {
      companies: ["VSOL"],
    },
    children: [
      {
        path: "generators",
        name: "generatorSettings",
        component: () => import("../components/settings/hardware/GeneratorSettingsCollection.vue"),
      },
      {
        path: "measurers",
        name: "measurerSettings",
        component: () => import("../components/settings/hardware/MeasurerSettingsCollection.vue"),
      },
      {
        path: "panels",
        name: "panelSettings",
        component: () => import("../components/settings/hardware/PanelSettingsCollection.vue"),
      },
    ],
  },
  {
    path: "/tasks",
    name: "tasks",
    component: () => import("../components/tasks/weekview/TaskWeekView.vue"),
  },
  {
    path: "/sales",
    name: "sales",
    component: () => import("../components/sales/sales.vue"),
    redirect: { name: "salesQuotations" },
    children: [
      {
        path: "/sales/productmanagement",
        name: "salesProductManagement",
        component: () => import("../components/sales/salesProductManagement.vue"),
      },
      {
        path: "/sales/quotations",
        name: "salesQuotations",
        component: () => import("../components/sales/salesQuotations.vue"),
        children: [
          {
            path: "quotationeditor/:quotation_id",
            name: "salesQuotationEditor",
            component: () => import("../components/sales/salesQuotationEditor.vue"),
          },
          {
            path: ":sale_id/edit",
            name: "salesEditor",
            component: () => import("../components/sales/salesComponent.vue"),
          },
          {
            path: "task/:task_id",
            name: "salesTask",
            component: () => import("../components/tasks/TaskDetails.vue"),
          },
        ],
      },
      {
        path: "/sales/productoverview",
        name: "productOverview",
        component: () => import("../components/sales/salesProductOverview.vue"),
      },
      {
        path: "/sales/templates",
        name: "salesTemplates",
        component: () => import("../components/sales/salesTemplates.vue"),
      },
    ],
  },
  {
    path: "/org",
    name: "org",
    component: () => import("../components/organizations/Organizations.vue"),
    redirect: { name: "organizations" },
    children: [
      {
        path: "",
        name: "organizations",
        component: () => import("../components/organizations/OrganizationCollection.vue"),
      },
      {
        path: "sort/:sortOptions",
        name: "organizationsSorted",
        component: () => import("../components/organizations/OrganizationCollection.vue"),
      },
      {
        path: "new",
        name: "newOrganization",
        component: () => import("../components/organizations/OrganizationNew.vue"),
      },
      {
        path: ":organization_id",
        name: "organization",
        component: () => import("../components/organizations/Organization.vue"),
        redirect: { name: "organizationDashboard" },
        children: [
          {
            path: "dashboard",
            name: "organizationDashboard",
            component: () => import("@/components/organizations/OrganizationDashboard.vue"),
          },
          {
            path: "profile",
            name: "organizationProfile",
            component: () => import("../components/organizations/OrganizationProfile.vue"),
          },
          {
            path: "contact",
            name: "organizationContactInfo",
            component: () => import("../components/organizations/OrganizationContactInfo.vue"),
          },
          {
            path: "templates",
            name: "templates",
            component: () =>
              import("@/components/organizations/templates/OrganizationTemplates.vue"),
            redirect: { name: "reportTemplates" },
            children: [
              {
                path: "reportTemplates",
                name: "reportTemplates",
                component: () => import("../components/reports/TemplatesList.vue"),
              },
              {
                path: "reportTemplatesParts",
                name: "reportTemplatesParts",
                redirect: { name: "SettingReportBody" },
                component: () => import("../components/reports/ReportTemplatesTabs.vue"),
                children: [
                  {
                    path: "body",
                    name: "SettingReportBody",
                    component: ReportTemplates,
                    props: { breadcrumb: ref("Body"), type: "REPORT_CONTENT" },
                  },
                  {
                    path: "header",
                    name: "SettingReportHeader",
                    component: ReportTemplates,
                    props: { breadcrumb: ref("Header"), type: "REPORT_HEADER" },
                  },
                  {
                    path: "contact",
                    name: "SettingReportContactInfo",
                    component: ReportTemplates,
                    props: { breadcrumb: ref("Contact Info"), type: "REPORT_CONTENT_HEADER_LEFT" },
                  },
                  {
                    path: "medical",
                    name: "SettingReportMedicalInfo",
                    component: ReportTemplates,
                    props: { breadcrumb: ref("Medical Info"), type: "REPORT_CONTENT_HEADER_RIGHT" },
                  },
                  {
                    path: "footer",
                    name: "SettingReportFooter",
                    component: ReportTemplates,
                    props: { breadcrumb: ref("Footer"), type: "REPORT_FOOTER" },
                  },
                  {
                    path: "signature",
                    name: "SettingReportSignature",
                    component: ReportClosingList,
                    props: { breadcrumb: ref("Signature"), type: "REPORT_SIGNATURE" },
                  },
                  {
                    path: "closing",
                    name: "SettingReportsClosing",
                    component: ReportTemplates,
                    props: { breadcrumb: ref("Closing"), type: "REPORT_CLOSING" },
                  },
                  {
                    path: "terms",
                    name: "SettingReportTerms",
                    component: ReportTemplates,
                    props: { breadcrumb: ref("Terms"), type: "REPORT_TERMS_AND_CONDITIONS" },
                  },
                  {
                    path: "background",
                    name: "SettingReportBackground",
                    component: ReportBackgroundList,
                    props: { breadcrumb: ref("Background"), type: "REPORT_BACKGROUND" },
                  },
                ],
              },
              {
                path: "email-templates",
                name: "EmailTemplateSettings",
                component: () => import("../components/emails/EmailTemplateSettings.vue"),
                redirect: { name: "EmailTemplates" },
                props: true,
                children: [
                  {
                    path: "",
                    name: "EmailTemplates",
                    component: () =>
                      import("../components/emails/email-templates/EmailTemplates.vue"),
                    props: { newTemplateRoute: "NewEmailTemplate" },
                  },
                  {
                    path: "new",
                    name: "NewEmailTemplate",
                    component: () =>
                      import("../components/emails/email-templates/NewEmailTemplate.vue"),
                    props: { previousRouteName: "EmailTemplateSettings" },
                  },
                ],
              },
              {
                path: "aqsTemplates",
                name: "aqsTemplates",
                component: () => import("../components/aqs/AqsTemplates.vue"),
                props: true,
              },
            ],
          },
          {
            path: "organizationStats",
            name: "organizationStats",
            component: () => import("../components/organizations/OrganizationStats.vue"),
          },
          {
            path: "settings",
            name: "organizationSettings",
            component: () => import("../components/organizations/OrganizationSettings.vue"),
            redirect: { name: "organizationSettingsCollection" },
            children: [
              {
                path: "",
                name: "organizationSettingsCollection",
                component: () => import("../components/settings/general/SettingsCollection.vue"),
              },
            ],
          },
          {
            path: "roles",
            name: "organizationRoles",
            component: () => import("../components/organizations/OrganizationRoles.vue"),
          },
          {
            path: "roles/sort/:sortOptions",
            name: "organizationRolesSorted",
            component: () => import("../components/organizations/OrganizationRoles.vue"),
          },
          {
            path: "orgImaging",
            name: "orgImaging",
            component: () => import("@/components/organizations/imaging/OrganizationImaging.vue"),
            redirect: { name: "organizationProcessing" },
            children: [
              {
                path: "processing",
                name: "organizationProcessing",
                component: () => import("@/components/settings/processings/ProcessingRule.vue"),
                redirect: { name: "organizationProcessingCollection" },
                children: [
                  {
                    path: "",
                    name: "organizationProcessingCollection",
                    component: () =>
                      import("@/components/settings/processings/ProcessingRuleCollection.vue"),
                  },
                ],
              },
              {
                path: "customprocessings",
                name: "organizationCustomProcessings",
                component: () =>
                  import(
                    "@/components/settings/processings/custom/CustomProcessingsCollection.vue"
                  ),
              },
              {
                path: "orientation",
                name: "organizationOrientation",
                component: () =>
                  import(
                    "@/components/settings/render-instructions/orientations/OrientationRules.vue"
                  ),
                redirect: { name: "organizationOrientationCollection" },
                children: [
                  {
                    path: "",
                    name: "organizationOrientationCollection",
                    component: () =>
                      import(
                        "@/components/settings/render-instructions/orientations/OrientationRuleCollection.vue"
                      ),
                  },
                ],
              },
              {
                path: "cropping",
                name: "organizationCropping",
                component: () =>
                  import("@/components/settings/render-instructions/croppings/CroppingRules.vue"),
                redirect: { name: "organizationCroppingCollection" },
                children: [
                  {
                    path: "",
                    name: "organizationCroppingCollection",
                    component: () =>
                      import(
                        "@/components/settings/render-instructions/croppings/CroppingRuleCollection.vue"
                      ),
                  },
                ],
              },
              {
                path: "annotations",
                name: "organizationAnnotations",
                component: () =>
                  import(
                    "@/components/settings/render-instructions/annotations/AnnotationRules.vue"
                  ),
                redirect: { name: "organizationAnnotationCollection" },
                children: [
                  {
                    path: "",
                    name: "organizationAnnotationCollection",
                    component: () =>
                      import(
                        "@/components/settings/render-instructions/annotations/AnnotationRulesCollection.vue"
                      ),
                    props: true,
                  },
                ],
              },
              {
                path: "cvinput",
                name: "organizationCvinput",
                redirect: { name: "organizationCVInputCollection" },
                component: () =>
                  import("../components/settings/render-instructions/cvinput/CVInputRules.vue"),
                children: [
                  {
                    path: "",
                    name: "organizationCVInputCollection",
                    component: () =>
                      import(
                        "../components/settings/render-instructions/cvinput/CVInputRuleCollection.vue"
                      ),
                    props: true,
                  },
                ],
              },
              {
                path: "windowings",
                name: "organizationWindowings",
                redirect: { name: "organizationWindowingsCollection" },
                component: () =>
                  import("@/components/settings/render-instructions/windowings/Windowings.vue"),
                children: [
                  {
                    path: "",
                    name: "organizationWindowingsCollection",
                    component: () =>
                      import(
                        "@/components/settings/render-instructions/windowings/WindowingsCollection.vue"
                      ),
                    props: true,
                  },
                ],
              },
              {
                path: "ricustomprocessings",
                name: "organizationRiCustomProcessings",
                component: () =>
                  import(
                    "@/components/settings/render-instructions/ricustom/RiCustomProcessingsCollection.vue"
                  ),
                props: true,
              },
              {
                path: "luts",
                name: "organizationLuts",
                component: () =>
                  import("@/components/settings/render-instructions/luts/LutRuleCollection.vue"),
              },
              {
                path: "riluts",
                name: "organizationRiluts",
                component: () =>
                  import(
                    "@/components/settings/render-instructions/riluts/RilutRuleCollection.vue"
                  ),
              },
            ],
          },
          {
            path: "organizationdicoms",
            name: "orgDicoms",
            component: () => import("@/components/organizations/dicoms/Dicoms.vue"),
            redirect: { name: "dicomApplicationEntities" },
            children: [
              {
                path: "dicomapplicationentities",
                name: "dicomApplicationEntities",
                component: () =>
                  import("../components/settings/hardware/DicomApplicationEntitiesCollection.vue"),
              },
              {
                path: "dicomtagconfigs",
                name: "dicomtagconfigs",
                component: () => import("@/components/organizations/dicoms/DicomTagConfigList.vue"),
              },
              {
                path: "dicommappingconfigs",
                name: "dicommappingconfigs",
                component: () =>
                  import("@/components/organizations/dicoms/DicomMappingConfigList.vue"),
              },
            ],
          },
          {
            path: "tasks",
            name: "organizationTasks",
            component: () => import("@/components/organizations/OrganizationTasks.vue"),
          },
          {
            path: "sales/quotations",
            name: "organizationSales",
            component: () => import("../components/sales/salesQuotations.vue"),
            children: [
              {
                path: "quotationeditor/:quotation_id",
                name: "organizationSalesQuotationEditor",
                component: () => import("../components/sales/salesQuotationEditor.vue"),
              },
              {
                path: ":sale_id/edit",
                name: "organizationSalesEditor",
                component: () => import("../components/sales/salesComponent.vue"),
              },
              {
                path: "task/:task_id",
                name: "organizationSalesTask",
                component: () => import("../components/tasks/TaskDetails.vue"),
              },
            ],
          },
          {
            path: "communication",
            name: "organizationCallHistory",
            component: () => import("@/components/organizations/OrganizationCallHistory.vue"),
          },
          {
            path: "customizations",
            name: "organizationCustomizations",
            component: () =>
              import("@/components/organizations/customizations/OrganizationCustomizations.vue"),
            redirect: { name: "organizationCustomizationsAQS" },
            children: [
              {
                path: "aqs",
                name: "organizationCustomizationsAQS",
                component: () => import("@/components/customizations/AqsCustomizations.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/computers",
    name: "cmp",
    component: () => import("../components/computers/Computers.vue"),
    redirect: { name: "computers" },
    children: [
      {
        path: "",
        name: "computers",
        component: () => import("../components/computers/ComputerCollection.vue"),
      },
      {
        path: "sort/:sortOptions",
        name: "computersSorted",
        component: () => import("../components/computers/ComputerCollection.vue"),
      },
      {
        path: ":computer_id",
        name: "computer",
        component: () => import("../components/computers/Computer.vue"),
        redirect: { name: "computerProfile" },
        children: [
          {
            path: "profile",
            name: "computerProfile",
            component: () => import("../components/computers/ComputerProfile.vue"),
          },
          {
            path: "network",
            name: "computerNetwork",
            component: () => import("@/components/computers/ComputerNetwork.vue"),
          },
          {
            path: "remote-access",
            name: "computerRemoteAccess",
            component: () => import("@/components/computers/ComputerRemoteAccess.vue"),
          },
          /*           {
            path: "computerdicoms",
            name: "computerDicoms",
            component: () => import("../components/computers/dicoms/ComputerDicoms.vue"),
            redirect: { name: "dicomTagConfig" },
            children: [
              {
                path: "dicomtagconfig",
                name: "dicomTagConfig",
                component: () =>
                  import("../components/computers/dicoms/ComputerDicomTagConfigList.vue"),
              },
              {
                path: "dicommappingconfig",
                name: "dicomMappingConfig",
                component: () =>
                  import("../components/computers/dicoms/ComputerDicomMappingConfigsList.vue"),
              },
              {
                path: "dicomapplicationentities",
                name: "connectedDicomApplicationEntities",
                component: () =>
                  import("../components/settings/hardware/DicomApplicationEntitiesCollection.vue"),
              },
            ],
          }, */
          {
            path: "hardware",
            name: "connectedHardware",
            redirect: { name: "connectedPanels" },
            component: () => import("../components/computers/ComputerConnectedHardware.vue"),
            children: [
              {
                path: "panels",
                name: "connectedPanels",
                component: () =>
                  import("../components/settings/hardware/PanelSettingsCollection.vue"),
              },
              {
                path: "generators",
                name: "connectedGenerators",
                component: () =>
                  import("../components/settings/hardware/GeneratorSettingsCollection.vue"),
              },
              {
                path: "measurers",
                name: "connectedMeasurers",
                component: () =>
                  import("../components/settings/hardware/MeasurerSettingsCollection.vue"),
              },
            ],
          },
          {
            path: "settings",
            name: "computerSettings",
            component: () => import("../components/computers/ComputerSettings.vue"),
            redirect: { name: "computerSettingsCollection" },
            children: [
              {
                path: "",
                name: "computerSettingsCollection",
                component: () => import("../components/settings/general/SettingsCollection.vue"),
              },
            ],
          },
          {
            path: "network/sort/:sortOptions",
            name: "computerNetworkSorted",
            component: () => import("@/components/computers/ComputerNetwork.vue"),
          },
          {
            path: "logs",
            name: "computerLogs",
            component: () => import("@/components/computers/ComputerLogs.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/logs",
    name: "logs",
    component: () => import("../components/logs/Logs.vue"),
    redirect: { name: "logsCollection" },
    meta: {
      companies: ["VSOL"],
    },
    children: [
      {
        path: "",
        name: "logsCollection",
        component: () => import("../components/logs/LogsCollection.vue"),
      },
    ],
  },
  {
    path: "/licenses",
    name: "licenses",
    component: () => import("../components/licenses/Licenses.vue"),
  },
  {
    path: "/team",
    name: "team",
    component: () => import("../components/team/Team.vue"),
    redirect: { name: "employees" },
    children: [
      {
        path: "employees",
        name: "employees",
        component: () => import("../components/team/EmployeeCollection.vue"),
      },
      {
        path: "companies",
        name: "companies",
        component: () => import("../components/companies/CompaniesCollection.vue"),
        meta: {
          roles: ["SUPER_ADMIN", "SUPER_DEV"],
        },
      },
      {
        path: "usergroups",
        name: "usergroups",
        component: () => import("../components/usergroups/UserGroupCollection.vue"),
      },
    ],
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../components/profile/Profile.vue"),
  },
  {
    path: "/releases",
    name: "releases",
    component: () => import("../components/releases/Releases.vue"),
    redirect: { name: "runConfigs" },
    children: [
      {
        path: "general",
        name: "runConfigs",
        component: () => import("../components/releases/run-configs/RunConfigurations.vue"),
        redirect: { name: "runConfigCollection" },
        children: [
          {
            path: "",
            name: "runConfigCollection",
            component: () =>
              import("../components/releases/run-configs/RunConfigurationCollection.vue"),
          },
          {
            path: "sort/:sortOptions",
            name: "runConfigCollectionSorted",
            component: () =>
              import("../components/releases/run-configs/RunConfigurationCollection.vue"),
          },
        ],
      },
      {
        path: "frontend",
        name: "frontends",
        component: () => import("../components/releases/frontend/Frontends.vue"),
        children: [
          {
            path: "",
            name: "frontendCollection",
            component: () =>
              import("../components/releases/frontend/FrontendVersionCollection.vue"),
          },
          {
            path: "sort/:sortOptions",
            name: "frontendCollectionSorted",
            component: () =>
              import("../components/releases/frontend/FrontendVersionCollection.vue"),
          },
        ],
      },
      {
        path: "backend",
        name: "backends",
        component: () => import("../components/releases/backend/Backends.vue"),
        children: [
          {
            path: "",
            name: "backendCollection",
            component: () => import("../components/releases/backend/BackendVersionCollection.vue"),
          },
          {
            path: "sort/:sortOptions",
            name: "backendCollectionSorted",
            component: () => import("../components/releases/backend/BackendVersionCollection.vue"),
          },
        ],
      },
      {
        path: "desktop",
        name: "aqs",
        component: () => import("../components/releases/desktop/AQSVersions.vue"),
        children: [
          {
            path: "",
            name: "aqsCollection",
            component: () => import("../components/releases/desktop/AQSVersionCollection.vue"),
          },
          {
            path: "sort/:sortOptions",
            name: "aqsCollectionSorted",
            component: () => import("../components/releases/desktop/AQSVersionCollection.vue"),
          },
        ],
      },
      {
        path: "desktop32",
        name: "aqs32",
        component: () => import("../components/releases/desktop/AQSVersions.vue"),
        children: [
          {
            path: "",
            name: "aqs32Collection",
            component: () => import("../components/releases/desktop/AQS32VersionCollection.vue"),
          },
          {
            path: "sort/:sortOptions",
            name: "aqs32CollectionSorted",
            component: () => import("../components/releases/desktop/AQS32VersionCollection.vue"),
          },
        ],
      },
      {
        path: "halapi",
        name: "halapiCollection",
        component: () => import("../components/releases/halapi/HalapiVersionCollection.vue"),
        children: [
          {
            path: "sort/:sortOptions",
            name: "halapiCollectionSorted",
            component: () => import("../components/releases/halapi/HalapiVersionCollection.vue"),
          },
        ],
      },
      {
        path: "toolkit",
        name: "toolkits",
        component: () => import("../components/releases/toolkit/ToolkitVersions.vue"),
        children: [
          {
            path: "",
            name: "toolkitCollection",
            component: () => import("../components/releases/toolkit/ToolkitVersionCollection.vue"),
          },
          {
            path: "sort/:sortOptions",
            name: "toolkitCollectionSorted",
            component: () => import("../components/releases/toolkit/ToolkitVersionCollection.vue"),
          },
        ],
      },
      {
        path: "james",
        name: "james",
        component: () => import("../components/releases/james/James.vue"),
        children: [
          {
            path: "",
            name: "jamesCollection",
            component: () => import("../components/releases/james/JamesVersionCollection.vue"),
          },
          {
            path: "sort/:sortOptions",
            name: "jamesCollectionSorted",
            component: () => import("../components/releases/james/JamesVersionCollection.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/communication",
    name: "callHistory",
    component: () => import("@/components/calls/CallHistory.vue"),
    redirect: { name: "callHistoryCollection" },
    meta: {
      companies: ["VSOL"],
    },
    children: [
      {
        path: "",
        name: "callHistoryCollection",
        component: () => import("@/components/calls/CallsCollection.vue"),
      },
    ],
  },
  {
    path: "/customizations",
    name: "customizations",
    component: () => import("@/components/customizations/Customizations.vue"),
    redirect: { name: "customizationsAQS" },
    children: [
      {
        path: "aqs",
        name: "customizationsAQS",
        component: () => import("@/components/customizations/AqsCustomizations.vue"),
      },
    ],
  },
  {
    path: "/health",
    name: "health",
    component: () => import("@/components/health/Health.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

// Redirect to login page if not logged in and trying to access a restricted page
router.beforeEach((to, _from) => {
  // Pages need to opt-out for authentication
  to.meta.requiresAuth = typeof to.meta.requiresAuth === "undefined" || to.meta.requiresAuth
  const loggedIn = store.getters.getIsLoggedIn

  //don't check this stuff if it's a public link like quotationlink
  if (!isCurrentUrlPublic()) {
    if (to.meta.requiresAuth) {
      if (!loggedIn && to.name !== "login") return { name: "login" }
    } else if (to.name === "login" && loggedIn) {
      return { name: "org" }
    }
  }

  if (!checkRole(to, store.getters.getRoles)) {
    console.log("no permission")
    return { name: "forbidden" }
  } else {
    console.log("permission")
  }
})

export default router
