
import { computed, nextTick, ref, watch, defineComponent } from "vue"
import TieredMenu from "primevue/tieredmenu"
import ContextMenu from "primevue/contextmenu"
export default defineComponent({
  name: "ReportEditor",
  components: { TieredMenu, ContextMenu },
  props: {
    modelValue: String,
    metaData: {
      type: Object,
      default: {},
    },
    height: String,
    editorContent: String,
    readOnly: Boolean,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    let skipUpdate = false
    const insertMenu = ref()
    const rightClickMenu = ref()
    const colorPicker = ref()
    const devMenu = ref()
    const fontOptions = ref([
      { name: "Calibri" },
      // , { name: 'Monospace' }
    ])
    const selectedFont = ref(fontOptions.value[0])

    let previousText: string

    let iframeEl: any
    const init = ref(true)

    function iframeLoaded(e: any) {
      //e.path[0].contentWindow.postMessage({ setContent: props.modelValue }, '*')
      iframeEl = e.path ? e.path[0] : e.srcElement

      if (props.modelValue && ("" + props.modelValue).length) {
        updateTextContent(props.modelValue as string)
      }
    }

    window.addEventListener("message", function (event) {
      //props.editorContent = event.data
      emit("update:modelValue", event.data)
    })

    //MANUAL TYPING CHANGES
    window["easyApi"].changeEditorPart = function () {
      updateTextContent(props.modelValue as string)
    }

    function updateTextContent(text: string) {
      if (text !== previousText && iframeEl) {
        iframeEl.contentWindow.postMessage({ setContent: text }, "*")
        previousText = props.modelValue as string
      }
    }
    updateTextContent("")

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        if (skipUpdate) {
          skipUpdate = false
          return
        }

        if (iframeEl && init.value) {
          updateTextContent(props.modelValue as string)
          init.value = false
        }

        /*if (iframeEl && iframeEl.contentWindow) {
                  iframeEl.contentWindow.postMessage({ setContent: newValue }, '*')
                }*/
        //editor.value.commands.setContent(newValue)
      }
    )
    const showDevOptions = computed(() => process.env.VUE_APP_VSOL_EDITOR_DEBUGGING === "true")

    // function jsonToHTML(json: any) {
    //     replaceSpaces(json)
    //     return generateHTML(json, [
    //         StarterKit, // Includes 'Text'
    //         VueComponent,
    //         DraggableItem,
    //         ReportEditorCheckbox,
    //         TextAlign.configure({
    //             types: ['heading', 'paragraph'],
    //         }),
    //         FontFamily.configure({
    //             types: ['textStyle'],
    //         }),
    //         Highlight.configure({ multicolor: false }),
    //         Table.configure({
    //             resizable: true,
    //         }),
    //         TableRow,
    //         TableHeader,
    //         //TableCell,
    //         CustomTableCell,
    //         Underline,
    //         TextStyle,
    //         ViewerReportPageBreak,
    //         ViewerReportMetaFieldExtension,
    //         Color.configure({
    //             types: ['textStyle'],
    //         }),
    //     ])
    // }
    function replaceSpaces(node: any) {
      if (node.type == "text") {
        node.text = node.text.replaceAll(" ", "⠀") // Convert plain spaces to unicode
      } else if (node.content && node.content.length > 0) {
        for (let childNode of node.content) {
          replaceSpaces(childNode)
        }
      }
    }

    function toggleInsertMenu(event: any) {
      insertMenu.value.toggle(event)
    }
    function toggleDevMenu(event: any) {
      devMenu.value.toggle(event)
    }

    function resolveNode(node: any, metaData: any) {
      if (node.type == "meta") {
        let newText = resolveMetaFromJson(metaData, node.attrs["meta-key"])
        if (newText) {
          node.content = [
            {
              type: "text",
              text: newText,
            },
          ]
        }
      } else if (node.content && node.content.length > 0) {
        for (let childNode of node.content) {
          resolveNode(childNode, metaData)
        }
      }
    }
    function resolveMetaFromJson(metaData: any, metaKey: string) {
      let metaKeys = metaKey.split(".")
      let metaValue = metaData
      for (let metaKeyItem of metaKeys) {
        if (!metaValue[metaKeyItem]) return null
        metaValue = metaValue[metaKeyItem]
      }
      return metaValue
    }
    function calculateRelativeTableCellWidths(node: any) {
      if (node.type == "tableRow") {
        let totalWidth = 0
        for (let childNode of node.content) {
          if (childNode.attrs.colwidth) {
            totalWidth += childNode.attrs.colwidth[0]
          }
        }
        for (let childNode of node.content) {
          if (childNode.attrs.colwidth) {
            childNode.attrs.widthPercentage = (childNode.attrs.colwidth[0] / totalWidth) * 100.0
          }
        }
      } else if (node.content && node.content.length > 0) {
        for (let childNode of node.content) {
          calculateRelativeTableCellWidths(childNode)
        }
      }
    }

    function onContextMenu(event: any) {
      rightClickMenu.value.toggle(event)
    }
    return {
      insertMenu,
      rightClickMenu,
      colorPicker,
      devMenu,
      onContextMenu,
      showDevOptions,
      fontOptions,
      selectedFont,
      iframeLoaded,
    }
  },
})
