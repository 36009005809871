
import { ref, reactive } from "vue"
import FilterOverlay from "@/components/data/FilterOverlay.vue"
import InputNumber from "primevue/inputnumber"

export default {
  components: { FilterOverlay, InputNumber },
  props: {
    activeFilters: {
      type: [],
    },
  },
  emits: ["reload"],
  setup(props: any, { emit }: any) {
    const timeout = ref(null)
    const systemModeOptions = reactive(["AQS", "CLA", "PC2", "SERVER"])

    const serialFilter = ref("")
    const aliasFilter = ref("")
    const systemModeFilter = ref(null)
    const panelFilter = ref("")
    const generatorFilter = ref("")
    const simCardFilter = ref("")
    const betaFilter = ref(null)
    const betaTagOptions = reactive([0, 1, 2, 3, 4, 5])

    function resetSingleFilter(fieldString: string) {
      const newActiveFilters = props.activeFilters.filter((item: any) => item.field !== fieldString)
      props.activeFilters.length = 0
      props.activeFilters.push.apply(props.activeFilters, newActiveFilters)
      emit("reload")
    }

    function resetFilters() {
      serialFilter.value = ""
      aliasFilter.value = ""
      systemModeFilter.value = null
      panelFilter.value = ""
      generatorFilter.value = ""
      simCardFilter.value = ""
      betaFilter.value = null
      props.activeFilters.length = 0
      emit("reload")
    }

    function search() {
      clearTimeout(timeout.value)
      if (
        serialFilter.value === "" &&
        aliasFilter.value === "" &&
        systemModeFilter.value === null &&
        panelFilter.value === "" &&
        generatorFilter.value === "" &&
        simCardFilter.value === "" &&
        betaFilter.value === null
      ) {
        resetFilters()
        return
      }

      timeout.value = setTimeout(() => {
        if (serialFilter.value !== null && serialFilter.value !== "") {
          let serialField = props.activeFilters.find((filter: any) => filter.field === "serial")
          if (serialField) {
            serialField.compareValue = serialFilter.value
          } else {
            props.activeFilters.push({
              field: "serial",
              type: "subString",
              compareValue: serialFilter.value,
              path: (row: any) => row["serial"],
              operator: "=",
              hasTag: false,
            })
          }
        }

        if (aliasFilter.value !== null && aliasFilter.value !== "") {
          let aliasField = props.activeFilters.find((filter: any) => filter.field === "alias")
          if (aliasField) {
            aliasField.compareValue = aliasFilter.value
          } else {
            props.activeFilters.push({
              field: "alias",
              type: "subString",
              compareValue: aliasFilter.value,
              path: (row: any) => row["alias"],
              operator: "=",
              hasTag: false,
            })
          }
        }

        if (systemModeFilter.value !== null) {
          let systemModeField = props.activeFilters.find(
            (filter: any) => filter.field === "systemMode"
          )
          if (systemModeField) {
            systemModeField.compareValue = systemModeFilter.value
          } else {
            props.activeFilters.push({
              field: "systemMode",
              type: "equals",
              compareValue: systemModeFilter.value,
              path: (row: any) => row["systemMode"],
              operator: "=",
              hasTag: false,
            })
          }
        }

        if (panelFilter.value !== null && panelFilter.value !== "") {
          let panelField = props.activeFilters.find((filter: any) => filter.field === "panelType")
          if (panelField) {
            panelField.compareValue = panelFilter.value
          } else {
            props.activeFilters.push({
              field: "panelType",
              type: "subString",
              compareValue: panelFilter.value,
              path: (row: any) => row["panelType"],
              operator: "=",
              hasTag: false,
            })
          }
        }

        if (generatorFilter.value !== null && generatorFilter.value !== "") {
          let generatorField = props.activeFilters.find(
            (filter: any) => filter.field == "generatorType"
          )
          if (generatorField) {
            generatorField.compareValue = generatorFilter.value
          } else {
            props.activeFilters.push({
              field: "generatorType",
              type: "subString",
              compareValue: generatorFilter.value,
              path: (row: any) => row["generatorType"],
              operator: "=",
              hasTag: false,
            })
          }
        }

        if (simCardFilter.value !== null && simCardFilter.value !== "") {
          let simCardField = props.activeFilters.find(
            (filter: any) => filter.field == "simCardNumber"
          )
          if (simCardField) {
            simCardField.compareValue = simCardFilter.value
          } else {
            props.activeFilters.push({
              field: "simCardNumber",
              type: "subString",
              compareValue: simCardFilter.value,
              path: (row: any) => row["simCardNumber"],
              operator: "=",
              hasTag: false,
            })
          }
        }

        if (betaFilter.value !== null && betaFilter.value !== "") {
          let betaField = props.activeFilters.find((filter: any) => filter.field === "betaTag")
          if (betaField) {
            betaField.compareValue = parseInt(betaFilter.value)
          } else {
            props.activeFilters.push({
              field: "betaTag",
              type: "equals",
              compareValue: parseInt(betaFilter.value),
              path: (row: any) => row["betaTag"],
              operator: "=",
              hasTag: false,
            })
          }
        }

        emit("reload")
      }, 500)
    }

    return {
      resetFilters,
      search,
      systemModeOptions,
      serialFilter,
      aliasFilter,
      systemModeFilter,
      panelFilter,
      generatorFilter,
      simCardFilter,
      betaFilter,
      resetSingleFilter,
      betaTagOptions,
    }
  },
}
