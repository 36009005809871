import axios from "axios"

export default class AuthService {
  logIn(email: String, password: String) {
    let payload = { email: email, password: password }
    return axios.post("auth/signin", payload).then((res) => {
      let result = res.data

      return result
    })
  }

  logOut() {
    return axios.post("auth/signout")
  }

  refreshToken(refreshToken: string) {
    return axios.post("auth/refresh", { refreshToken: refreshToken })
  }

  logInVsol(orgUUID: string) {
    return axios.post(
      "auth/organizations/" + orgUUID,
      {},
      { baseURL: process.env.VUE_APP_VSOL_API }
    )
  }

  generatePmsSupportKey(userId: string, organizationId: string, pmsName: string) {
    if (!userId || !organizationId || !pmsName) return

    let url =
      "auth/pms/support?userId=" +
      userId +
      "&organizationId=" +
      organizationId +
      "&pmsName=" +
      pmsName

    return axios.post(url, {}, { baseURL: process.env.VUE_APP_VSOL_API }).then((res) => res.data)
  }
}
