<template>
  <div
    v-if="role"
    class="flex align-items-center"
  >
    <mdicon
      :name="role.icon"
      size="18"
      class="mr-1"
    />
    <span>{{ role.label }}</span>
  </div>
  <p v-else>
    Geen idee wat die hier doet...
  </p>
</template>

<script>
import useArrayUtils from "@/utils/array"
import { computed } from "vue"
import useRoleUtils from "@/utils/roles"

export default {
  props: {
    roleValue: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const { getOptionByValue } = useArrayUtils()
    const { supportedRoles } = useRoleUtils()

    const role = computed(() => getOptionByValue(supportedRoles, props.roleValue))

    return { role }
  },
}
</script>

<style scoped></style>
