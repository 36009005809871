import { io } from "socket.io-client"
import { state } from "@/utils/state"
import { clearObject } from "@/utils/clearObject"

export default class realtime {
  socket: any

  constructor() {
    this.socket = io("https://node.vsolpacs.com:8080/")

    //TEAMVIEWER
    this.socket.emit("join", "teamviewer_devices")

    this.socket.on("teamviewer_devices", (msg: any) => {
      state.onlineTeamviewer.length = 0
      ;[].push.apply(state.onlineTeamviewer, msg.online_devices)
    })

    //ANYDESK
    this.socket.emit("join", "anydesk_devices")

    this.socket.on("anydesk_devices", (msg: any) => {
      state.onlineAnydesk.length = 0
      ;[].push.apply(state.onlineAnydesk, msg.online_devices)
    })

    //CACHED_NETWORKS
    this.socket.emit("join", "cached_networks")

    //RECEIVE CACHED_NETWORKS
    this.socket.on("cached_networks", (msg: any) => {
      //Object.assign(state.cachedNetworks, msg)
      Object.assign(state.cachedNetworks, msg)
      //;[].push.apply(state.onlineAnydesk, msg.online_devices)
    })

    //REQUEST CACHED_NETWORKS
    this.socket.emit("publish", {
      room: "cached_networks",
      data: {},
    })

    //JOIN PHONE_STATUS
    this.socket.emit("join", "phone_status")

    //RECEIVE PHONE_STATUS
    this.socket.on("phone_status", (msg: any) => {
      Object.assign(state.phoneStatus, msg)
    })

    //REQUEST CACHED_NETWORKS
    this.socket.emit("publish", {
      room: "phone_status",
      data: {},
    })

    //JOIN TASKS
    this.socket.emit("join", "tasks")

    //RECEIVE TASKS
    this.socket.on("tasks", (msg: any) => {
      for (const taskId in msg) {
        if (msg[taskId].putDate > state.lastUpdatedTimeTasks) {
          const foundTask = state.allTasks.find((task) => task.id === taskId)
          //msg[taskId].data.selected = false

          if (foundTask && !foundTask.selected) {
            const cachedSelected = foundTask.selected
            Object.assign(foundTask, msg[taskId].data)
            foundTask.selected = cachedSelected
          }
        }
      }
    })

    //JOIN QUOTATIONPRODUCTS
    this.socket.emit("join", "quotationproducts")

    //RECEIVE QUOTATIONPRODUCTS
    this.socket.on("quotationproducts", (msg: any) => {
      if (state.activeQuotation) {
        state.activeQuotation.categories.forEach((cat: any) => {
          cat.products.forEach((prod: any) => {
            if(msg[prod.id] && (msg[prod.id].selected === true || msg[prod.id].selected === false)){
              prod.selected = msg[prod.id].selected
            }
            if(msg[prod.id] && typeof msg[prod.id].selected === 'number'){
              prod.amount = msg[prod.id].amount
            }
          })
        })
      }
    })

    //REQUEST QUOTATIONS
    this.socket.emit("publish", {
      room: "quotationproducts",
      data: {},
    })


    //JOIN QUOTATIONCATEGORIES
    this.socket.emit("join", "quotationcategories")

    //RECEIVE QUOTATIONCATEGORIES
    this.socket.on("quotationcategories", (msg: any) => {
      if (state.activeQuotation) {
        state.activeQuotation.categories.forEach((cat: any) => {
          if (msg[cat.id] && (msg[cat.id].collapsed === true || msg[cat.id].collapsed === false)) {
            cat.collapsed = msg[cat.id].collapsed
          }
        })
      }
    })

    //REQUEST QUOTATIONCATEGORIES
    this.socket.emit("publish", {
      room: "quotationcategories",
      data: {},
    })
  }
}
