import axios from "axios"
import ResultSet from "@/models/ResultSet"
import RenderInstructionService from "@/services/RenderInstructionService"

export default class ProcessingService extends RenderInstructionService {
  //TODO: refactor, not really correct
  pageSize: number = 20

  public constructor() {
    super("processings")
  }

  public getProcessingFiles() {
    let url = this.endpoint + "/files"

    return axios.get(url, { baseURL: process.env.VUE_APP_VSOL_API }).then((result) => result.data)
  }

  public getAll() {
    let url = this.endpoint + "/support/list"

    return axios.get(url, { baseURL: process.env.VUE_APP_VSOL_API }).then((result) => result.data)
  }
}
