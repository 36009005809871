import { computed, ref } from "vue"

export default function useAppUtils() {
  const windowHeight = ref(window.innerHeight)
  const windowWidth = ref(window.innerWidth)

  const isMobile = computed(() => {
    return windowWidth.value < 600
  })

  window.addEventListener("resize", () => {
    windowHeight.value = window.innerHeight
    windowWidth.value = window.innerWidth
  })

  return {
    isMobile,
  }
}
