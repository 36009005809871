
import { ref, reactive, watch } from "vue"
import ComputerAutoComplete from "@/components/computers/tools/ComputerAutoComplete.vue"
import UserAutoComplete from "@/components/users/UserAutoComplete.vue"
import OrganizationAutoComplete from "@/components/organizations/OrganizationAutoComplete.vue"
import InputSwitch from "primevue/inputswitch"
import InputNumber from "primevue/inputnumber"
import SettingService from "@/services/SettingService"
import { useToast } from "primevue/usetoast"
import ComputerService from "@/services/ComputerService"
import UserService from "@/services/UserService"
import OrganizationService from "@/services/OrganizationService"
import useArrayUtils from "@/utils/array"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"

export default {
  name: "SettingsCollectionForm",
  components: {
    ComputerAutoComplete,
    UserAutoComplete,
    OrganizationAutoComplete,
    InputNumber,
    InputSwitch,
    PrimaryButton,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    setting: {
      type: Object,
      default: null,
    },
    subCategorySettingAdd: {
      type: Object,
      default: null,
    },
    computer: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    organization: {
      type: Object,
      default: null,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["added", "edited"],
  setup(props: any, { emit }: any) {
    let settingOptions = [
      { name: "aqs.deactivate-on-retake", type: "boolean" },
      { name: "aqs.enabled", type: "boolean" },
      { name: "aqs.end-of-study-export", type: "text" },
      { name: "aqs.export-to-dicom", type: "boolean" },
      { name: "aqs.export-to-jpg", type: "boolean" },
      { name: "aqs.fuji-image-count", type: "number" },
      { name: "aqs.fuji-image-days", type: "number" },
      { name: "aqs.generator-mappings-file", type: "text" },
      { name: "aqs.generator-timeout", type: "number" },
      { name: "desktop.auto-shutdown-time", type: "text" },
      { name: "desktop.minimize-button-text", type: "text" },
      { name: "desktop.on-screen-keyboard", type: "boolean" },
      { name: "desktop.scaling-factor", type: "number" },
      { name: "desktop.shutdown-pc-on-exit", type: "boolean" },
      { name: "dicom.shared-path", type: "text" },
      { name: "dicom.store", type: "boolean" },
      { name: "dicom.store.synced", type: "boolean" },
      { name: "dicom.tag.institution-name", type: "text" },
      { name: "dicom.tag.responsible-person", type: "boolean" },
      { name: "mail.bcc", type: "email" },
      { name: "mail.reply-to", type: "email" },
      { name: "pms.connection", type: "boolean" },
      { name: "pms.name", type: "text" },
      { name: "report.enabled", type: "boolean" },
      { name: "sync.dicom.emabled", type: "boolean" },
      { name: "sync.dicom.download.days", type: "number" },
      { name: "sync.enabled", type: "boolean" },
      { name: "sync.last-opened-time", type: "boolean" },
      { name: "sync.min-free-space", type: "number" },
      { name: "sync.raw.download", type: "boolean" },
      { name: "sync.raw.download.days", type: "number" },
      { name: "sync.raw.upload", type: "boolean" },
      { name: "ui.horses-only", type: "boolean" },
      { name: "ui.language", type: "language", possibleValues: ["EN", "NL", "FR", "DE"] },
      { name: "viewer.left-handed", type: "boolean" },
      { name: "viewer.unit", type: "measuring unit", possibleValues: ["METRIC", "IMPERIAL"] },
    ]
    let selectedSetting = ref()
    let settingDefault = ref({})
    const makeDefaultSettingDialog = ref(false)
    const toast = useToast()
    const settingService = new SettingService()

    const form = reactive({
      user: props.user,
      organization: props.organization,
      computer: props.computer,
      settingValue: props.setting
        ? convertToCorrectType(props.setting.value)
        : convertToCorrectType(null),
    })

    const { intersectionById } = useArrayUtils()
    const computerService = new ComputerService()
    const userService = new UserService()
    const organizationService = OrganizationService.getInstance()

    let updatedUsers = reactive([])
    let updatedComputers = reactive([])
    let updatedOrganizations = reactive([])

    updateUserOptions()
    updateComputerOptions()
    updateOrganizationOptions()

    watch(
      () => form.user,
      () => {
        updateUserOptions()
        updateComputerOptions()
        updateOrganizationOptions()
      }
    )

    watch(
      () => form.computer,
      () => {
        updateUserOptions()
        updateComputerOptions()
        updateOrganizationOptions()
      }
    )

    watch(
      () => form.organization,
      () => {
        updateUserOptions()
        updateComputerOptions()
        updateOrganizationOptions()
      }
    )

    function convertToCorrectType(value: any) {
      if (props.type === "boolean") {
        return value === "true" ? true : false
      }
      return value
    }

    watch(
      () => selectedSetting.value,
      (newValue, oldValue) => {
        if (newValue.type === "boolean" && (!props.setting || !props.subCategorySettingAdd)) {
          form.settingValue = false
        }
      }
    )

    populateForm()
    function populateForm() {
      if (props.setting) {
        selectedSetting.value = props.setting
        if (selectedSetting.value.name === "ui.language") {
          selectedSetting.value.possibleValues = ["EN", "NL", "FR", "DE"]
        } else if (selectedSetting.value.name === "viewer.unit") {
          selectedSetting.value.possibleValues = ["METRIC", "IMPERIAL"]
        }
      } else if (props.subCategorySettingAdd) {
        selectedSetting.value = props.subCategorySettingAdd
        if (selectedSetting.value.name === "ui.language") {
          selectedSetting.value.possibleValues = ["EN", "NL", "FR", "DE"]
        } else if (selectedSetting.value.name === "viewer.unit") {
          selectedSetting.value.possibleValues = ["METRIC", "IMPERIAL"]
        }
      }
    }

    function onSubmit() {
      if (props.isEditing === true) {
        editSetting()
      } else {
        if (!form.computer && !form.user && !form.organization) {
          settingDefault.value = selectedSetting.value.name
          makeDefaultSettingDialog.value = true
        } else {
          addSetting()
        }
      }
    }

    function addSetting() {
      makeDefaultSettingDialog.value = false
      settingDefault.value = null
      if (form.settingValue !== null) {
        settingService
          .add(
            selectedSetting.value.name,
            form ? form.settingValue : null,
            form.organization ? form.organization.id : null,
            form.user ? form.user.id : null,
            form.computer ? form.computer.id : null
          )
          .then(() => {
            emit("added")
            form.user = null
            form.computer = null
            form.organization = null
            form.settingValue = null
            toast.add({
              severity: "success",
              summary: "Success",
              detail: "Added setting",
              life: 3000,
            })
          })
      } else {
        toast.add({
          severity: "error",
          summary: "Error",
          detail: "No value found",
          life: 1000,
        })
      }
    }

    function editSetting() {
      let idArray = []
      idArray.push(props.setting.id)
      settingService
        .editMultiple(idArray, selectedSetting.value.name, form.settingValue)
        .then(() => {
          emit("edited")
          toast.add({
            severity: "success",
            summary: "Success",
            detail: "Setting value changed",
            life: 3000,
          })
          idArray = []
        })
    }

    function updateUserOptions() {
      updatedUsers.length = 0
      if (form.user != null) {
        ;[].push(updatedUsers, [form.user])
      } else if (form.organization == null && form.computer == null) {
        ;[].push(updatedUsers, null)
      } else if (form.organization != null && form.computer != null) {
        let computerUsers: any[] = null
        let organizationUsers: any[] = null

        Promise.all([
          computerService.getUsersForComputer(form.computer.id).then((response) => {
            computerUsers = response.rows
          }),
          organizationService.getUsersForOrganization(form.organization.id).then((response) => {
            organizationUsers = response.rows.map((role) => role.user)
          }),
        ]).then(() => {
          ;[].push.apply(updatedUsers, intersectionById(computerUsers, organizationUsers))
        })
      } else if (form.computer != null) {
        computerService.getUsersForComputer(form.computer.id).then((response) => {
          ;[].push.apply(updatedUsers, response.rows)
        })
      } else if (form.organization != null) {
        organizationService.getUsersForOrganization(form.organization.id).then((response) => {
          ;[].push.apply(
            updatedUsers,
            response.rows.map((role) => role.user)
          )
        })
      }
    }

    function updateComputerOptions() {
      updatedComputers.length = 0
      if (form.computer != null) {
        ;[].push(updatedComputers, [form.computer])
      } else if (form.organization == null && form.user == null) {
        ;[].push(updatedComputers, null)
      } else if (form.organization != null && form.user != null) {
        let userComputers: any[]
        let organizationComputers: any[]

        Promise.all([
          userService.getComputersForUser(form.user.id).then((response) => {
            userComputers = response.rows
          }),
          organizationService.getComputersForOrganization(form.organization.id).then((reponse) => {
            organizationComputers = reponse.rows.map((role) => role.computer)
          }),
        ]).then(() => {
          ;[].push.apply(updatedComputers, intersectionById(userComputers, organizationComputers))
        })
      } else if (form.user != null) {
        userService.getComputersForUser(form.user.id).then((response) => {
          ;[].push.apply(updatedComputers, response.rows)
        })
      } else if (form.organization != null) {
        organizationService.getComputersForOrganization(form.organization.id).then((response) => {
          ;[].push.apply(
            updatedComputers,
            response.rows.map((role) => role.computer)
          )
        })
      }
    }

    function updateOrganizationOptions() {
      updatedOrganizations.length = 0
      if (form.organization != null) {
        ;[].push(updatedOrganizations, [form.organization])
      } else if (form.computer == null && form.user == null) {
        ;[].push(updatedOrganizations, null)
      } else if (form.computer != null && form.user != null) {
        let userOrganizations: any[]
        let computerOrganizations: any[]

        Promise.all([
          computerService.getOrganizationsForComputer(form.computer.id).then((response) => {
            computerOrganizations = response.rows.map((network) => network.organization)
          }),
          userService.getOrganizationsForUser(form.user.id).then((response) => {
            userOrganizations = response.rows.map((role) => role.organization)
          }),
        ]).then(() => {
          ;[].push.apply(
            updatedOrganizations,
            intersectionById(computerOrganizations, userOrganizations)
          )
        })
      } else if (form.user != null) {
        userService.getOrganizationsForUser(form.user.id).then((response) => {
          ;[].push.apply(
            updatedOrganizations,
            response.rows.map((role) => role.organization)
          )
        })
      } else if (form.computer != null) {
        computerService.getOrganizationsForComputer(form.computer.id).then((response) => {
          ;[].push.apply(
            updatedOrganizations,
            response.rows.map((network) => network.organization)
          )
        })
      }
    }

    return {
      settingOptions,
      selectedSetting,
      form,
      makeDefaultSettingDialog,
      onSubmit,
      settingDefault,
      addSetting,
      updatedComputers,
      updatedOrganizations,
      updatedUsers,
    }
  },
}
