<template>
  <div class="relative">
    <AutoComplete
      v-model="selectedProcessing"
      class="w-full"
      :class="{ 'p-invalid': invalid }"
      :suggestions="filteredProcessingOptions"
      :dropdown="true"
      :placeholder="placeholder"
      :field="(item) => item.alias"
      force-selection
      @complete="searchProcessing($event)"
      @item-select="$emit('update:modelValue', selectedProcessing)"
      @item-unselect="$emit('update:modelValue', selectedProcessing)"
    >
      <template #item="slotProps">
        <div class="p-2">
          {{ slotProps.item.alias }}
        </div>
      </template>
    </AutoComplete>
    <Button
      v-if="selectedProcessing"
      icon="pi pi-times"
      class="p-button-rounded p-button-secondary p-button-text clear"
      @click="$emit('update:modelValue', null)"
    />
  </div>
</template>

<script>
import ProcessingService from "@/services/ProcessingService"
import { ref, watch } from "vue"

export default {
  props: {
    modelValue: Object,
    excludes: {
      type: Object,
      default: [],
    },
    placeholder: {
      type: String,
      default: "Select a processing file",
    },
    invalid: Boolean,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const selectedProcessing = ref()
    const processingService = new ProcessingService("processings")
    const allProcessingOptions = ref([])
    const filteredProcessingOptions = ref([])

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        selectedProcessing.value = newValue
      }
    )

    loadProcessingOptions()

    function loadProcessingOptions() {
      processingService.getAll().then((response) => {
        allProcessingOptions.value = response
        filteredProcessingOptions.value = response
        selectedProcessing.value = props.modelValue

        filterExcludes()
      })
    }

    const searchProcessing = (event) => {
      if (!event.query.trim().length) {
        filteredProcessingOptions.value = [...allProcessingOptions.value]
      } else {
        filteredProcessingOptions.value = allProcessingOptions.value.filter((processingOption) => {
          return processingOption.alias.toLowerCase().indexOf(event.query.toLowerCase()) >= 0
        })
      }

      filterExcludes()
    }

    function filterExcludes() {
      filteredProcessingOptions.value = filteredProcessingOptions.value.filter(
        (processingOption) => {
          return !props.excludes.includes(processingOption)
        }
      )
    }

    return {
      selectedProcessing: selectedProcessing,
      filteredProcessingOptions: filteredProcessingOptions,
      searchProcessing: searchProcessing,
    }
  },
}
</script>
<style scoped>
.clear {
  position: absolute;
  top: 5px;
  right: 40px;
}
</style>
