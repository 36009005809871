<!--suppress XmlInvalidId -->
<template>
  <div
    v-if="showAlias"
    class="field col-12 md:col-3 xl:col-3"
  >
    <label for="alias">Alias</label>
    <InputText
      id="alias"
      v-model="v$.alias.$model"
      type="text"
      class="w-full"
    />
  </div>
  <div class="field col-12 md:col-3 xl:col-3">
    <label for="organization">Parameter file</label>
    <ProcessingFileAutoComplete
      v-model="v$.parameterFile.$model"
      :invalid="v$.parameterFile.$error"
      placeholder="Choose a param file"
      class="w-full"
    />
    <inline-form-error
      v-if="v$.parameterFile.$error"
      :errors="v$.parameterFile.$errors"
    />
  </div>
  <div class="field col-12 md:col-3 xl:col-3">
    <label for="setting">Setting</label>
    <InputText
      id="setting"
      v-model="v$.setting.$model"
      type="number"
      class="w-full"
    />
  </div>
  <div class="field col-12 md:col-3 xl:col-3">
    <label for="brightness">Brightness</label>
    <InputText
      id="brightness"
      v-model="v$.brightness.$model"
      type="number"
      class="w-full"
    />
  </div>
  <div class="field col-12 md:col-3 xl:col-3">
    <label for="contrast">Contrast</label>
    <InputText
      id="contrast"
      v-model="v$.contrast.$model"
      type="number"
      class="w-full"
    />
  </div>
  <div class="field col-12 md:col-3 xl:col-3">
    <label for="latitude">Latitude</label>
    <InputText
      id="latitude"
      v-model="v$.latitude.$model"
      type="number"
      class="w-full"
    />
  </div>
  <div class="field col-12 md:col-3 xl:col-3">
    <label for="edge">Edge</label>
    <InputText
      id="edge"
      v-model="v$.edge.$model"
      type="number"
      class="w-full"
    />
  </div>
  <div class="field col-12 md:col-3 xl:col-3">
    <label for="noise">Noise</label>
    <InputText
      id="noise"
      v-model="v$.noise.$model"
      type="number"
      class="w-full"
    />
  </div>
</template>

<script>
import { ref } from "vue"
import useVuelidate from "@vuelidate/core"
import { helpers, required } from "@vuelidate/validators"
import SizeDropdown from "@/components/settings/render-instructions/StatureDropdown"

export default {
  components: { SizeDropdown },
  props: {
    additionalFields: {
      alias: String,
      parameterFile: String,
      setting: Number,
      brightness: Number,
      contrast: Number,
      latitude: Number,
      edge: Number,
      noise: Number,
    },
    showAlias: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const additionalRules = {
      alias: {},
      parameterFile: {
        fileRequired: helpers.withMessage("The parameter file is required", required),
      },
      setting: {},
      brightness: {},
      contrast: {},
      latitude: {},
      edge: {},
      noise: {},
    }

    const v$ = useVuelidate(additionalRules, props.additionalFields)

    return {
      v$,
    }
  },
}
</script>
