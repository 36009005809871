import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-088496d1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-col" }
const _hoisted_2 = { class: "table-col" }
const _hoisted_3 = { class: "table-col" }
const _hoisted_4 = { class: "table-col email" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkLabel = _resolveComponent("LinkLabel")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_LinkLabel, {
        id: _ctx.user.id,
        value: _ctx.user.lastName,
        type: "user"
      }, null, 8, ["id", "value"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: { name: 'userProfile', params: { user_id: _ctx.user.id } }
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.user.firstName), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_router_link, {
        to: { name: 'userProfile', params: { user_id: _ctx.user.id } }
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.user.username), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_link, {
        to: { name: 'userProfile', params: { user_id: _ctx.user.id } }
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.user.email), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ], 64))
}