import { Task } from "@/models/Task"
import { reactive } from "vue"
import { TaskAndChildren } from "@/components/tasks/types/TaskAndChildren"
import { SelectedTask } from "@/models/SelectedTask"

export const state = reactive({
  mode: "WEEK",
  allOrganizations: new Map(),
  realtime: null,
  organization: {},
  onlineTeamviewer: [],
  onlineAnydesk: [],
  cachedNetworks: {},
  phoneStatus: {},
  weekviewDragData: <TaskAndChildren>null,
  weekviewSelectedTask: SelectedTask.from(Task.fromDefault(), false), // used to move Tasks by clicking on day columns in WeekView
  weekviewSelectedChildTasks: [] as SelectedTask[], // used to move children of merged-items by clicking on day columns in WeekView
  allTasks: [],
  lastUpdatedTimeTasks: new Date().getTime(),
  activeQuotation: null,
  isVsolEmployee: null,
})

const defaultState: any = {
  mode: "WEEK",
  allOrganizations: new Map(),
  realtime: null,
  organization: {},
  onlineTeamviewer: [],
  onlineAnydesk: [],
  cachedNetworks: {},
  phoneStatus: {},
  weekviewDragData: "",
  weekviewSelectedTask: Task.fromDefault(),
  weekviewSelectedChildTasks: [],
  allTasks: [],
  lastUpdatedTimeTasks: new Date().getTime(),
  activeQuotation: null,
  isVsolEmployee: null,
}

export function clearState() {
  Object.assign(state, defaultState)
}
