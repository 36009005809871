
import { computed, reactive, ref, defineComponent } from "vue"
import OrganizationService from "@/services/OrganizationService"
import { useRouter, useRoute } from "vue-router"
import AuthService from "@/services/AuthService"
import { useStore } from "vuex"
import { TabMenuChangeEvent } from "primevue/tabmenu"
import { state, clearState } from "@/utils/state"
import { mdiConsoleLine } from "@mdi/js"
import SecondaryButton from "../buttons/SecondaryButton.vue"
import TabsComponent from "../navigation/TabsComponent.vue"
import ResultSet from "@/models/ResultSet"

export default defineComponent({
  props: {},
  setup(props) {
    const organizationService = OrganizationService.getInstance()
    const organizations = reactive([])
    const foundOrganizations = reactive([])
    const showDropdown = ref(false)
    const router = useRouter()
    const hwDropdownVisible = ref(false)
    const settingsDropdownVisible = ref(false)
    const dropDownActive = ref(false)
    const service = new AuthService()
    const store = useStore()
    const focusedOrg = computed(() => foundOrganizations.filter((org: any) => org.focus)[0])
    const searchValue = ref()
    let route = useRoute()

    const tabItems = reactive([
      { label: "Organizations", routeName: "org", attributes: {} },
      { label: "Releases", routeName: "releases", attributes: {} },
      { label: "Users", routeName: "users", attributes: { permissions: '["VSOL"]' } },
      { label: "Computers", routeName: "cmp", attributes: { permissions: '["VSOL"]' } },
      { label: "Team", routeName: "team", attributes: {} },
      { label: "Logs", routeName: "logs", attributes: { permissions: '["VSOL"]' } },
      { label: "Communication", routeName: "callHistory", attributes: { permissions: '["VSOL"]' } },
      { label: "Customizations", routeName: "customizations", attributes: {} },
      { label: "Hardware", routeName: "hardwareSettings", attributes: { permissions: '["VSOL"]' } },
      { label: "Settings", routeName: "settings", attributes: { permissions: '["VSOL"]' } },
      { label: "Sales", routeName: "sales", attributes: {} },
      { label: "Health", routeName: "health", attributes: { permissions: '["VSOL"]' } },
    ])

    organizationService.getOrganizations(0, "", "").then((result: ResultSet<any>) => {
      state.allOrganizations.clear()
      result.rows.forEach((row) => {
        state.allOrganizations.set(row.id, row)
        row.focus = false
        organizations.push(row)
      })
    })

    function searchOrgs() {
      foundOrganizations.length = 0
      let searchValueToLower = searchValue.value.toLowerCase()
      Object.assign(
        foundOrganizations,
        organizations
          .filter(
            (org: any) =>
              org.code.toLowerCase().includes(searchValue.value.toLowerCase()) ||
              org.name.toLowerCase().includes(searchValue.value.toLowerCase()) ||
              org.internalName?.toLowerCase().includes(searchValue.value.toLowerCase())
          )
          .map((org: any) => {
            org.focus = false
            return org
          })
          .sort((a: any, b: any) => {
            if (a.code.toLowerCase() === searchValueToLower) {
              return -1
            }
            if (b.code.toLowerCase() === searchValueToLower) {
              return 1
            }
            if (a.code.toLowerCase().startsWith(searchValueToLower)) {
              return -1
            }
            if (b.code.toLowerCase().startsWith(searchValueToLower)) {
              return 1
            }
            if (a.code.toLowerCase().includes(searchValueToLower)) {
              return -1
            } else {
              return 1
            }
          })
      )
      if (foundOrganizations.length > 0) {
        foundOrganizations[0].focus = true
      }
    }

    function search() {
      showDropdown.value = !!searchValue.value.length
      searchOrgs()
    }

    function goToOrg(id: string) {
      searchValue.value = ""
      showDropdown.value = false
      searchValue.value = null
      router.push({ name: "organization", params: { organization_id: id } })
    }
    function inputFocus() {
      showDropdown.value = true
    }
    function focusOut() {
      showDropdown.value = false
    }
    function navigate(navData: any) {
      router.push({ name: navData.route })
    }
    function showHwDropdown() {
      hwDropdownVisible.value = true
      dropDownActive.value = true
    }
    function showSettingsDropdown() {
      settingsDropdownVisible.value = true
      dropDownActive.value = true
    }
    function hideDropdowns() {
      hwDropdownVisible.value = false
      settingsDropdownVisible.value = false
      dropDownActive.value = false
    }
    function routeTo(route: string) {
      hwDropdownVisible.value = false
      dropDownActive.value = false
      router.push({ name: route })
      //location.reload()
    }
    function logout() {
      service.logOut().then(
        () => {
          clearState()
          store.dispatch("logOut")
          router.push({ name: "login" })
        },
        () => {
          clearState()
          store.dispatch("logOut")
          router.push({ name: "login" })
        }
      )
    }
    function onKeydown(event: any) {
      if (event.key === "Enter") {
        event.preventDefault()
        if (focusedOrg.value) {
          goToOrg(focusedOrg.value.id)
          searchValue.value = ""
          clearFocus()
        }
      }
      if (event.key === "ArrowDown") {
        event.preventDefault()
        focusOnNext()
      }
      if (event.key === "ArrowUp") {
        event.preventDefault()
        focusOnPrevious()
      }
    }
    function focusOnNext() {
      const nowFocusedIndex = foundOrganizations.findIndex((org: any) => org.focus)
      clearFocus()
      if (nowFocusedIndex + 1 === foundOrganizations.length) {
        foundOrganizations[0].focus = true
      } else {
        foundOrganizations[nowFocusedIndex + 1].focus = true
      }
    }
    function focusOnPrevious() {
      const nowFocusedIndex = foundOrganizations.findIndex((org: any) => org.focus)
      clearFocus()
      if (nowFocusedIndex === 0) {
        foundOrganizations[foundOrganizations.length - 1].focus = true
      } else {
        foundOrganizations[nowFocusedIndex - 1].focus = true
      }
    }
    function clearFocus() {
      foundOrganizations.forEach((org: any) => {
        org.focus = false
      })
    }
    return {
      foundOrganizations,
      inputFocus,
      showDropdown,
      goToOrg,
      search,
      navigate,
      hwDropdownVisible,
      showHwDropdown,
      hideDropdowns,
      dropDownActive,
      routeTo,
      route,
      settingsDropdownVisible,
      showSettingsDropdown,
      logout,
      onKeydown,
      searchValue,
      tabItems,
      state,
    }
  },
  components: { SecondaryButton, TabsComponent },
})
