import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["data-collection-row", $props.modelValue ? 'selected' : ''])
  }, [
    ($props.selectable)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["table-col row-checkbox", $props.modelValue ? 'selected' : ''])
        }, [
          _createVNode(_component_Checkbox, {
            name: $props.id,
            id: $props.id,
            modelValue: $props.modelValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($props.modelValue) = $event)),
            binary: true,
            class: "ml-3",
            onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $props.modelValue)))
          }, null, 8, ["name", "id", "modelValue"])
        ], 2))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}