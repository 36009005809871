<!--suppress XmlInvalidId -->
<template>
  <form @submit.prevent="onSubmit">
    <div class="formgroup-inline mt-3 mb-4">
      <div
        v-if="!user"
        class="field"
      >
        <UserAutoComplete
          v-model="v$.user.$model"
          :invalid="v$.user.$error"
          placeholder="Add user"
        />
        <inline-form-error
          v-if="v$.user.$error"
          :errors="v$.user.$errors"
        />
      </div>
      <div
        v-if="!organization"
        class="field"
      >
        <OrganizationAutoComplete
          v-model="v$.organization.$model"
          :invalid="v$.organization.$error"
          placeholder="Add to organization"
        />
        <inline-form-error
          v-if="v$.organization.$error"
          :errors="v$.organization.$errors"
        />
      </div>
      <div class="field">
        <Dropdown
          v-model="v$.type.$model"
          :options="typeOptions"
          :invalid="v$.type.$error"
        />
        <inline-form-error
          v-if="v$.type.$error"
          :errors="v$.type.$errors"
        />
      </div>
      <div class="field">
        <Button
          type="submit"
          class="p-button-outlined"
          label="Add"
        />
      </div>
    </div>
  </form>
</template>

<script langs="ts">
import { ref } from "vue"
import { helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import OrganizationAutoComplete from "@/components/organizations/OrganizationAutoComplete"
import UserAutoComplete from "@/components/users/UserAutoComplete"
import RolesService from "@/services/RolesService"

export default {
  components: { UserAutoComplete, OrganizationAutoComplete },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
    saved: {},
  },
  emits: ["added"],
  setup(props, { emit }) {
    const done = ref(false)
    const typeOptions = ["USER", "ADMIN"]
    const roleService = new RolesService()

    const form = ref({
      user: props.user,
      organization: props.organization,
      type: "USER",
    })

    const rules = {
      type: { required: helpers.withMessage("The type is required", required) },
      user: { required: helpers.withMessage("The user is required", required) },
      organization: { required: helpers.withMessage("The organization is required", required) },
    }

    const v$ = useVuelidate(rules, form)

    function onSubmit() {
      v$.value.$touch()

      if (v$.value.$invalid) return

      roleService
        .addRole(form.value.organization.id, form.value.user.id, form.value.type)
        .then(() => {
          if (props.organization) form.value.user = null
          if (props.user) form.value.organization = null
          form.value.type = "USER"

          v$.value.$reset()

          emit("added", form.value)
        })
    }

    function onDone() {
      done.value = true
      setTimeout(() => {
        done.value = false
      }, 2500)
    }

    return {
      done,
      typeOptions,
      v$,
      onSubmit,
      onDone,
    }
  },
}
</script>

<style scoped></style>
