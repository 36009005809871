export default {
  state: () => ({
    drawer: {
      collapsed: false,
    },
    weekview: {
      compactMode: false,
    },
    /*history: {
      collapsed: false,
    },*/
  }),

  mutations: {
    updateDrawerCollapsed(state: any, collapsed: boolean) {
      state.drawer.collapsed = collapsed
    },

    updateWeekviewCompactMode(state: any, compactMode: boolean) {
      state.weekview.compactMode = compactMode
    },

    /*updateHistoryCollapsed(state: any, collapsed: boolean) {
      state.history.collapsed = collapsed
    },*/
  },

  /* Business logic (controllers) */
  actions: {
    setDrawerCollapsed(context: any, collapsed: boolean) {
      context.commit("updateDrawerCollapsed", collapsed)
    },

    setWeekviewCompactMode(context: any, compactMode: boolean) {
      context.commit("updateWeekviewCompactMode", compactMode)
    },

    /*setHistoryCollapsed(context: any, collapsed: boolean) {
      context.commit("updateHistoryCollapsed", collapsed)
    },*/
  },

  getters: {
    isDrawerCollapsed(state: any) {
      return state.drawer.collapsed
    },

    isWeekviewCompactMode(state: any) {
      return state.weekview.compactMode
    },

    /*isHistoryCollapsed(state: any) {
      return state.history.collapsed
    },*/
  },
}
