<!--suppress XmlInvalidId -->
<template>
  <div class="field col-12 md:col-6">
    <label for="Crop">Crop</label>
    <div>
      <InputSwitch
        v-model="v$.crop.$model"
        :class="{ 'p-invalid': v$.crop.$error }"
        class="mr-2"
        @change="$emit('update:modelValue', v$.crop.$model)"
      />
      {{ v$.crop.$model }}
    </div>
    <inline-form-error
      v-if="v$.crop.$error"
      :errors="v$.crop.$errors"
    />
  </div>
  <div class="field col-12 md:col-6">
    <label for="Mask">Mask</label>
    <div>
      <InputSwitch
        v-model="v$.mask.$model"
        :class="{ 'p-invalid': v$.mask.$error }"
        class="mr-2"
        @change="$emit('update:modelValue', v$.mask.$model)"
      />
      {{ v$.mask.$model }}
    </div>
    <inline-form-error
      v-if="v$.mask.$error"
      :errors="v$.mask.$errors"
    />
  </div>
</template>

<script>
import { ref } from "vue"
import useVuelidate from "@vuelidate/core"
import InputSwitch from "primevue/inputswitch"

export default {
  components: { InputSwitch },
  props: {
    additionalFields: { crop: Boolean, mask: Boolean },
  },
  setup(props) {
    const additionalRules = { crop: {}, mask: {} }

    const v$ = useVuelidate(additionalRules, props.additionalFields)

    return {
      v$,
    }
  },
}
</script>
