import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c1665c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  title: "Last synchronization time"
}
const _hoisted_2 = { class: "sync" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return ($props.computer.syncTime)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createVNode(_component_mdicon, {
          name: "weatherCloudyClock",
          size: 20,
          class: "mr-2"
        }),
        _createElementVNode("span", _hoisted_2, _toDisplayString($props.absoluteDateTime), 1)
      ]))
    : _createCommentVNode("", true)
}