import { Module } from "vuex"
import ResultSet from "@/models/ResultSet"
import TaskLabel from "@/components/tasks/TaskLabel.vue"

interface TaskLabelServiceState {
  taskLabels: Map<string, []>
}

const taskLabelServiceModule: Module<TaskLabelServiceState, any> = {
  state: () => ({
    taskLabels: new Map<string, []>(),
  }),
  mutations: {
    setTaskLabels(state: any, payload: { url: string; data: any }) {
      if (!(state.taskLabels instanceof Map)) {
        state.taskLabels = new Map()
      }
      state.taskLabels.set(payload.url, payload.data)
      console.log(`[CACHED #${payload.data.length}]`, payload.url)
    },
  },
  getters: {
    hasTaskLabelsData:
      (state: any) =>
      (url: string): boolean => {
        if (!(state.taskLabels instanceof Map)) {
          state.taskLabels = new Map()
        }
        console.log("[HAS]", url, state.taskLabels.has(url))
        return state.taskLabels.has(url)
      },
    getTaskLabelsData:
      (state: any) =>
      (url: string): [] => {
        if (!(state.taskLabels instanceof Map)) {
          state.taskLabels = new Map()
        }
        console.log("[CACHE HIT]", url)
        return state.taskLabels?.get(url)
      },
  },
}

export default taskLabelServiceModule
