
import { ref, reactive, defineComponent } from "vue"
import useVuelidate from "@vuelidate/core"
import { useRoute } from "vue-router"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"

export default defineComponent({
  props: {
    service: {
      type: Object,
    },
    organization: {
      type: Object,
      default: null,
    },
    selectedRule: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    duplicate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["create", "edit", "retrieved", "closedialogs"],
  setup(props, { emit }: any) {
    const route = useRoute()
    const form = reactive({
      user: null,
      organization: props.organization,
      computer: null,
      species: null,
      anatomy: null,
      direction: null,
      leg: null,
      stature: null,
    })
    const rules = {
      user: {},
      organization: {},
      computer: {},
      species: {},
      anatomy: {},
      direction: {},
      leg: {},
      stature: {},
    }
    const v$: any = useVuelidate(rules, form)
    function onSubmit() {
      v$.value.$touch()
      if (v$.value.$invalid) return
      if (props.edit) {
        emit("edit")
      } else if (props.add || props.duplicate) {
        emit("create")
      }
    }
    populateForm()
    function populateForm() {
      if (!props.add) {
        form.user = props.selectedRule.user ? props.selectedRule.user : null
        form.computer = props.selectedRule.computer
        form.organization = props.selectedRule.organization
        form.species = props.selectedRule.species
        form.anatomy = props.selectedRule.anatomy
        form.direction = props.selectedRule.direction
        form.leg = props.selectedRule.leg
        form.stature = props.selectedRule.stature
        emit("retrieved", props.selectedRule)
      }
    }
    /**
     * To be called by wrapper component (e.g. OrientationsNew)
     * @param payload
     */
    function addRule(payload: any) {
      const org = form.organization ? form.organization.id : null
      const user = form.user ? form.user.id : null
      const computer = form.computer ? form.computer.id : null
      if (props.add || props.duplicate) {
        props.service
          .add(
            computer,
            user,
            org,
            form.species,
            form.anatomy,
            form.direction,
            form.leg,
            form.stature,
            payload
          )
          .then(() => {
            emit("closedialogs")
          })
      }
    }
    function editRule(payload: any) {
      if (props.edit) {
        props.service
          .edit(
            props.selectedRule.id,
            form.species,
            form.anatomy,
            form.direction,
            form.leg,
            form.stature,
            payload
          )
          .then(() => {
            emit("closedialogs")
          })
      }
    }
    return {
      v$,
      onSubmit,
      addRule,
      editRule,
      meta: route.meta,
    }
  },
  components: { PrimaryButton },
})
