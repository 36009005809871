import { Module } from "vuex"
import ResultSet from "@/models/ResultSet"

interface EmployeeServiceState {
  employees: Map<string, ResultSet<any>>
}

const employeeServiceModule: Module<EmployeeServiceState, any> = {
  state: () => ({
    employees: new Map<string, ResultSet<any>>(),
  }),
  mutations: {
    setEmployees(state: any, payload: { url: string; data: any }) {
      if (!(state.employees instanceof Map)) {
        state.employees = new Map()
      }
      state.employees.set(payload.url, payload.data)
      console.log(`[CACHED #${payload.data.rows.length}]`, payload.url)
    },
    clearEmployees(state: any) {
      state.employees.clear()
      console.log("[CACHE] cleared")
    },
  },
  getters: {
    hasEmployeesData:
      (state: any) =>
      (url: string): boolean => {
        if (!(state.employees instanceof Map)) {
          state.employees = new Map()
        }
        console.log("[HAS]", url, state.employees.has(url))
        return state.employees.has(url)
      },
    getEmployeesData:
      (state: any) =>
      (url: string): ResultSet<any> => {
        if (!(state.employees instanceof Map)) {
          state.employees = new Map()
        }
        console.log("[CACHE HIT]", url)
        return state.employees?.get(url)
      },
  },
}

export default employeeServiceModule
