import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10cdbef2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex align-items-center" }
const _hoisted_2 = {
  key: 0,
  class: "company-code"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    class: _normalizeClass(["link-label", _ctx.config[_ctx.type].class, { disabled: !_ctx.clickAble }]),
    to: _ctx.config[_ctx.type].to,
    title: _ctx.hoverMessage,
    onClick: _ctx.onLinkClick
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.companyCode)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("p", null, _toDisplayString(_ctx.companyCode), 1)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["flex align-items-center", _ctx.config[_ctx.type].class + '-text'])
        }, [
          (_ctx.showIcon)
            ? (_openBlock(), _createBlock(_component_mdicon, {
                key: 0,
                name: _ctx.config[_ctx.type].icon,
                class: "mr-2"
              }, null, 8, ["name"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.value), 1)
        ], 2)
      ])
    ]),
    _: 1
  }, 8, ["class", "to", "title", "onClick"]))
}