<template>
  <Dropdown
    v-model="modelValue"
    :options="options"
    :show-clear="true"
    placeholder="Any anatomy"
    @change="$emit('update:modelValue', modelValue)"
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const options = [
      "ABDOMEN",
      "BACK",
      "C7_T1",
      "CARPUS",
      "DISTAL_LEG",
      "ELBOW",
      "FETLOCK",
      "FOOT",
      "GESTATION_CHECK",
      "HEAD",
      "HIP",
      "HOCK",
      "LUMBAR_SPINE",
      "MANDIBULA",
      "MAXILA",
      "NECK",
      "PELVIS",
      "PODO",
      "SHOULDER",
      "SPINES",
      "SPLINTBONE",
      "THORACAL_SPINE",
      "STIFLE",
      "THORAX",
      "TOOTH",
      "UPPER_LEG",
      "WITHERS",
    ]

    return {
      options,
    }
  },
}
</script>
