import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToolkitTag = _resolveComponent("ToolkitTag")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    class: _normalizeClass(["w-full", { 'p-invalid': $props.invalid }]),
    modelValue: $setup.selectedItem,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedItem) = $event)),
    options: $setup.allOptions,
    placeholder: $props.placeholder,
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $setup.selectedItem))),
    loading: $setup.loading,
    showClear: true
  }, {
    value: _withCtx((slotProps) => [
      (slotProps.value)
        ? (_openBlock(), _createBlock(_component_ToolkitTag, {
            key: 0,
            toolkit: slotProps.value
          }, null, 8, ["toolkit"]))
        : _createCommentVNode("", true)
    ]),
    option: _withCtx((slotProps) => [
      _createVNode(_component_ToolkitTag, {
        toolkit: slotProps.option
      }, null, 8, ["toolkit"])
    ]),
    _: 1
  }, 8, ["class", "modelValue", "options", "placeholder", "loading"]))
}