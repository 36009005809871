<template>
  <div class="relative">
    <AutoComplete
      v-model="selectedProcessingFile"
      class="w-full"
      :class="{ 'p-invalid': invalid }"
      :suggestions="filteredProcessingFileOptions"
      :dropdown="true"
      :placeholder="placeholder"
      :field="(item) => item"
      force-selection
      @complete="searchProcessingFile($event)"
      @item-select="$emit('update:modelValue', selectedProcessingFile)"
      @item-unselect="$emit('update:modelValue', selectedProcessingFile)"
    >
      <template #item="slotProps">
        <div class="p-2">
          {{ slotProps.item }}
        </div>
      </template>
    </AutoComplete>
    <Button
      v-if="selectedProcessingFile"
      icon="pi pi-times"
      class="p-button-rounded p-button-secondary p-button-text clear"
      @click="$emit('update:modelValue', null)"
    />
  </div>
</template>

<script>
import ProcessingService from "@/services/ProcessingService"
import { ref, watch } from "vue"

export default {
  props: {
    modelValue: String,
    excludes: {
      type: Object,
      default: [],
    },
    placeholder: {
      type: String,
      default: "Select a processing file",
    },
    invalid: Boolean,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const selectedProcessingFile = ref()
    const processingService = new ProcessingService("processings")
    const allProcessingFileOptions = ref([])
    const filteredProcessingFileOptions = ref([])

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        selectedProcessingFile.value = newValue
      }
    )

    loadProcessingFileOptions()

    function loadProcessingFileOptions() {
      processingService.getProcessingFiles().then((response) => {
        allProcessingFileOptions.value = response
        filteredProcessingFileOptions.value = response
        selectedProcessingFile.value = props.modelValue

        filterExcludes()
      })
    }

    const searchProcessingFile = (event) => {
      if (!event.query.trim().length) {
        filteredProcessingFileOptions.value = [...allProcessingFileOptions.value]
      } else {
        filteredProcessingFileOptions.value = allProcessingFileOptions.value.filter(
          (processingFileOption) => {
            return processingFileOption.toLowerCase().indexOf(event.query.toLowerCase()) >= 0
          }
        )
      }

      filterExcludes()
    }

    function filterExcludes() {
      filteredProcessingFileOptions.value = filteredProcessingFileOptions.value.filter(
        (processingFileOption) => {
          return !props.excludes.includes(processingFileOption)
        }
      )
    }

    return {
      selectedProcessingFile: selectedProcessingFile,
      filteredProcessingFileOptions: filteredProcessingFileOptions,
      searchProcessingFile: searchProcessingFile,
    }
  },
}
</script>
<style scoped>
.clear {
  position: absolute;
  top: 5px;
  right: 40px;
}
</style>
