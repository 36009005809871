
import ReportService from "@/services/ReportService"
import { computed, ref, watch } from "vue"
import FileUpload from "primevue/fileupload"
import ReportBackgroundItem from "@/components/reports/tools/ReportBackgroundItem.vue"
import { useRoute } from "vue-router"
import OrganizationService from "@/services/OrganizationService"
import { useConfirm } from "primevue/useconfirm"
import LinkLabel from "@/components/navigation/LinkLabel.vue"
import { state } from "@/utils/state"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"
import useTemplateUtils from "@/utils/template"

export default {
  name: "ReportBackgroundList",
  components: { ReportBackgroundItem, FileUpload, LinkLabel, PrimaryButton },
  props: {
    organization: {
      type: String,
      default: null,
    },
    language: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  emits: ["default-checked"],
  setup(props: any, { emit }: any) {
    const selectedUser = ref(null)
    const backgroundAlias = ref("")
    const internalAlias = ref("")
    const reportService = new ReportService()
    const templateList = ref([])
    const loading = ref(false)
    const isDefault = ref(false)
    const addDialog = ref(false)
    const allLanguages = ref(true)
    const { postReportTemplatePart } = useTemplateUtils()

    watch(
      () => props.language,
      () => {
        resetDropdown()
        loadTemplates()
      }
    )

    watch(
      () => selectedUser.value,
      () => {
        if (selectedUser.value == null) {
          loadTemplates()
        } else {
          loadTemplatesForUser()
        }
      }
    )

    if (props.language) {
      loadTemplates()
    }

    // function checkDefault() {
    //   confirm.require({
    //     message: "Do you want to enable default mode ?",
    //     header: "Default",
    //     accept: () => {
    //       isDefault.value = true
    //       if (selectedUser.value == null) {
    //         loadTemplates()
    //       } else {
    //         loadTemplatesForUser()
    //       }
    //     },
    //     reject: () => {
    //       isDefault.value = false
    //       if (selectedUser.value == null) {
    //         loadTemplates()
    //       } else {
    //         loadTemplatesForUser()
    //       }
    //     },
    //   })
    // }

    function loadTemplates() {
      loading.value = true
      if (props.organization) {
        reportService
          .getReportTemplatesByType(props.type, props.language, props.organization)
          .then((result) => {
            if (result.data.length > 0) {
              templateList.value = result.data.sort((a: any, b: any) =>
                new Date(a.createdTime) > new Date(b.createdTime) ? -1 : 1
              )
            } else {
              templateList.value = []
            }
            loading.value = false
          })
      }
    }

    function loadTemplatesForUser() {
      if (props.organization) {
        reportService
          .getReportTemplatesByTypeForOrganizationUser(
            props.type,
            props.language,
            props.organization as string,
            selectedUser.value.id
          )
          .then((result) => {
            if (result.data.length > 0) {
              templateList.value = result.data.sort((a: any, b: any) =>
                new Date(a.createdTime) > new Date(b.createdTime) ? -1 : 1
              )
            } else {
              templateList.value = []
            }
            loading.value = false
          })
      }
    }

    const route = useRoute()
    const organizationService = OrganizationService.getInstance()
    const organizationId = computed(() => route.params.organization_id as string)
    const organizationUsers = ref([])
    loadOrganizationUsers()

    function loadOrganizationUsers() {
      if (organizationId) {
        organizationService.getUsersForOrganization(organizationId.value).then((result) => {
          let rows = result.rows
          for (let row of rows) {
            organizationUsers.value.push(row.user)
          }
        })
      }
    }

    async function chooseTemplateBackground(event: any) {
      if (event) {
        await postReportTemplatePart(
          backgroundAlias.value,
          internalAlias.value,
          props.type,
          allLanguages.value ? "" : props.language,
          isDefault.value ? null : props.organization,
          isDefault.value ? null : selectedUser?.value?.id,
          event.files[0],
          selectedUser?.value?.id ? loadTemplatesForUser : loadTemplates,
          addDialog,
          backgroundAlias,
          internalAlias,
          resetDropdown
        )
      }
    }

    function resetDropdown() {
      selectedUser.value = null
    }

    return {
      chooseTemplateBackground,
      backgroundAlias,
      templateList,
      loading,
      loadTemplates,
      internalAlias,
      organizationUsers,
      selectedUser,
      resetDropdown,
      isDefault,
      addDialog,
      state,
      allLanguages,
    }
  },
}
