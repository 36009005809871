
import ResultSet from "@/models/ResultSet"
import RolesService from "@/services/RolesService"
import UserService from "@/services/UserService"
import Column from "primevue/column"
import DataTable from "primevue/datatable"
import { nextTick, onMounted, onUnmounted, ref, watch } from "vue"
import LinkLabel from "../navigation/LinkLabel.vue"
import UserForm from "@/components/users/UserForm.vue"
import PrimaryButton from "../buttons/PrimaryButton.vue"

export default {
  name: "UserToOrgRoleForm",
  components: { LinkLabel, UserForm, PrimaryButton },
  props: {
    organization: Object,
  },
  emits: ["reload-members"],
  setup(props: any, { emit }: any) {
    let part = 0
    const sort = ref("")
    const filter = ref("")
    const items = ref([])
    const loading = ref(false)
    const loadedAllData = ref(false)
    const selectedItems = ref()
    const userService = new UserService()
    const roleService = new RolesService()
    const scrollComponent = ref()
    const roleType = ref("USER")
    const roleTypes = ref(["USER", "ADMIN"])
    const showSelectionError = ref(false)
    const timeout = ref(null)

    loadPart(part, sort.value, filter.value)

    onMounted(() => {
      scrollComponent.value = document.querySelector(
        "div.add-user-datatable div.p-datatable-wrapper"
      )
      if (scrollComponent.value) {
        scrollComponent.value.addEventListener("scroll", checkVisible, { passive: true })
      }

      window.addEventListener("resize", checkVisible, { passive: true })
    })

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener("scroll", checkVisible)
      }

      window.removeEventListener("resize", checkVisible)
    })

    function search() {
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        reload()
      }, 300)
    }

    /*watch(
      () => filter.value,
      () => {
        reload()
      }
    )*/

    watch(
      () => sort.value,
      () => {
        reload()
      }
    )

    watch(
      () => selectedItems.value,
      () => {
        showSelectionError.value = false
      }
    )

    function checkVisible() {
      if (loadedAllData.value) return
      if (loading.value) return

      if (
        Math.ceil(scrollComponent.value.scrollTop * 1.3) >=
        scrollComponent.value.scrollHeight - scrollComponent.value.clientHeight
      ) {
        loadPart(part, sort.value, filter.value)
      }
    }

    function loadPart(part: number, sort: any, filter: string) {
      loading.value = true
      userService.getUsers(part, sort, filter).then((result) => {
        onPartLoaded(result)
      })
    }

    function onPartLoaded(result: ResultSet<any>) {
      loadedAllData.value = !result.moreToCome
      loading.value = false

      if (result.rows.length == 0) {
        return
      }

      part++
      result.rows.map(function (element) {
        let item = { selected: false, data: element }
        items.value.push(item)
      })

      nextTick().then(() => {
        checkVisible()
      })
    }

    function reload() {
      loading.value = true
      part = 0
      selectedItems.value = null
      loadedAllData.value = false
      items.value = []
      loadPart(part, sort.value, filter.value)
    }

    async function addRole() {
      if (!selectedItems.value || !selectedItems.value.length) {
        showSelectionError.value = true
        return
      }
      for (const item of selectedItems.value) {
        await roleService.addRole(props.organization.id, item.data.id, roleType.value)
      }
      emit("reload-members")
    }

    function createAndAdd(data: any) {
      let user = {
        firstName: data.firstName,
        lastName: data.lastName,
        username: data.username,
        email: data.email,
        password: data.password,
      }
      userService.addUser(user).then((result) => {
        roleService.addRole(props.organization.id, result, data.role).then(() => {
          emit("reload-members")
        })
      })
    }

    const sortColumn = (event: any) => {
      if (event.value) {
        sort.value = event.value
      } else {
        sort.value = event.sortField + "," + (event.sortOrder == 1 ? "desc" : "asc")
      }
    }

    return {
      items,
      loading,
      selectedItems,
      filter,
      roleType,
      roleTypes,
      addRole,
      showSelectionError,
      sortColumn,
      createAndAdd,
      search,
    }
  },
}
