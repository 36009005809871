import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76ac90cd"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["status"]
const _hoisted_2 = {
  key: 1,
  class: "employee-available"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "phone-status-square",
    status: $props.phoneStatus?.status
  }, [
    _createElementVNode("p", null, _toDisplayString($props.employee.firstName.charAt(0) + $props.employee.lastName.charAt(0).toUpperCase()), 1),
    (
        ($props.phoneStatus?.status === 'RINGING' || $props.phoneStatus?.status === 'CALLING') &&
        $setup.incomingOrganizationContact
      )
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "org-calling",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goToOrg($setup.incomingOrganizationContact?.organization?.id)))
        }, _toDisplayString($setup.incomingOrganizationContact?.organization?.code), 1))
      : _createCommentVNode("", true),
    ($props.phoneStatus?.status === 'IDLE')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_mdicon, { name: "circleMedium" })
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}