
import NavigationPanelMenuItem from "./NavigationPanelMenuItem.vue"

export default {
  name: "NavigationPanelMenu",
  components: {
    NavigationPanelMenuItem,
  },
  props: {
    menuItems: {
      type: Object,
    },
  },
  setup() {
    return {}
  },
}
