import { onMounted, ref } from "vue"

export default function useNetworkUtils() {
  const online = ref()

  onMounted(() => {
    online.value = window.navigator.onLine

    window.addEventListener("offline", (e) => {
      online.value = false
    })

    window.addEventListener("online", (e) => {
      online.value = true
    })
  })

  return {
    online,
  }
}
