import axios from "axios"
import ResultSet from "@/models/ResultSet"

export default class RenderInstructionService {
  pageSize: number = 20

  protected readonly endpoint: string

  public constructor(endpoint: string) {
    this.endpoint = endpoint
  }

  public get(ruleID: string) {
    return axios.get(this.endpoint + "/" + ruleID, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  public edit(
    ruleID: string,
    species: string,
    anatomy: string,
    direction: string,
    leg: string,
    stature: string,
    payload: any
  ) {
    payload.species = species
    payload.anatomy = anatomy
    payload.direction = direction
    payload.leg = leg
    payload.stature = stature

    return axios.put(this.endpoint + "/" + ruleID, payload, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  public getRules(
    computerId: string,
    userId: string,
    organizationId: string,
    species: string,
    anatomy: string,
    direction: string,
    leg: string,
    stature: string,
    part: Number,
    sort: any,
    exclDefault: boolean = false,
    pageSize: number = this.pageSize
  ) {
    let url =
      this.endpoint +
      "/support?size=" +
      pageSize +
      "&page=" +
      part +
      "&sort=" +
      sort +
      "&exclDefault=" +
      exclDefault

    if (organizationId != null) {
      url += "&organizationId=" + organizationId
    }

    if (computerId != null) {
      url += "&computerId=" + computerId
    }

    if (userId != null) {
      url += "&userId=" + userId
    }

    if (species != null) {
      url += "&species=" + species
    }

    if (anatomy != null) {
      url += "&anatomy=" + anatomy
    }

    if (direction != null) {
      url += "&direction=" + direction
    }

    if (leg != null) {
      url += "&leg=" + leg
    }

    if (stature != null) {
      url += "&stature=" + stature
    }

    let oThis = this

    return axios
      .get(url, { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  public add(
    computerId: string,
    userId: string,
    organizationId: string,
    species: string,
    anatomy: string,
    direction: string,
    leg: string,
    stature: string,
    payload: any
  ) {
    let url = this.endpoint + "/support"

    if (organizationId != null) {
      url += "?organizationId=" + organizationId
    }

    if (computerId != null) {
      url += organizationId != null ? "&" : "?"
      url += "computerId=" + computerId
    }

    if (userId != null) {
      url += organizationId != null || computerId != null ? "&" : "?"
      url += "userId=" + userId
    }

    if (species != null) {
      payload.species = species
    }
    if (anatomy != null) {
      payload.anatomy = anatomy
    }
    if (direction != null) {
      payload.direction = direction
    }
    if (leg != null) {
      payload.leg = leg
    }
    if (stature != null) {
      payload.stature = stature
    }

    return axios
      .post(url, payload, { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => res.data)
  }

  public copy(organizationId: string, computerId: string, userId: string, riIds: Array<string>) {
    let queryParams = new URLSearchParams()
    if (organizationId) {
      queryParams.append("organizationId", organizationId)
    }
    if (computerId) {
      queryParams.append("computerId", computerId)
    }
    if (userId) {
      queryParams.append("userId", userId)
    }
    const url: string = this.endpoint + `/copy/support?${queryParams.toString()}`
    return axios.post(url, riIds, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  public editMultiple(ids: string[], payload: any) {
    let url = this.endpoint + "/support?"

    for (let id of ids) {
      url += "&ids=" + id
    }

    return axios
      .put(url, payload, { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => res.data)
  }

  public deleteMultiple(ids: string[]) {
    let url = this.endpoint + "?cascade=false"

    for (let id of ids) {
      url += "&ids=" + id
    }

    return axios.delete(url, { baseURL: process.env.VUE_APP_VSOL_API }).then((res) => res.data)
  }

  public getLutFiles() {
    return axios
      .get("/luts/files", { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => res.data)
  }

  public getProcessings(id: string) {
    return axios
      .get(this.endpoint + "/" + id + "/processings", { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => res.data)
  }

  public getProcessing(id: string) {
    return axios
      .get(this.endpoint + "/" + id + "/processing", { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => res.data)
  }
}
