import { format, formatDistanceStrict } from "date-fns"

export default function useTimeUtils() {
  function absoluteTime(timestamp: Date): string {
    //times between 23h - 00h caused a bug when adding 1 hour for our timezone, 23:30:00 --> 24:30:00
    //const options = { hour12: false }
    //return timestamp.toLocaleTimeString(undefined, options)
    return format(timestamp, "HH:mm:ss")
  }

  function absoluteDate(timestamp: Date): string {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
    } as Intl.DateTimeFormatOptions
    return timestamp.toLocaleString(undefined, options)
  }

  function toDateFormat(date: Date, dateFormat: string) {
    return format(date, dateFormat)
  }

  function absoluteDateTime(timestamp: Date): string {
    return absoluteDate(timestamp) + " " + absoluteTime(timestamp)
  }

  function distanceBetweenDates(startDate: Date, endDate: Date) {
    if (!startDate || !endDate) return null

    let diff = Math.abs(endDate.getTime() - startDate.getTime())

    let ms = diff % 1000
    diff = (diff - ms) / 1000
    let ss = diff % 60
    diff = (diff - ss) / 60
    let mm = diff % 60
    diff = (diff - mm) / 60
    let hh = diff % 24
    let days = (diff - hh) / 24

    return `${days ? days + "day" + (days > 1 ? "s" : "") : ""} ${hh ? hh + "h" : ""} ${
      mm ? mm + "min" : ""
    } ${ss ? ss + "s" : ""}`
  }

  function getYesterdayDate(startDate?: string): Date {
    const todayDate = startDate ? new Date(startDate) : new Date()
    return new Date(new Date().setDate(todayDate.getDate() - 1))
  }

  return {
    absoluteDate,
    absoluteTime,
    absoluteDateTime,
    toDateFormat,
    distanceBetweenDates,
    getYesterdayDate,
  }
}
