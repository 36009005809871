
import axios, { AxiosRequestHeaders } from "axios"
import { RouterView, useRoute, useRouter } from "vue-router"
import useAppUtils from "@/utils/app"
import { useToast } from "primevue/usetoast"
import useNetworkUtils from "@/utils/network"
import { computed, onMounted, ref, watch, nextTick } from "vue"
import { useStore } from "vuex"
import AuthService from "@/services/AuthService"
import Toast from "primevue/toast"
import realtime from "@/utils/realtime"
import { state } from "@/utils/state"
import { isCurrentUrlPublic } from "@/helpers/UrlPermissionHelper"
import { vsolCompanyId } from "./constants/companies"
import { checkPermissionsOfElements } from "./helpers/UrlPermissionHelper"
import ActiveCalls from "./components/calls/ActiveCalls.vue"

declare global {
  interface Window {
    easyApi: any
  }
}

window.easyApi = window.easyApi || {}

export default {
  components: { ActiveCalls },
  setup() {
    window["easyApi"] = {}
    console.log("Starting " + process.env.VUE_APP_TITLE)

    let route = useRoute()
    const { isMobile } = useAppUtils()
    const toast = useToast()
    const store = useStore()
    const roles = store.getters.getRoles
    const router = useRouter()
    const { online } = useNetworkUtils()
    const service = new AuthService()
    const token = computed(() => store.getters.getToken)
    const isStaging = process?.env?.VUE_APP_DATABASE_NAME == "Staging"
    const mounted = ref(false)
    const observerelement = ref(null)
    let observer: any
    state.isVsolEmployee = roles ? !!roles[vsolCompanyId] : false

    router.beforeEach((to, from, next) => {
      //routes need explicit public = false otherwise stuff will render when undefined
      if (to.meta.public !== true) {
        to.meta.public = false
      }
      next()
    })

    watch(observerelement, (newElement) => {
      if (newElement && !observer) {
        observer = new MutationObserver((records, observer) => {
          checkPermissionsOfElements(records, roles, state.isVsolEmployee)
        })

        observer.observe(observerelement.value, {
          childList: true,
          subtree: true,
          attributeFilter: ["permission"],
        })
      }
    })

    onMounted(() => {
      state.realtime = new realtime()

      //add tinymce
      const tinymce = document.createElement("script")
      tinymce.setAttribute("src", "/tinymce/js/tinymce/tinymce.min.js")
      tinymce.setAttribute("type", "text/javascript")
      document.head.appendChild(tinymce)
      const mainContent = ref(document.getElementsByClassName("main-content")[0])

      if (isMobile.value) {
        mainContent.value && mainContent.value.addEventListener("click", collapseDrawer)
      } else {
        mainContent.value && mainContent.value.removeEventListener("click", collapseDrawer)
      }

      watch(
        () => isMobile.value,
        (newValue, oldValue) => {
          if (newValue) {
            mainContent.value && mainContent.value.addEventListener("click", collapseDrawer)
          } else {
            mainContent.value && mainContent.value.removeEventListener("click", collapseDrawer)
          }
        }
      )

      nextTick(() => {
        mounted.value = true
      })
    })

    function collapseDrawer() {
      if (!store.state.uiState.drawer.collapsed) {
        store.dispatch("setDrawerCollapsed", true)
      }
    }

    /*watch(route, () => {
      //forces a reload of right side, because sometimes state remained from previous route
      reloadKey.value++
    })*/

    watch(
      () => online.value,
      (newValue, oldValue) => {
        updateToast(newValue)

        if (oldValue == false && newValue) {
          location.reload()
        }
      }
    )

    function updateToast(online: any) {
      if (online == true) {
        toast.removeGroup("connection")
      } else {
        toast.add({
          severity: "warn",
          summary: "Connection lost",
          detail: "You appear to be offline",
        })
      }
    }

    // WHAT API TO USE
    if (process.env.VUE_APP_VSOL_API) {
      axios.defaults.baseURL = process.env.VUE_APP_ERP_API
    } else {
      console.error("VUE_APP_ERP_API not specified in this environment")
    }

    // Use tokens during request
    axios.interceptors.request.use(
      async (config) => {
        if (store.getters.getIsLoggedIn && token.value != null) {
          config.headers = {
            Authorization: `Bearer ${token.value}`,
          } as AxiosRequestHeaders
        }

        return config
      },
      function (error) {
        console.dir(error)
        return Promise.reject(error)
      }
    )

    // Refresh tokens
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      function (error) {
        const originalRequest = error.config
        if (typeof error.response === "undefined") {
          return Promise.reject(error)
        }

        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true
          return service.refreshToken(store.getters.getRefreshToken).then(
            (res) => {
              if (res.status === 200 || res.status === 201) {
                store.dispatch("refreshTokens", res.data)
                axios.defaults.headers.common["Authorization"] = `Bearer ${token.value}`
                return axios(originalRequest).catch((error) => {
                  return Promise.reject(error)
                })
              }
            },
            (res) => {
              if (!isCurrentUrlPublic()) {
                store.dispatch("logOut")
                router.push({ name: "login" })
                return Promise.reject(error)
              }
            }
          )
        }

        if (
          (error.response.status === 403 && originalRequest.url.indexOf("auth/refresh") >= 0) ||
          (error.response.status === 401 && originalRequest.url.indexOf("auth/signin") >= 0)
        ) {
          router.push({ name: "login" })
          return Promise.reject(error)
        }
        return Promise.reject(error)
      }
    )

    checkStagingStatus()
    function checkStagingStatus() {
      let link
      let title
      link = document.querySelector("link[rel*='icon']")
      title = document.querySelector("title")

      if (isStaging == true) {
        link.setAttribute("href", "../../vsolstaging.ico")
        document.getElementsByTagName("head")[0].appendChild(link)
        title.innerText = "ERP Staging " + process?.env?.VUE_APP_VERSION
      } else {
        link.setAttribute("href", "../../vsol.ico")
        document.getElementsByTagName("head")[0].appendChild(link)
        title.innerText = "ERP " + process?.env?.VUE_APP_VERSION
      }
    }

    return {
      route,
      isMobile,
      mounted,
      isStaging,
      observerelement,
      state,
    }
  },
}
