import { Module } from "vuex"
import ResultSet from "@/models/ResultSet"

interface UserGroupServiceState {
  userGroups: Map<string, ResultSet<any>>
}

const userGroupsModule: Module<UserGroupServiceState, any> = {
  state: () => ({
    userGroups: new Map<string, ResultSet<any>>(),
  }),
  mutations: {
    setUserGroups(state: any, payload: { url: string; data: any }) {
      if (!(state.userGroups instanceof Map)) {
        state.userGroups = new Map()
      }
      state.userGroups.set(payload.url, payload.data)
      console.log(`[CACHED #${payload.data.rows.length}]`, payload.url)
    },
  },
  getters: {
    hasUserGroupData:
      (state: any) =>
      (url: string): boolean => {
        if (!(state.userGroups instanceof Map)) {
          state.userGroups = new Map()
        }
        console.log("[HAS]", url, state.userGroups.has(url))
        return state.userGroups.has(url)
      },
    getUserGroupData:
      (state: any) =>
      (url: string): ResultSet<any> => {
        if (!(state.userGroups instanceof Map)) {
          state.userGroups = new Map()
        }
        console.log("[CACHE HIT]", url)
        return state.userGroups?.get(url)
      },
  },
}

export default userGroupsModule
