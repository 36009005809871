
import { computed, ref, onMounted, onUnmounted, watch, reactive } from "vue"
import AuthService from "@/services/AuthService"
import { useRoute, useRouter } from "vue-router"
import { useStore } from "vuex"
import useAppUtils from "@/utils/app"
import OrganizationSwitcher from "@/components/organizations/OrganizationSwitcher.vue"
import NavigationPanelMenu from "./NavigationPanelMenu.vue"
import LinkLabel from "./LinkLabel.vue"
import { state } from "@/utils/state"
import EmployeeService from "@/services/EmployeeService"
import { support } from "jquery"

export default {
  components: { OrganizationSwitcher, NavigationPanelMenu, LinkLabel },
  setup() {
    const store = useStore()
    const router = useRouter()
    const service = new AuthService()
    const { isMobile } = useAppUtils()
    const title = process.env.VUE_APP_TITLE
    //const historyCollapsed = computed(() => store.getters.isHistoryCollapsed)

    const menu = ref()
    const menuName = computed(() => {
      return { label: store.getters.getFullName, to: { name: "profile" } }
    })
    const isCollapsed = computed(() => store.getters.isDrawerCollapsed)
    const isStaging = computed(() => process.env.VUE_APP_DATABASE_NAME == "Staging")
    const organizationAutocomplete = ref()
    const allRecentOrgs = computed(() => store.getters.getOrgHistory)
    const allRecentUsers = computed(() => store.getters.getUserHistory)
    const allRecentComputers = computed(() => store.getters.getComputerHistory)
    const historyItemHeight = ref((window.innerHeight - 104) / 50)
    const heightQuarterOfHistoryItems = ref((window.innerHeight - 104) / 4)

    checkForMobile()

    onMounted(() => {
      window.addEventListener("resize", checkForMobile)
      organizationAutocomplete.value = document.querySelector("input.p-autocomplete-input")
      document.body.addEventListener("keyup", focusSearchbar)
    })

    onUnmounted(() => {
      window.removeEventListener("resize", checkForMobile)
      document.body.removeEventListener("keyup", focusSearchbar)
    })

    function focusSearchbar(e: any) {
      if (e.code === "F4") {
        organizationAutocomplete.value.focus()
      }
    }

    function checkForMobile() {
      if (!isMobile.value && isCollapsed.value) {
        onNavigationToggleClicked()
      }
    }

    const items = ref([
      {
        route: "hardware",
        label: "Hardware",
        icon: "desktopClassic",
        items: [
          {
            route: "panels",
            label: "Panels",
            to: { name: "panelSettings" },
          },
          {
            route: "generators",
            label: "Generators",
            to: { name: "generatorSettings" },
          },
          {
            route: "measurers",
            label: "Measurers",
            to: { name: "measurerSettings" },
          },
          {
            route: "dicomapplicationentities",
            label: "DAE",
            to: { name: "dicomApplicationEntities" },
          },
        ],
      },
      {
        route: "settings",
        label: "Settings",
        icon: "cog",
        items: [
          {
            route: "settings",
            label: "General",
            to: { name: "settingsHome" },
          },
          {
            route: "processing",
            label: "Processing Presets",
            to: { name: "processingCollection" },
          },
          {
            route: "rendering",
            label: "Def. Render Instructions",
            items: [
              {
                route: "orientations",
                label: "Orientation",
                to: { name: "orientationsCollection" },
              },
              {
                route: "croppings",
                label: "Cropping",
                to: { name: "croppingCollection" },
              },
              {
                route: "annotations",
                label: "Annotations",
                to: { name: "annotationsCollection" },
              },
              {
                route: "cvinput",
                label: "Context Vision Input",
                to: { name: "CVInputCollection" },
              },
              {
                route: "windowings",
                label: "Windowings",
                to: { name: "windowingsCollection" },
              },
            ],
          },
        ],
      },
    ])

    const menuLinks = [
      /*{
            label: 'settings',
            icon: 'pi pi-cog',

        },
        // }, {
           separator: true
        }, */ {
        label: "Sign out",
        icon: "sign_out_icon",
        command: onLogout,
      },
    ]

    // const menuItems = computed(() => [...menuLinks]);
    const menuItems = computed(() => [menuName.value, { separator: true }, ...menuLinks])

    function onToggleUserMenu(event: any) {
      menu.value.toggle(event)
    }

    function onLogout() {
      service.logOut().then(
        () => {
          store.dispatch("logOut")
          router.push({ name: "login" })
        },
        () => {
          store.dispatch("logOut")
          router.push({ name: "login" })
        }
      )
    }

    function onNavigationToggleClicked() {
      store.dispatch("setDrawerCollapsed", !isCollapsed.value)
    }

    function onNavigationOptionClicked() {
      collapseIfSmall()
    }

    function collapseIfSmall() {
      if (isMobile.value) {
        store.dispatch("setDrawerCollapsed", true)
      }
    }

    /*function toggleHistory() {
      store.dispatch("setHistoryCollapsed", !historyCollapsed.value)
    }*/

    function goToOrg(org: any) {
      router.push({ name: "organization", params: { organization_id: org.id } })
    }

    function goToComp(computer: any) {
      router.push({ name: "computerProfile", params: { computer_id: computer.id } })
    }

    function goToUser(user: any) {
      router.push({ name: "userProfile", params: { user_id: user.id } })
    }

    return {
      isCollapsed,
      menu,
      menuItems,
      title,
      isStaging,
      onNavigationToggleClicked,
      onToggleUserMenu,
      onNavigationOptionClicked,
      items,
      isMobile,
      //historyCollapsed,
      historyItemHeight,
      heightQuarterOfHistoryItems,
      allRecentOrgs,
      allRecentUsers,
      allRecentComputers,
      //toggleHistory,
      goToOrg,
      goToComp,
      goToUser,
      state,
    }
  },
}
