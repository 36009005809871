<!--suppress XmlInvalidId -->
<template>
  <div class="field col-12">
    <label for="Annotations">Annotations</label>
    <InputText
      v-model="v$.annotations.$model"
      :class="{ 'p-invalid': v$.annotations.$error }"
      class="w-full"
      @change="$emit('update:modelValue', v$.annotations.$model)"
    />
    <inline-form-error
      v-if="v$.annotations.$error"
      :errors="v$.annotations.$errors"
    />
  </div>
</template>

<script>
import { ref } from "vue"
import useVuelidate from "@vuelidate/core"
import InputSwitch from "primevue/inputswitch"

export default {
  components: { InputSwitch },
  props: {
    additionalFields: { annotations: String },
  },
  setup(props) {
    const additionalRules = { annotations: {} }

    const v$ = useVuelidate(additionalRules, props.additionalFields)

    return {
      v$,
    }
  },
}
</script>
