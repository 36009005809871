
import { ref, computed, onMounted, onUnmounted, watch, nextTick, defineComponent } from "vue"
import { useRouter } from "vue-router"
import ResultSet from "@/models/ResultSet"
import { useConfirm } from "primevue/useconfirm"
import RolesService from "@/services/RolesService"
import Button from "primevue/button"
import Column from "primevue/column"
import ConfirmPopup from "primevue/confirmpopup"
import DataTable from "primevue/datatable"
import LinkLabel from "../navigation/LinkLabel.vue"
import UserService from "@/services/UserService"
import UserForm from "@/components/users/UserForm.vue"
import RoleForm from "@/components/roles/RoleForm.vue"
import UserToOrgRoleForm from "@/components/roles/UserToOrgRoleForm.vue"
import PrimaryButton from "../buttons/PrimaryButton.vue"
import SecondaryButton from "../buttons/SecondaryButton.vue"
import SettingService from "@/services/SettingService"
import AuthService from "@/services/AuthService"
import { useToast } from "primevue/usetoast"

export default defineComponent({
  components: { LinkLabel, UserForm, RoleForm, UserToOrgRoleForm, PrimaryButton, SecondaryButton },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    let part = 0
    const scrollComponent = ref()
    const filter = ref("")
    const sort = ref("")
    const loading = ref(true)
    const loadedAllData = ref(false)
    const loadingPart = ref(false)
    const items = ref([])
    const selectedItems = ref()
    const confirm = useConfirm()
    const router = useRouter()
    const rolesService = new RolesService()
    const settingService = new SettingService()
    const authService = new AuthService()
    const pmsConnection = ref(false)
    const pmsName = ref()
    const toast = useToast()
    const collectionComponent = ref()
    const windowWidth = ref(window.innerWidth)
    const displayNewMember = ref(false)
    const displayNewOrg = ref(false)
    const rolesNewLabel = ref("")

    const showSortOptions = computed(() => windowWidth.value < 960)
    const scrollable = computed(() => windowWidth.value > 960)

    determineLabel()
    function determineLabel() {
      if (props.user) {
        rolesNewLabel.value = "Organization"
      } else {
        rolesNewLabel.value = "Member"
      }
    }

    if (props.organization) {
      settingService
        .getRules("pms.connection", props.organization.id, null, null, 0, "")
        .then((result: ResultSet<any>) => {
          pmsConnection.value = !!result.rows.filter(
            (item: any) => item.organizationId && item.organizationId === props.organization.id
          ).length

          if (pmsConnection.value) {
            settingService
              .getRules("pms.name", props.organization.id, null, null, 0, "")
              .then((res: ResultSet<any>) => {
                pmsName.value = res.rows.filter(
                  (item: any) =>
                    item.organizationId && item.organizationId === props.organization.id
                )[0]?.value
              })
          }
        })
    }

    loadPart(0, sort.value, filter.value)

    onMounted(() => {
      loading.value = false
      scrollComponent.value = document.querySelector(".p-datatable-wrapper")
      if (scrollComponent.value) {
        scrollComponent.value.addEventListener("scroll", checkVisible, { passive: true })
      }

      window.addEventListener("resize", checkVisible, { passive: true })
    })

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener("scroll", checkVisible)
      }

      window.removeEventListener("resize", checkVisible)
    })

    watch(
      () => filter.value,
      () => {
        reload()
      }
    )

    watch(
      () => sort.value,
      () => {
        reload()
      }
    )

    function reload() {
      loadingPart.value = true
      part = 0
      selectedItems.value = null
      loadedAllData.value = false
      items.value = []
      loadPart(part, sort.value, filter.value)
      closeNewMemberDisplay()
    }

    function checkVisible() {
      windowWidth.value = window.innerWidth
      if (loadedAllData.value) return
      if (loadingPart.value) return

      if (
        Math.ceil(scrollComponent.value.scrollTop * 1.3) >=
        scrollComponent.value.scrollHeight - scrollComponent.value.clientHeight
      ) {
        loadPart(part, sort.value, filter.value)
      }
    }

    const sortColumn = (event: any) => {
      if (event.value) {
        sort.value = event.value
      } else {
        sort.value = event.sortField + "," + (event.sortOrder == 1 ? "desc" : "asc")
      }
    }

    function loadPart(part: Number, sort: any, filter: string) {
      loadingPart.value = true
      if (props.organization != null) {
        rolesService
          .getOrganizationRoles(props.organization.id, part, sort, filter)
          .then((result: ResultSet<any>) => {
            onPartLoaded(part, sort, result)
          })
      } else if (props.user != null) {
        rolesService
          .getUserRoles(props.user.id, part, sort, filter)
          .then((result: ResultSet<any>) => {
            onPartLoaded(part, sort, result)
          })
      }
    }

    function onPartLoaded(loadedPart: Number, sort: any, result: ResultSet<any>) {
      loadingPart.value = false
      loadedAllData.value = !result.moreToCome

      if (result.rows.length == 0) {
        return
      }

      part++
      result.rows.map(function (element) {
        let item = { selected: false, data: element }
        items.value.push(item)
      })

      nextTick().then(() => {
        checkVisible()
      })
    }

    const sortOptions = ref([
      { label: "Role ↓", value: "type,asc" },
      { label: "Role ↑", value: "type,desc" },
    ])

    /*const selectedItems = computed(() => {
      return collectionComponent.value != null ? collectionComponent.value.selectedItems : []
    })*/

    function deleteSelected() {
      let numRemainingDeletes = selectedItems.value.length
      selectedItems.value.forEach((selectedItem: any) => {
        rolesService.deleteRole(selectedItem.data.id).then(() => {
          numRemainingDeletes--

          if (numRemainingDeletes == 0) {
            reload()
          }
        })
      })
    }

    function onNew() {
      router.push({ name: "newOrganization" })
    }

    const onConfirmDelete = (event: any) => {
      confirm.require({
        target: event.currentTarget,
        message:
          "Do you want remove " + selectedItems.value.length + " users from this organization?",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => deleteSelected(),
      })
    }

    const openNewMemberDisplay = () => {
      if (props.organization) {
        displayNewMember.value = true
      } else if (props.user) {
        displayNewOrg.value = true
      }
    }

    const closeNewMemberDisplay = () => {
      displayNewMember.value = false
      displayNewOrg.value = false
    }

    function generatePmsApiKey() {
      if (selectedItems.value && selectedItems.value.length && selectedItems.value.length === 1) {
        authService
          .generatePmsSupportKey(
            selectedItems.value[0].data.user.id,
            props.organization.id,
            pmsName.value
          )
          .then((result: any) => {
            if (result) {
              navigator.clipboard.writeText(result)
              toast.add({
                severity: "success",
                detail: "Copied to clipboard!",
                life: 3000,
              })
              selectedItems.value = null
            }
          })
      }
    }

    return {
      sortOptions,
      collectionComponent,
      selectedItems,
      loadPart,
      onNew,
      onConfirmDelete,
      sortColumn,
      items,
      loading,
      showSortOptions,
      sort,
      reload,
      openNewMemberDisplay,
      closeNewMemberDisplay,
      displayNewMember,
      displayNewOrg,
      scrollable,
      rolesNewLabel,
      pmsConnection,
      generatePmsApiKey,
      pmsName,
    }
  },
})
