import ReportService from "@/services/ReportService"
import { Ref } from "vue"

export default function useTemplateUtils() {
  const reportService = new ReportService()

  async function postReportTemplatePart(
    alias: string,
    internalAlias: string,
    type: string,
    language: string,
    organizationId: string,
    userId: string,
    content: string | Blob,
    callback?: Function,
    dialogToClose?: Ref<boolean>,
    aliasRef?: Ref<string>,
    internalAliasRef?: Ref<string>,
    resetDropdown?: Function
  ) {
    await reportService.addTemplateType(
      alias,
      internalAlias,
      type,
      language,
      organizationId,
      content,
      userId
    )
    if (dialogToClose) dialogToClose.value = false
    if (aliasRef != null) aliasRef.value = ""
    if (internalAliasRef != null) internalAliasRef.value = ""
    if (callback) callback()
    if (resetDropdown) resetDropdown()
  }

  return { postReportTemplatePart }
}
