
import ReportTemplateEditModal from "@/components/reports/tools/ReportTemplateEditModal.vue"
import { useToast } from "primevue/usetoast"
import ReportService from "@/services/ReportService"
import { useConfirm } from "primevue/useconfirm"
import { ref, defineComponent, computed } from "vue"
import LinkLabel from "../navigation/LinkLabel.vue"
import { useRoute } from "vue-router"
import { useStore } from "vuex"
import ReportTemplateViewModal from "@/components/reports/tools/ReportTemplateViewModal.vue"

export default defineComponent({
  name: "ReportTemplatesItem",
  components: { ReportTemplateEditModal, LinkLabel, ReportTemplateViewModal },
  props: {
    template: {
      type: Object,
      default: null,
    },
  },
  emits: ["template-changed"],
  setup(props, { emit }) {
    const showEdit = ref(false)
    let templateRef = ref()
    const toast = useToast()
    const confirm = useConfirm()
    const reportService = new ReportService()
    const showDuplicationOptions = ref(false)
    const route = useRoute()
    const organizationId = computed(() => route.params.organization_id as string)
    const deleteDefaultConfirmDialog = ref(false)
    const store = useStore()
    const roles = Object.values(store.getters.getRoles)
    const viewTemplate = ref(false)

    function checkAdminRole() {
      if (
        roles.includes("ADMIN") ||
        roles.includes("SUPER_SUPPORT") ||
        roles.includes("SUPER_ADMIN")
      ) {
        return true
      } else {
        return false
      }
    }

    function checkAdminStatus() {
      if (
        props.template.userId == null &&
        props.template.organizationId == null &&
        props.template.computerId == null
      ) {
        return checkAdminRole()
      } else {
        return true
      }
    }

    function duplicateTemplate(organization: Boolean) {
      let alias = "Copy of " + props.template.alias
      let content
      reportService.readReportTemplate(props.template.id).then((result) => {
        content = result.data
        if (organization === true) {
          reportService
            .addTemplateType(
              alias,
              props.template.internalAlias,
              props.template.type,
              props.template.language,
              organizationId.value,
              content
            )
            .then(() => {
              emit("template-changed")
              // showEdit.value = true
              showDuplicationOptions.value = false
              let copyAlias = alias + " created"
              toast.add({
                severity: "success",
                summary: "Successfully duplicated for organization",
                detail: copyAlias,
                life: 3000,
              })
            })
        } else {
          reportService
            .addDefaultTemplateType(
              alias,
              props.template.internalAlias,
              props.template.type,
              props.template.language,
              content
            )
            .then(() => {
              emit("template-changed")
              showDuplicationOptions.value = false
              let copyAlias = alias + " created"
              toast.add({
                severity: "success",
                summary: "Successfully duplicated as default",
                detail: copyAlias,
                life: 3000,
              })
            })
        }
      })
    }

    function onConfirmDelete(event: any) {
      confirm.require({
        target: event.currentTarget,
        message: "Are you sure you want to delete this template part ?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: () => {
          deleteTemplate()
        },
      })
    }

    function deleteTemplate() {
      if (
        !props.template.organizationId &&
        !props.template.userId &&
        !deleteDefaultConfirmDialog.value
      ) {
        deleteDefaultConfirmDialog.value = true
        return
      } else {
        deleteDefaultConfirmDialog.value = false
      }
      reportService.deleteTemplate(props.template.id).then(() => {
        templateChanged()
        toast.add({
          severity: "success",
          summary: "Successfully deleted template part",
          life: 3000,
        })
      })
    }

    function templateChanged() {
      showEdit.value = false
      emit("template-changed")
    }

    return {
      showEdit,
      templateChanged,
      templateRef,
      duplicateTemplate,
      onConfirmDelete,
      showDuplicationOptions,
      deleteDefaultConfirmDialog,
      deleteTemplate,
      roles,
      checkAdminRole,
      checkAdminStatus,
      viewTemplate,
    }
  },
})
