
import { computed, ref } from "vue"
import useTimeUtils from "@/utils/time"
import ComputerRunConfiguration from "@/components/computers/tools/ComputerRunConfiguration.vue"
import ComputerSyncTime from "@/components/computers/tools/ComputerSyncTime.vue"
import ComputerUpdateTime from "@/components/computers/tools/ComputerUpdateTime.vue"
import LinkLabel from "../navigation/LinkLabel.vue"

export default {
  components: { ComputerUpdateTime, ComputerSyncTime, ComputerRunConfiguration, LinkLabel },
  props: {
    computer: {
      type: Object,
      default: null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onComputerChanged"],
  setup() {
    const { absoluteDateTime } = useTimeUtils()

    return {
      absoluteDateTime,
    }
  },
}
