<!--suppress XmlInvalidId -->
<template>
  <div class="field col-12 md:col-3 xl:col-3">
    <label for="noise">Load a processing preset</label>
    <ProcessingAutoComplete v-model="processing" />
  </div>
  <ProcessingRuleValue
    :additional-fields="additionalFields"
    :show-alias="false"
  />
</template>

<script>
import ProcessingAutoComplete from "@/components/settings/processings/ProcessingAutoComplete"
import { ref, watch } from "vue"
import ProcessingService from "@/services/ProcessingService"

export default {
  components: { ProcessingAutoComplete },
  props: {
    additionalFields: {
      parameterFile: String,
      setting: Number,
      brightness: Number,
      contrast: Number,
      latitude: Number,
      edge: Number,
      noise: Number,
    },
  },
  setup(props) {
    const processing = ref()
    const service = new ProcessingService()

    watch(
      () => processing.value,
      (newValue, oldValue) => {
        if (processing.value) {
          service.get(newValue.id).then((result) => {
            props.additionalFields.parameterFile = result.data.parameterFile
            props.additionalFields.setting = result.data.setting
            props.additionalFields.brightness = result.data.brightness
            props.additionalFields.contrast = result.data.contrast
            props.additionalFields.latitude = result.data.latitude
            props.additionalFields.edge = result.data.edge
            props.additionalFields.noise = result.data.noise
          })
        }
      }
    )

    return {
      processing,
    }
  },
}
</script>
