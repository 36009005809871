import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table-col"
}
const _hoisted_2 = {
  key: 1,
  class: "table-col"
}
const _hoisted_3 = { class: "table-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkLabel = _resolveComponent("LinkLabel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.role.organization)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_LinkLabel, {
            id: _ctx.role.organization.id,
            value: _ctx.role.organization.name,
            type: "organization"
          }, null, 8, ["id", "value"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.role.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_LinkLabel, {
            id: _ctx.role.user.id,
            value: _ctx.role.user.username,
            type: "user"
          }, null, 8, ["id", "value"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.role.type), 1)
  ], 64))
}