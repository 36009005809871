
import { state } from "@/utils/state"
import { computed, ref } from "vue"
export default {
  name: "CallHistoryItem",
  props: {
    incomingCall: Object,
    supportEmployees: Object,
  },
  emits: ["go-to-org", "open-add-contact-dialog"],
  setup(props: any, { emit }: any) {
    const answeredRecipients = computed(() =>
      props.incomingCall?.callRecipients?.filter(
        (recipient: any) => recipient.status === "ANSWERED"
      )
    )

    function goToOrg(event: any) {
      emit("go-to-org", event, props.incomingCall?.organization)
    }

    function openAddContactDialog() {
      emit("open-add-contact-dialog", props.incomingCall?.phone)
    }

    return {
      state,
      goToOrg,
      answeredRecipients,
      openAddContactDialog,
    }
  },
}
