
import { ref, watch, computed } from "vue"
import ReportEditor from "../ReportEditor.vue"
import ReportService from "@/services/ReportService"

export default {
  name: "ReportTemplateViewModal",
  components: { ReportEditor },
  props: {
    templatePart: {
      type: Object,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  setup(props: any, { emit }: any) {
    const editorContent = ref("")
    const reportService = new ReportService()
    window.setTimeout(() => {
      editorContent.value = " "
    })
    loadTemplate()

    function loadTemplate() {
      reportService.readReportTemplate(props.templatePart.id).then((result) => {
        editorContent.value = result.data
      })
    }

    return { editorContent }
  },
}
