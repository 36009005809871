
import AuthService from "@/services/AuthService"
import { computed, ref, defineComponent } from "vue"
import useAppUtils from "@/utils/app"
import { useRouter } from "vue-router"
import LinkLabel from "../navigation/LinkLabel.vue"

export default defineComponent({
  components: { LinkLabel },
  props: {
    organization: Object,
  },
  setup(props) {
    const service = new AuthService()
    const loggingIn = ref(false)
    const form = ref()

    const VSOL_FRONTEND_BASE_URL = process.env.VUE_APP_VSOL_FRONTEND

    function onOpenVSOL6() {
      loggingIn.value = true

      service.logInVsol(props.organization.id).then((response) => {
        if (response.status == 200) {
          let url =
            VSOL_FRONTEND_BASE_URL +
            "login/support?" +
            new URLSearchParams(response.data.tokens[0]).toString()
          // let routeData = VSOL6_FRONTEND + router.resolve({path: "/login", query: }).href;
          window.open(url, "_blank")
        } else {
          console.error("Login failed", response)
        }

        loggingIn.value = false
      })
    }

    return {
      form,
      loggingIn,
      onOpenVSOL6,
    }
  },
})
