
import { ComputedRef, computed, watch } from "vue"
import { useRoute, useRouter } from "vue-router"

export default {
  name: "TabsComponent",
  props: {
    items: Object,
    baseline: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any) {
    const route = useRoute()
    const router = useRouter()

    watch(
      () => route.fullPath,
      (newValue, oldValue) => {
        checkRouteForSelected()
      }
    )

    checkRouteForSelected()
    function checkRouteForSelected() {
      props.items.map(
        (item: any) =>
          (item.selected = !!route.matched.find(
            (routeObj: any) => routeObj.name === item.routeName
          ))
      )
    }

    const selectedTab: ComputedRef<any> = computed(() => {
      props.items.filter((tab: any) => tab.selected)[0]
    })

    function routeTo(tab: any) {
      unselectTabs()
      tab.selected = true
      router.push({ name: tab.routeName })
    }

    function unselectTabs() {
      props.items.map((tab: any) => (tab.selected = false))
    }

    return {
      routeTo,
    }
  },
}
