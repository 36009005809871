import axios from "axios"

export default class ContactService {
  private pageSize: number = 20

  addContact(contact: any, organizationId: string, personId: string) {
    let url = "contacts"
    if (organizationId) {
      url += "?organizationId=" + organizationId
    }
    if (personId) {
      url += url.includes("?") ? "&" : "?" + "personId=" + personId
    }
    return axios.post(url, contact).then((res) => res.data)
  }

  getContacts(organizationId: string, personId: string, page: number, pageSize?: number) {
    let url: string =
      "contacts?page=" + page.toString() + "&size=" + pageSize
        ? pageSize.toString()
        : this.pageSize.toString()
    if (organizationId) {
      url += "&organizationId=" + organizationId
    }
    if (personId) {
      url += "&personId=" + personId
    }
    return axios.get(url).then((res) => res.data)
  }

  getContact(contactId: string) {
    let url = "contacts/" + contactId
    return axios.get(url).then((res) => res.data)
  }

  getContactByPhone(phoneNumber: string) {
    let url = "contacts/caller?phone=" + phoneNumber
    return axios.get(url).then((res) => res.data)
  }

  deleteContact(contactId: string) {
    let url = "contacts/" + contactId
    return axios.delete(url).then((res) => res.data)
  }

  deleteContacts(contactIds: string[]) {
    let url = "contacts"
    contactIds.forEach((id: string, index: number) => {
      if (index === 0) {
        url += "?"
      } else {
        url += "&"
      }
      url += "ids=" + id
    })
    return axios.delete(url).then((res) => res.data)
  }

  editContact(contactId: string, contact: any) {
    let url = "contacts/" + contactId
    return axios.put(url, contact).then((res) => res.data)
  }
}
