import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5930e413"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings-container" }
const _hoisted_2 = { class: "setting-card" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["title"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_SettingsCollectionRecordValues = _resolveComponent("SettingsCollectionRecordValues")!
  const _component_SettingsCollectionForm = _resolveComponent("SettingsCollectionForm")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.subCategory.settings, (setting) => {
        return (_openBlock(), _createElementBlock("div", null, [
          _createElementVNode("div", {
            class: _normalizeClass({ collapsed: setting.collapsed, novalues: setting.hasNoValues })
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: "header-layer",
                onClick: _withModifiers(($event: any) => ($setup.collapseAction(setting)), ["self"])
              }, [
                _createElementVNode("div", {
                  class: "flex align-items-center",
                  title: setting.description
                }, [
                  _createElementVNode("label", {
                    class: "cursor-pointer settingName",
                    onClick: ($event: any) => ($setup.collapseAction(setting))
                  }, _toDisplayString($setup.formatSettingName(setting.name)), 9, _hoisted_5),
                  _createVNode(_component_Button, _mergeProps({
                    class: "p-button-rounded p-button-text",
                    onClick: _withModifiers(($event: any) => ($setup.addNewValueForSetting(setting)), ["stop"])
                  }, $props.permissions), {
                    default: _withCtx(() => [
                      _createVNode(_component_mdicon, { name: "plus" })
                    ]),
                    _: 2
                  }, 1040, ["onClick"]),
                  (setting.value !== null)
                    ? (_openBlock(), _createElementBlock("label", {
                        key: 0,
                        class: "default",
                        onClick: ($event: any) => ($setup.collapseAction(setting))
                      }, "(Default) " + _toDisplayString(setting.value), 9, _hoisted_6))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_4),
                (!setting.hasNoValues)
                  ? (_openBlock(), _createBlock(_component_mdicon, {
                      key: 0,
                      name: "menuDown",
                      class: "settings-toggler",
                      onClick: ($event: any) => ($setup.collapseAction(setting))
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_3),
              _createVNode(_component_SettingsCollectionRecordValues, {
                ref_for: true,
                ref: "valuesRef",
                computer: $props.computer,
                user: $props.user,
                organization: $props.organization,
                setting: setting,
                searchValue: $props.searchValue,
                permissions: $props.permissions,
                onNoValues: ($event: any) => (setting.hasNoValues = true),
                onNoValuesReset: ($event: any) => (setting.hasNoValues = false)
              }, null, 8, ["computer", "user", "organization", "setting", "searchValue", "permissions", "onNoValues", "onNoValuesReset"])
            ])
          ], 2)
        ]))
      }), 256))
    ]),
    _createVNode(_component_Dialog, {
      modal: true,
      visible: $setup.showAddDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (($setup.showAddDialog) = $event)),
      header: "Add setting"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SettingsCollectionForm, {
          setting: $setup.settingToAdd,
          subCategorySettingAdd: $setup.settingToAdd,
          type: $setup.settingToAdd.type,
          computer: $props.computer,
          user: $props.user,
          organization: $props.organization,
          isEditing: false,
          onAdded: _cache[0] || (_cache[0] = ($event: any) => ($setup.reloadData(_ctx.$refs.valuesRef))),
          searchValue: $props.searchValue
        }, null, 8, ["setting", "subCategorySettingAdd", "type", "computer", "user", "organization", "searchValue"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}