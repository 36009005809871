import axios from "axios"
import ResultSet from "@/models/ResultSet"

export default class ReleasesService {
  pageSize: number = 50

  getRunConfigurations(part: Number = 0, sort: any) {
    let oThis = this
    return axios
      .get("runconfigurations?size=" + this.pageSize + "&page=" + part + "&sort=" + sort)
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  updateRunConfiguration(version: string, status: string, comments: string) {
    return axios.put(
      "runconfigurations/" + version + "/?status=" + status + "&comments=" + comments,
      {},
      { headers: { "Content-Type": "application/json" } }
    )
  }

  getAllRunConfigurations(stableOnly: Boolean = true) {
    return axios
      .get(stableOnly ? "runconfigurations/all?status=OK" : "runconfigurations/all")
      .then((res) => res.data)
  }

  getAllToolkitVersions() {
    return axios.get("toolkitversions/list").then((res) => res.data)
  }

  getToolkitVersions(part: Number = 0, sort: any) {
    let oThis = this
    return axios
      .get("toolkitversions?size=" + this.pageSize + "&page=" + part + "&sort=" + sort)
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  updateToolkitVersion(version: String, notes: String) {
    return axios.put(
      "toolkitversions/" + version,
      { notes: notes },
      { headers: { "Content-Type": "application/json" } }
    )
  }

  getFrontendVersions(part: Number = 0, sort: any) {
    let oThis = this
    return axios
      .get("frontendversions?size=" + this.pageSize + "&page=" + part + "&sort=" + sort)
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  updateFrontendVersion(version: String, notes: String) {
    return axios.put(
      "frontendversions/" + version,
      { notes: notes },
      { headers: { "Content-Type": "application/json" } }
    )
  }

  getBackendVersions(part: Number = 0, sort: any) {
    let oThis = this
    return axios
      .get("backendversions?size=" + this.pageSize + "&page=" + part + "&sort=" + sort)
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  updateBackendVersion(version: String, notes: String) {
    return axios.put(
      "backendversions/" + version,
      { notes: notes },
      { headers: { "Content-Type": "application/json" } }
    )
  }

  getAQSVersions(part: Number = 0, sort: any) {
    let oThis = this
    return axios
      .get("desktopversions?size=" + this.pageSize + "&page=" + part + "&sort=" + sort)
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  updateAQSVersion(version: String, notes: String) {
    return axios.put(
      "desktopversions/" + version,
      { notes: notes },
      { headers: { "Content-Type": "application/json" } }
    )
  }

  getAQS32Versions(part: Number = 0, sort: any) {
    let oThis = this
    return axios
      .get("desktop32versions?size=" + this.pageSize + "&page=" + part + "&sort=" + sort)
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  updateAQS32Version(version: String, notes: String) {
    return axios.put(
      "desktop32versions/" + version,
      { notes: notes },
      { headers: { "Content-Type": "application/json" } }
    )
  }

  getHalapiVersions(part: Number = 0, sort: any) {
    let oThis = this
    return axios
      .get("halapiversions?size=" + this.pageSize + "&page=" + part + "&sort=" + sort)
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  updateHalapiVersion(version: String, notes: String) {
    return axios.put(
      "halapiversions/" + version,
      { notes: notes },
      { headers: { "Content-Type": "application/json" } }
    )
  }

  getJamesVersions(part: number = 0, sort: any) {
    return axios
      .get(`erpversions?size=${this.pageSize}&page=${part}&sort=${sort}`)
      .then((res) => new ResultSet(res.data, this.pageSize))
  }

  updateJamesVersion(version: string, notes: string) {
    return axios.put(`erpversions/${version}`, { notes: notes })
  }
}
