
import { reactive, ref } from "vue"
import { helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import InputSwitch from "primevue/inputswitch"
import CompaniesService from "@/services/CompaniesService"
import { useStore } from "vuex"

export default {
  components: { InputSwitch },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    saved: {},
  },
  emits: ["submit", "cancel"],
  setup(props: any, { emit }: any) {
    const done = ref(false)
    const companyService = new CompaniesService()
    const companies = reactive([])
    const selectedCompany = ref()
    const savingAttempt = ref(false)
    const companyRequiredMessage = ref("Company is required")
    const store = useStore()

    function handleDefaultCompany() {
      companies.forEach((company: any) => {
        if (store.getters.getDefaultCompanyId === company.id) {
          selectedCompany.value = company
        }
      })
    }

    const form = ref({
      code: props.organization == null ? "" : props.organization.code,
      name: props.organization == null ? "" : props.organization.name,
      internalName: props.organization == null ? "" : props.organization.internalName,
      website: props.organization == null ? "" : props.organization.website,
      pms: props.organization == null ? "" : props.organization.pms,
      language: props.organization == null ? "EN" : props.organization.language,
      comments: props.organization == null ? "" : props.organization.comments,
      commercial: props.organization == null ? "" : props.organization.commercial,
      coc: props.organization == null ? "" : props.organization.coc,
      intraCommunity: props.organization == null ? false : props.organization.intraCommunity,
      extraCommunity: props.organization == null ? false : props.organization.extraCommunity,
      vat: props.organization == null ? "" : props.organization.vat,
      via: props.organization == null ? "" : props.organization.via,
      organizationType: props.organization == null ? null : props.organization.organizationType,
      size: props.organization == null ? null : props.organization.size,
      network: props.organization == null ? null : props.organization.network,
      subscription: props.organization == null ? "NONE" : props.organization.subscription,
    })

    const languages = ["EN", "NL", "FR", "DE"]
    const orgTypes = ["EQ", "SA", "HB", "OT"]
    const orgSizes = ["PRACTICE_1_PERSON", "PRACTICE_2_PERSON", "PRACTICE_MULTIVET", "CLINIC"]
    const orgNetworks = [
      "BOA",
      "BEVET",
      "IVC",
      "ANICURA",
      "CVS",
      "EVIDENSIA",
      "NESTO",
      "ALTANO",
      "EQUINE_CARE_GROUP",
    ]

    const subscriptionTypes = ["BRONZE", "SILVER", "GOLD", "NONE"]

    const rules = {
      code: { codeRequired: helpers.withMessage("The code is required", required) },
      name: {
        codeRequired: helpers.withMessage("The external name is required", required),
      },
      internalName: {},
      website: {},
      pms: {},
      language: {},
      comments: {},
      commercial: {},
      coc: {},
      intraCommunity: {},
      extraCommunity: {},
      vat: {},
      via: {},
      organizationType: {},
      size: {},
      network: {},
      subscription: {},
    }

    if (!props.organization) {
      companyService.getCompanies().then((result: any) => {
        if (result.data && result.data.length) {
          result.data.forEach((company: any) => {
            companies.push(company)
          })
          handleDefaultCompany()
        }
      })
    }

    const v$: any = useVuelidate(rules, form)

    function onSubmit() {
      v$.value.$touch()

      if (v$.value.$invalid) return

      if (props.organization) {
        emit("submit", form.value)
      } else {
        if (!selectedCompany.value) {
          savingAttempt.value = true
          return
        }
        emit("submit", form.value, selectedCompany.value)
      }
    }

    function onDone() {
      done.value = true
      setTimeout(() => {
        done.value = false
      }, 2500)
    }

    return {
      done,
      v$,
      onSubmit,
      onDone,
      languages,
      companies,
      orgNetworks,
      orgSizes,
      orgTypes,
      selectedCompany,
      savingAttempt,
      companyRequiredMessage,
      subscriptionTypes,
    }
  },
}
