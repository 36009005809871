<template>
  <Dropdown
    v-model="modelValue"
    :options="options"
    :show-clear="true"
    placeholder="Any stature"
    @change="$emit('update:modelValue', modelValue)"
  />
</template>

<script>
export default {
  name: "StatureDropdown",
  props: {
    modelValue: {
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const options = ["S", "M", "L"]

    return {
      options,
    }
  },
}
</script>
