<template>
  <div class="relative">
    <AutoComplete
      v-model="modelValue"
      class="w-full"
      :class="{ 'p-invalid': invalid }"
      :suggestions="filteredOrganizationOptions"
      :dropdown="true"
      :placeholder="placeholder"
      :disabled="disabled"
      :field="(item) => organizationToString(item)"
      :multiple="multiple"
      force-selection
      @complete="searchOrganization($event)"
      @item-select="$emit('update:modelValue', modelValue)"
      @item-unselect="$emit('update:modelValue', modelValue)"
    >
      <template #item="slotProps">
        <div class="p-1">
          {{ organizationToString(slotProps.item) }}
        </div>
      </template>
    </AutoComplete>
    <Button
      v-if="modelValue && !disabled"
      icon="pi pi-times"
      class="p-button-rounded p-button-secondary p-button-text clear"
      @click="$emit('update:modelValue', null)"
    />
  </div>
</template>

<script>
import OrganizationService from "@/services/OrganizationService"
import { ref, watch } from "vue"
import ComputerService from "@/services/ComputerService"
import UserService from "@/services/UserService"

export default {
  props: {
    modelValue: Object,
    excludes: {
      type: Object,
      default: [],
    },
    includes: {
      type: Array,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Select an organization",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    invalid: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const organizationService = OrganizationService.getInstance()
    const allOrganizationOptions = ref([])
    const filteredOrganizationOptions = ref([])

    initialize()

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        //   emit("update:modelValue", newValue);
      }
    )

    watch(
      () => props.includes,
      (newValue, oldValue) => initialize()
    )

    function initialize() {
      if (props.includes == null) {
        // Load all options
        loadOrganizationOptions()
      } else {
        // Only use includes
        allOrganizationOptions.value = props.includes
      }
    }

    function loadOrganizationOptions() {
      organizationService.getAllOrganizations().then((response) => {
        allOrganizationOptions.value = response
        filteredOrganizationOptions.value = response
        filterExcludes()
      })
    }

    function searchOrganization(event) {
      if (!event.query.trim().length) {
        filteredOrganizationOptions.value = [...allOrganizationOptions.value]
      } else {
        filteredOrganizationOptions.value = allOrganizationOptions.value.filter(
          (organizationOption) => {
            return (
              organizationToString(organizationOption)
                .toLowerCase()
                .indexOf(event.query.toLowerCase()) >= 0
            )
          }
        )
      }
      filterExcludes()
    }

    function filterExcludes() {
      filteredOrganizationOptions.value = filteredOrganizationOptions.value.filter(
        (organizationOption) => {
          return !props.excludes.includes(organizationOption.id)
        }
      )
    }

    function organizationToString(organization) {
      return organization.code + " - " + organization.name
    }

    return {
      filteredOrganizationOptions,
      searchOrganization,
      organizationToString,
    }
  },
}
</script>
<style scoped>
.clear {
  position: absolute;
  top: 5px;
  right: 40px;
}
</style>
