export default function useTaskUtils() {
  function getTaskIcon(type: string) {
    switch (type) {
      case "VISIT":
        return "car"
      case "OFFICE":
        return "officeBuilding"
      case "PHONE_CALL":
        return "phone"
      case "TEAMVIEWER":
        return "teamviewer"
      case "PARCEL":
        return "packageVariantClosed"
      case "LETTER":
        return "mailboxOutline"
      case "MAIL":
        return "emailOutline"
      case "WHATSAPP":
        return "whatsapp"
      default:
        return "help"
    }
  }

  return {
    getTaskIcon,
  }
}
