
import { computed, ref, defineComponent } from "vue"
import useTimeUtils from "@/utils/time"
import ReleasesService from "@/services/ReleasesService"
import { useStore } from "vuex"

export default defineComponent({
  props: {
    version: Object,
    type: String,
  },
  emits: ["version-changed"],
  setup(props, { emit }) {
    const { absoluteDateTime } = useTimeUtils()
    const { marked } = require("marked")
    const isEditing = ref(false)
    const markDown = computed(() => (props.version.notes ? marked(props.version.notes) : ""))
    const notes = ref(props.version.notes)
    const store = useStore()

    const roles = store.getters.getRoles

    const checkRoles = computed(() => {
      const _roles = Object.values(roles)
      if (_roles && _roles.length) {
        return (
          _roles.includes("SUPER_ADMIN") ||
          _roles.includes("SUPER_DEV") ||
          _roles.includes("SUPER_SUPPORT") ||
          _roles.includes("ADMIN") ||
          _roles.includes("DEV")
        )
      }
    })

    function onSave() {
      emit("version-changed", props.version.version, notes.value)
    }

    return {
      absoluteDateTime,
      isEditing,
      markDown,
      notes,
      onSave,
      checkRoles,
    }
  },
})
