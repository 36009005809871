<template>
  <p class="inline-flex justify-content-between align-items-center w-full">
    <span
      class="flex justify-content-between align-items-center relative"
      :class="commentsEnabled ? status.className : ''"
    >
      <span class="config-version">{{ runConfig.version }}</span>
      <!--<RunConfigurationStatusBadge
        v-if="commentsEnabled && showStatus && status && !showStatusMessage"
        :status="status"
      />-->

      <RunConfigurationStatus
        v-if="commentsEnabled && showStatus && status && showStatusMessage"
        :run-config="runConfig"
        :status="status"
        :show-status-message="showStatusMessage"
        @changed="$emit('changed')"
      />
    </span>
    <span
      v-if="showDate"
      class="date ml-5"
    >{{ date }}</span>
  </p>
</template>

<script>
import { useRouter } from "vue-router"
import useTimeUtils from "@/utils/time"
import { computed } from "vue"
import useArrayUtils from "@/utils/array"
import useReleaseUtils from "@/utils/releases"
import RunConfigurationStatus from "@/components/releases/run-configs/RunConfigurationStatus"
import RunConfigurationStatusBadge from "@/components/releases/run-configs/RunConfigurationStatusBadge"

export default {
  components: { RunConfigurationStatus, RunConfigurationStatusBadge },
  props: {
    runConfig: {
      type: Object,
      default: null,
    },
    showDate: {
      type: Boolean,
      default: true,
    },
    showStatus: {
      type: Boolean,
      default: false,
    },
    showStatusMessage: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["changed"],
  setup(props) {
    const { absoluteDateTime } = useTimeUtils()
    const { getOptionByValue } = useArrayUtils()
    const { releaseStatusOptions, defaultReleaseStatus } = useReleaseUtils()

    const date = computed(() => absoluteDateTime(new Date(props.runConfig.createdTime)))
    const commentsEnabled = process.env.VUE_APP_RUN_CONFIG_COMMENTS == "true"
    const status = computed(() =>
      getOptionByValue(releaseStatusOptions, props.runConfig.status, defaultReleaseStatus)
    )

    return { date, absoluteDateTime, commentsEnabled, status }
  },
}
</script>

<style scoped>
.config-version {
  background-color: transparent;
  border: 1px solid #029ddf;
  color: #029ddf;
  padding: 2px 5px 2px 5px;
  border-radius: 3px;
  font-size: 18px;
}

.date {
  color: #999;
  font-size: 15px;
}
</style>
