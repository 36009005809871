
import OrganizationService from "@/services/OrganizationService"
import { ref, watch } from "vue"
import { useRouter } from "vue-router"

export default {
  props: {
    modelValue: Object,
    excludes: {
      type: Object,
      default: [],
    },
    placeholder: {
      type: String,
      default: "Jump to organization",
    },
    invalid: Boolean,
  },
  emits: ["update:modelValue"],
  setup(props: any, { emit }: any) {
    const router = useRouter()
    const selectedOrganization = ref()
    const organizationService = OrganizationService.getInstance()
    //const allOrganizationOptions = ref([])
    const filteredOrganizationOptions = ref([])

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        selectedOrganization.value = newValue
      }
    )

    //loadOrganizationOptions()

    /*function loadOrganizationOptions() {
      organizationService.getAllOrganizations().then((response) => {
        allOrganizationOptions.value = response
        filteredOrganizationOptions.value = response

        filterExcludes()
      })
      // selectedOrganization.value = allOrganizationOptions.value[0]
    }

    const searchOrganization = (event) => {
      if (!event.query.trim().length) {
        filteredOrganizationOptions.value = [...allOrganizationOptions.value]
      } else {
        filteredOrganizationOptions.value = allOrganizationOptions.value.filter(
          (organizationOption) => {
            return (
              organizationToString(organizationOption)
                .toLowerCase()
                .indexOf(event.query.toLowerCase()) >= 0
            )
          }
        )
      }

      filterExcludes()
    }*/

    function filterExcludes() {
      filteredOrganizationOptions.value = filteredOrganizationOptions.value.filter(
        (organizationOption) => {
          return !props.excludes.includes(organizationOption.id)
        }
      )
    }

    function searchOrganization(event: any) {
      organizationService.getOrganizations(0, "", event.query.trim()).then((response) => {
        filteredOrganizationOptions.value = response.rows
        filterExcludes()
      })
    }

    function organizationToString(organization: any) {
      return organization.code + " - " + organization.name
    }

    function onSelect(event: any) {
      if (event.value) {
        router.push({ name: "organization", params: { organization_id: event.value.id } })
        selectedOrganization.value = null
      }
    }

    return {
      selectedOrganization,
      filteredOrganizationOptions,
      searchOrganization,
      organizationToString,
      onSelect,
      //loadOrganizationOptions,
    }
  },
}
