<template>
  <div class="col-12 p-1 mb-2">
    <div
      class="card p-3 shadow-2"
      :class="commentsEnabled ? status.className : ''"
    >
      <RunConfigurationTag
        :run-config="runConfig"
        :show-status="true"
        class="mb-2"
        @changed="$emit('changed')"
      />
      <p class="flex justify-content-between flex-wrap">
        <span class="component-version">Frontend {{ runConfig.frontendVersion.version }}</span>
        <span class="component-version">Backend {{ runConfig.backendVersion.version }}</span>
        <span class="component-version">Desktop {{ runConfig.desktopVersion.version }}</span>
        <span class="component-version halapi">
          <label v-if="runConfig.halapiVersion">Halapi {{ runConfig.halapiVersion.version }}</label>
        </span>
        <span class="component-version">Desktop (32-bit) {{ runConfig.desktop32Version.version }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import useTimeUtils from "@/utils/time"
import { computed } from "vue"
import useArrayUtils from "@/utils/array"
import useReleaseUtils from "@/utils/releases"

export default {
  props: {
    runConfig: Object,
  },
  emits: ["changed"],
  setup(props) {
    const { absoluteDateTime } = useTimeUtils()
    const { getOptionByValue } = useArrayUtils()
    const { releaseStatusOptions, defaultReleaseStatus } = useReleaseUtils()

    const commentsEnabled = process.env.VUE_APP_RUN_CONFIG_COMMENTS == "true"
    const status = computed(() =>
      getOptionByValue(releaseStatusOptions, props.runConfig.status, defaultReleaseStatus)
    )

    return {
      absoluteDateTime,
      commentsEnabled,
      status,
    }
  },
}
</script>

<style scoped>
.component-version {
  /*display: block;
    width: 150px;*/
  font-size: 15px;
}

.halapi {
  width: 10%;
  display: flex;
  justify-content: center;
}

.OK {
  /*border: 1px solid #689F38;;*/
}

.NOK {
  background-color: rgba(211, 47, 47, 0.05);
  border: 1px solid rgb(211, 47, 47);
}

.UNKNOWN {
  background-color: rgba(251, 192, 45, 0.05);
  border: 1px solid rgb(251, 192, 45);
}

.TESTING {
  background-color: rgba(66, 103, 213, 0.05);
  border: 1px solid rgb(46, 106, 225);
}
</style>
