
import { ref, reactive } from "vue"
import RenderInstructionService from "@/services/RenderInstructionService"
import ProcessingAutoComplete from "@/components/settings/processings/ProcessingAutoComplete.vue"

export default {
  components: { ProcessingAutoComplete },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    duplicate: {
      type: Boolean,
      default: false,
    },
    selectedRule: {
      type: Object,
      default: null,
    },
  },
  emits: ["closedialogs"],
  setup(props: any, { emit }: any) {
    const service = new RenderInstructionService("ricvinput")
    const additionalFields = reactive({
      alias: "",
      parameterFile: "",
      setting: 0,
      brightness: 0.0,
      contrast: 0.0,
      latitude: 0.0,
      edge: 0.0,
      noise: 0.0,
    })
    const form = ref()

    function onCreate() {
      form.value.addRule(additionalFields)
    }

    function onEdit() {
      form.value.editRule(additionalFields)
    }

    function onRetrieve(rule: any) {
      additionalFields.alias = rule.alias
      additionalFields.parameterFile = rule.parameterFile
      additionalFields.setting = rule.setting
      additionalFields.brightness = rule.brightness
      additionalFields.contrast = rule.contrast
      additionalFields.latitude = rule.latitude
      additionalFields.edge = rule.edge
      additionalFields.noise = rule.noise
    }

    function closeDialogs() {
      emit("closedialogs")
    }

    return {
      service,
      form,
      additionalFields,
      onCreate,
      onEdit,
      onRetrieve,
      closeDialogs,
    }
  },
}
