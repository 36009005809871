
import ReleasesService from "@/services/ReleasesService"
import Dropdown from "primevue/dropdown"
import { ref, watch } from "vue"
import ToolkitTag from "./ToolkitTag.vue"

export default {
  name: "ToolkitAutoComplete",
  components: { ToolkitTag },
  props: {
    modelValue: Object,
    excludes: {
      type: Object,
      default: [],
    },
    placeholder: {
      type: String,
      default: "Select a toolkit version",
    },
    invalid: Boolean,
  },
  emits: ["update:modelValue"],
  setup(props: any, { emit }: any) {
    const selectedItem = ref()
    const allOptions = ref([])
    const loading = ref(false)
    const service = new ReleasesService()

    watch(
      () => props.modelValue,
      (newValue, oldValue) => {
        selectedItem.value = newValue
      }
    )

    loadToolkitVersions()

    function loadToolkitVersions() {
      loading.value = true
      service.getAllToolkitVersions().then((response) => {
        allOptions.value = response
        selectedItem.value = allOptions.value[0]
        loading.value = false
        emit("update:modelValue", selectedItem.value)
      })
    }

    return {
      selectedItem,
      allOptions,
      loading,
    }
  },
}
