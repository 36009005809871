
import { ref, reactive } from "vue"
import RenderInstructionService from "@/services/RenderInstructionService"

export default {
  props: {
    organization: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    duplicate: {
      type: Boolean,
      default: false,
    },
    selectedRule: {
      type: Object,
      default: null,
    },
  },
  emits: ["closedialogs"],
  setup(props: any, { emit }: any) {
    const service = new RenderInstructionService("ricroppings")
    const additionalFields = reactive({ mask: false, crop: false })
    const form = ref()

    function onCreate() {
      form.value.addRule(additionalFields)
    }

    function onEdit() {
      form.value.editRule(additionalFields)
    }

    function onRetrieve(rule: any) {
      additionalFields.mask = rule.mask
      additionalFields.crop = rule.crop
    }

    function closeDialogs() {
      emit("closedialogs")
    }

    return {
      service,
      form,
      additionalFields,
      onCreate,
      onEdit,
      onRetrieve,
      closeDialogs,
    }
  },
}
