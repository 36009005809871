import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29687810"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "active-calls" }
const _hoisted_2 = { class: "top-section" }
const _hoisted_3 = { class: "active-call" }
const _hoisted_4 = { class: "top-row" }
const _hoisted_5 = { class: "top-row-left" }
const _hoisted_6 = { class: "top-row-right" }
const _hoisted_7 = {
  key: 0,
  class: "org-language"
}
const _hoisted_8 = {
  key: 1,
  class: "person-initials"
}
const _hoisted_9 = { class: "bottom-row" }
const _hoisted_10 = { class: "task" }
const _hoisted_11 = { class: "task-part task-part-left" }
const _hoisted_12 = { class: "subject" }
const _hoisted_13 = { class: "task-part" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_LinkLabel = _resolveComponent("LinkLabel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["active-calls-container", { 'no-content': !$setup.activeCalls.size }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_mdicon, {
          name: "close",
          size: 20,
          onClick: $setup.closeActiveCalls
        }, null, 8, ["onClick"])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activeCalls, ([phoneNr, activeCall]) => {
        return (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (activeCall.organization)
                ? (_openBlock(), _createBlock(_component_LinkLabel, {
                    key: 0,
                    type: "organization",
                    value: activeCall.organization.code,
                    id: activeCall.organization.id
                  }, null, 8, ["value", "id"]))
                : _createCommentVNode("", true),
              _createVNode(_component_mdicon, {
                name: "phone",
                size: 30
              }),
              _createElementVNode("p", null, _toDisplayString(phoneNr), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              (activeCall.organization?.language)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createElementVNode("p", null, _toDisplayString(activeCall.organization.language), 1)
                  ]))
                : _createCommentVNode("", true),
              (activeCall.person)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(activeCall.person.firstname.charAt(0) + activeCall.person.lastname.charAt(0)), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(activeCall.tasks, (task) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_mdicon, {
                    name: $setup.getTaskIcon(task.category)
                  }, null, 8, ["name"]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("p", null, _toDisplayString(task.subject), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_13, [
                  (task.priority !== 'NONE')
                    ? (_openBlock(), _createBlock(_component_mdicon, {
                        key: 0,
                        name: "flagVariant",
                        class: _normalizeClass(task.priority)
                      }, null, 8, ["class"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("p", null, _toDisplayString($setup.toDateFormat(new Date(task.plannedDate), "dd/MM/y")), 1)
                ])
              ]))
            }), 256))
          ])
        ]))
      }), 256))
    ])
  ], 2))
}