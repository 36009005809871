import { createStore } from "vuex"
import UserState from "@/store/modules/UserState"
import VuexPersistence from "vuex-persist"
import UIState from "@/store/modules/UIState"
import HistoryState from "@/store/modules/HistoryState"
import DataTableState from "./modules/DataTableState"
import WeekviewEmployeesState from "./modules/WeekviewEmployeesState"
import SalesState from "./modules/SalesState"
import UserGroupServiceState from "./modules/UserGroupServiceState"
import EmployeeServiceState from "@/store/modules/EmployeeServiceState"
import TaskLabelServiceState from "@/store/modules/TaskLabelServiceState"

export default createStore({
  modules: {
    userState: UserState,
    uiState: UIState,
    historyState: HistoryState,
    dataTableState: DataTableState,
    weekviewEmployeeState: WeekviewEmployeesState,
    salesState: SalesState,
    userGroupServiceState: UserGroupServiceState,
    employeeServiceState: EmployeeServiceState,
    taskLabelState: TaskLabelServiceState,
  },
  plugins: [new VuexPersistence().plugin], // Make the stores persistent
})
