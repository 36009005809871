
import useVuelidate from "@vuelidate/core"
import { defineComponent } from "vue"

export default defineComponent({
  name: "ColorAdjustmentsValue",
  props: {
    additionalFields: <any>{ contrast: Number, brightness: Number },
  },
  setup(props) {
    const additionalRules = { contrast: {}, brightness: {} }

    const v$ = useVuelidate(additionalRules, props.additionalFields)

    // function decreaseContrast() {
    //     props.additionalFields.contrast = (props.additionalFields.contrast - 0.01);
    //
    //     if (props.additionalFields.contrast < -1) {
    //         props.additionalFields.contrast += 1;
    //     }
    // }
    //
    // function increaseContrast() {
    //     props.additionalFields.contrast = (props.additionalFields.contrast + 0.01)
    //
    //     if (props.additionalFields.contrast >= 1) {
    //         props.additionalFields.contrast -= 1;
    //     }
    // }

    return {
      v$,
    }
  },
})
