
import { reactive, defineComponent } from "vue"
import { RouterLink, useRouter } from "vue-router"

export default defineComponent({
  props: {
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    companyCode: {
      type: String,
      default: null,
    },
    clickAble: {
      type: Boolean,
      default: true,
    },
    hoverMessage: {
      type: String,
      default: "",
    },
  },
  setup(props: any) {
    const router = useRouter()

    const config: any = reactive({
      organization: {
        icon: "domain",
        class: "org-link",
        to: { name: "organization", params: { organization_id: props.id } },
      },
      user: {
        icon: "account",
        class: "user-link",
        to: { name: "userProfile", params: { user_id: props.id } },
      },
      computer: {
        icon: "cellphoneLink",
        class: "computer-link",
        to: { name: "computerProfile", params: { computer_id: props.id } },
      },
    })

    function onLinkClick(event: any) {
      event.stopPropagation()
    }

    return {
      config,
      onLinkClick,
    }
  },
})
