export default function usePhoneUtils() {
  function formatPhone(phoneNumber: string) {
    let formatted = phoneNumber
    if (formatted.includes("+")) {
      formatted = formatted.replace("+", "00")
    }
    if (formatted.includes(" ")) {
      formatted = formatted.replace(" ", "00")
    }
    return formatted
  }

  return {
    formatPhone,
  }
}
