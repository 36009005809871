<template>
  <Dropdown
    v-model="modelValue"
    :options="options"
    :show-clear="true"
    placeholder="Any leg"
    @change="$emit('update:modelValue', modelValue)"
  />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const options = ["LF", "RF", "LH", "RH"]

    return {
      options,
    }
  },
}
</script>
