
import ToolkitTag from "@/components/releases/toolkit/ToolkitTag.vue"
import ToolkitAutoComplete from "@/components/releases/toolkit/ToolkitAutoComplete.vue"
import ComputerService from "@/services/ComputerService"
import Button from "primevue/button"
import OverlayPanel from "primevue/overlaypanel"
import { computed, ref } from "vue"

export default {
  name: "ComputerToolkitVersion",
  components: { ToolkitTag, ToolkitAutoComplete },
  props: {
    computer: {
      type: Object,
      required: true,
    },
    filteringEnabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["value-changed", "show-filter-context-menu"],
  setup(props: any, { emit }: any) {
    const editOverlayComponent = ref()
    const computerService = new ComputerService()
    const toolkitTargetVersion = ref()
    const sameVersions = computed(() => {
      if (props.computer.toolkitVersion && props.computer.toolkitTargetVersion) {
        return props.computer.toolkitVersion.version === props.computer.toolkitTargetVersion.version
      } else {
        return false
      }
    })

    function onEdit(event: any) {
      editOverlayComponent.value.toggle(event)
      event.stopPropagation()
    }

    function onCancelEdit() {
      editOverlayComponent.value.toggle()
    }

    function onConfirmEdit() {
      computerService
        .setTargetToolkitVersion(toolkitTargetVersion.value.version, [props.computer.id])
        .then(() => {
          editOverlayComponent.value.toggle()
          emit("value-changed")
        })
    }

    function showFilterContextMenu(event: any, version: string, versionType: string) {
      if (props.filteringEnabled) {
        emit("show-filter-context-menu", event, versionType, version)
      }
    }

    return {
      onEdit,
      editOverlayComponent,
      onCancelEdit,
      onConfirmEdit,
      toolkitTargetVersion,
      sameVersions,
      showFilterContextMenu,
    }
  },
}
