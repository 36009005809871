<template>
  <mdicon
    :class="status.value"
    :size="16"
    class="absolute icon"
  />
</template>

<script>
import useArrayUtils from "@/utils/array"
import useReleaseUtils from "@/utils/releases"

export default {
  name: "RunConfigurationStatusBadge",
  props: {
    status: Object,
  },
  setup(props) {
    const { getOptionByValue } = useArrayUtils()
    const { releaseStatusOptions } = useReleaseUtils()
    const stableIconName = getOptionByValue(releaseStatusOptions, "OK").icon
    const unstableIconName = getOptionByValue(releaseStatusOptions, "NOK").icon
  },
}
</script>

<style scoped>
.icon {
  right: -8px;
  bottom: -5px;
  background-color: white;
  border-radius: 10px;
}

.OK .icon {
  color: #689f38;
}

.NOK .icon {
  color: rgb(211, 47, 47);
}

.UNKNOWN .icon {
  color: #727272;
}

.TESTING .icon {
  color: rgb(67, 124, 255);
}
</style>
