import axios from "axios"
import Service from "./Service"

export default class ChangeCompanyService extends Service {
  protected readonly endpoint: string

  public constructor(endpoint: string) {
    super()
    this.endpoint = endpoint
  }

  changeCompany(id: string, companyId: string) {
    const url: string = this.endpoint + "/" + id + "/company?companyId=" + companyId
    return axios.post(url)
  }
}
