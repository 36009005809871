
import { debounce } from "@/helpers/Debounce"
import { getRandomNumberBetween } from "@/helpers/GetRandomNumberBetween"
import ContactService from "@/services/ContactService"
import { state } from "@/utils/state"
import { computed, ref, watch } from "vue"
import { useRouter } from "vue-router"

export default {
  name: "EmployeeCallLabel",
  props: {
    employee: Object,
    phoneStatus: Object,
  },
  setup(props: any) {
    const incomingOrganizationContact = ref(null)
    const contactService = new ContactService()
    const router = useRouter()

    watch(
      () => props.phoneStatus?.status,
      (newValue, oldValue) => {
        if (newValue === "RINGING" || newValue === "CALLING") {
          if (
            !incomingOrganizationContact.value ||
            incomingOrganizationContact.value?.value !== props.phoneStatus.phoneNumber
          ) {
            getContactByPhoneNumber()
          }
        } else {
          incomingOrganizationContact.value = null
        }
      }
    )

    function getContactByPhoneNumber() {
      const getContact: Function = () => {
        setTimeout(() => {
          if (props.phoneStatus?.phoneNumber) {
            contactService.getContactByPhone(props.phoneStatus.phoneNumber).then((result: any) => {
              incomingOrganizationContact.value = result
            })
          } else {
            incomingOrganizationContact.value = null
          }
        }, getRandomNumberBetween(0, 3000))
      }

      debounce(getContact(), 3000)
    }

    function goToOrg(orgId: string) {
      if (orgId) {
        router.push({ name: "organization", params: { organization_id: orgId } })
      }
    }

    return {
      state,
      incomingOrganizationContact,
      goToOrg,
    }
  },
}
