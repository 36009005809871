import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8332c7b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs-container" }
const _hoisted_2 = { class: "tabs" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "baseline"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (tab) => {
        return (_openBlock(), _createElementBlock("div", _mergeProps({
          class: ["tab-container", { selected: tab.selected }],
          onClick: ($event: any) => ($setup.routeTo(tab))
        }, tab.attributes), _toDisplayString(tab.label), 17, _hoisted_3))
      }), 256))
    ]),
    ($props.baseline)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}