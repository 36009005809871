import EmployeeService from "@/services/EmployeeService"
import User from "@/models/User"

export default {
  state: () =>
    <any>{
      user: null as User,
      userId: null,
      username: null,
      refreshToken: null,
      token: null,
      roles: null,
      initials: null,
      defaultCompanyId: null,
    },

  mutations: {
    updateUserData(state: any, responseData: any) {
      state.userId = responseData.userId
      state.username = responseData.username
      state.roles = responseData.roles
      state.defaultCompanyId = responseData.defaultCompanyId
    },

    updateRefreshToken(state: any, refreshToken: string) {
      state.refreshToken = refreshToken
    },

    updateToken(state: any, token: string) {
      state.token = token
    },

    updateUser(state: any, user: User) {
      state.user = user
    },

    clearUserData(state: any) {
      state.userId = null
      state.username = null
      state.roles = null
      state.user = null
    },

    clearToken(state: any) {
      state.token = null
    },

    clearRefreshToken(state: any) {
      state.refreshToken = null
    },

    updateInitials(state: any, initials: string) {
      state.initials = initials
    },
  },

  /* Business logic (controllers) */
  actions: {
    logIn(context: any, response: any) {
      context.commit("updateUserData", response)
      context.commit("updateToken", response.token)
      context.commit("updateRefreshToken", response.refreshToken)

       new EmployeeService().get(context.getters.getUserId).then(res => {
        context.commit("updateUser", res.data)
       })
    },

    logOut(context: any) {
      context.commit("clearUserData")
      context.commit("clearToken")
      context.commit("clearRefreshToken")      
    },

    refreshTokens(context: any, response: any) {
      context.commit("updateToken", response.token)
      context.commit("updateRefreshToken", response.refreshToken)
    },

    setInitials(context: any, initials: string) {
      context.commit("updateInitials", initials)
    },
  },

  getters: {
    getUserId(state: any) {
      return state.userId
    },

    getUser(state: any) {
      return state.user
    },

    getFullName(state: any) {
      return state.username
    },

    getDefaultCompanyId(state: any) {
      return state.defaultCompanyId
    },

    getIsLoggedIn(state: any) {
      return state.token != null
    },

    getToken(state: any) {
      return state.token
    },

    getRoles(state: any) {
      return state.roles
    },

    getRefreshToken(state: any) {
      return state.refreshToken
    },

    getUserInitials(state: any) {
      return state.initials
    },
  },
}
