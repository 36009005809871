import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RilutRuleValue = _resolveComponent("RilutRuleValue")!
  const _component_RenderInstructionRuleForm = _resolveComponent("RenderInstructionRuleForm")!

  return (_openBlock(), _createBlock(_component_RenderInstructionRuleForm, {
    ref: "form",
    service: $setup.service,
    organization: $props.organization,
    onCreate: $setup.onCreate,
    onEdit: $setup.onEdit,
    onRetrieved: $setup.onRetrieve,
    onClosedialogs: $setup.closeDialogs,
    selectedRule: $props.selectedRule,
    add: $props.add,
    edit: $props.edit,
    duplicate: $props.duplicate
  }, {
    default: _withCtx(() => [
      _createVNode(_component_RilutRuleValue, { additionalFields: $setup.additionalFields }, null, 8, ["additionalFields"])
    ]),
    _: 1
  }, 8, ["service", "organization", "onCreate", "onEdit", "onRetrieved", "onClosedialogs", "selectedRule", "add", "edit", "duplicate"]))
}