export default function useArrayUtils() {
  function getOptionByValue(options: any, value: string, defaultValue: any = null) {
    if (value == null) return defaultValue

    for (let option of options) {
      if (option.value == value) {
        return option
      }
    }

    return defaultValue
  }

  function intersectionById(arrayA: any[], arrayB: any[]) {
    return arrayA.filter((object) => arrayB.map((item) => item.id).includes(object.id))
  }

  return {
    getOptionByValue,
    intersectionById,
  }
}
