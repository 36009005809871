
import useVuelidate from "@vuelidate/core"
import LutFileAutocomplete from "./LutFileAutocomplete.vue"

export default {
  props: {
    additionalFields: { lutFile: "", alias: "" },
  },
  setup(props: any) {
    const additionalRules = { lutFile: {}, alias: {} }
    const v$ = useVuelidate(additionalRules, props.additionalFields)
    return { v$ }
  },
  components: { LutFileAutocomplete },
}
