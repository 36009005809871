export default {
  state: () =>
    <any>{
      computerColumns: [],
      orgSalesColumns: [],
    },

  mutations: {
    replaceComputerColumns(state: any, computerColumns: any) {
      Object.assign(state.computerColumns, computerColumns)
    },
    replaceOrgSalesColumns(state: any, orgSalesColumns: any) {
      Object.assign(state.orgSalesColumns, orgSalesColumns)
    },
  },

  actions: {
    setComputerColumns(context: any, computerColumns: any) {
      context.commit("replaceComputerColumns", computerColumns)
    },
    setOrgSalesColumns(context: any, orgSalesColumns: any) {
      context.commit("replaceOrgSalesColumns", orgSalesColumns)
    },
  },

  getters: {
    getComputerColumns(state: any) {
      return state.computerColumns
    },
    getOrgSalesColumns(state: any) {
      return state.orgSalesColumns
    },
  },
}
