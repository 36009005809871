<template>
  <div class="mb-1 mt-2">
    <StatureDropdown v-model="selectedStature" />
    <SpeciesDropdown
      v-model="selectedSpecies"
      class="mr-1"
    />
    <AnatomyDropdown
      v-model="selectedAnatomy"
      class="mr-1"
    />
    <DirectionDropdown
      v-model="selectedDirection"
      class="mr-1"
    />
    <LegDropdown v-model="selectedLeg" />
  </div>
</template>

<script>
import SpeciesDropdown from "@/components/settings/render-instructions/SpeciesDropdown.vue"
import AnatomyDropdown from "@/components/settings/render-instructions/AnatomyDropdown.vue"
import DirectionDropdown from "@/components/settings/render-instructions/DirectionDropdown.vue"
import LegDropdown from "@/components/settings/render-instructions/LegDropdown.vue"
import { watch } from "vue"
import StatureDropdown from "@/components/settings/render-instructions/StatureDropdown"

export default {
  name: "RenderInstructionsMainFilter",
  components: {
    StatureDropdown,
    SpeciesDropdown,
    AnatomyDropdown,
    DirectionDropdown,
    LegDropdown,
  },
  props: {
    selectedAnatomy: {
      type: String,
      default: null,
    },
    selectedDirection: {
      type: String,
      default: null,
    },
    selectedLeg: {
      type: String,
      default: null,
    },
    selectedSpecies: {
      type: String,
      default: null,
    },
    selectedStature: {
      type: String,
      default: null,
    },
  },
  emits: [
    "update:selectedSpecies",
    "update:selectedAnatomy",
    "update:selectedDirection",
    "update:selectedLeg",
    "update:selectedStature",
  ],
  setup(props, { emit }) {
    watch(
      () => props.selectedSpecies,
      (newValue, oldValue) => {
        emit("update:selectedSpecies", newValue)
      }
    )
    watch(
      () => props.selectedAnatomy,
      (newValue, oldValue) => {
        emit("update:selectedAnatomy", newValue)
      }
    )
    watch(
      () => props.selectedDirection,
      (newValue, oldValue) => {
        emit("update:selectedDirection", newValue)
      }
    )
    watch(
      () => props.selectedLeg,
      (newValue, oldValue) => {
        emit("update:selectedLeg", newValue)
      }
    )
    watch(
      () => props.selectedStature,
      (newValue, oldValue) => {
        emit("update:selectedStature", newValue)
      }
    )
  },
}
</script>
