
import FileUpload from "primevue/fileupload"
import ReportClosingItem from "@/components/reports/tools/ReportClosingItem.vue"
import { ref, watch, defineComponent, computed } from "vue"
import ReportService from "@/services/ReportService"
import { useRoute } from "vue-router"
import OrganizationService from "@/services/OrganizationService"
import LinkLabel from "@/components/navigation/LinkLabel.vue"
import { state } from "@/utils/state"
import PrimaryButton from "@/components/buttons/PrimaryButton.vue"
import useTemplateUtils from "@/utils/template"

export default defineComponent({
  name: "ReportClosingList",
  components: { ReportClosingItem, FileUpload, LinkLabel, PrimaryButton },
  props: {
    organization: {
      type: String,
      default: null,
    },
    language: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  emits: ["default-checked"],
  setup(props, { emit }) {
    const selectedUser = ref(null)
    const reportService = new ReportService()
    const loading = ref(false)
    const imageList = ref([])
    const imageAlias = ref("")
    const internalAlias = ref("")
    const isDefault = ref(false)
    const addDialog = ref(false)
    const allLanguages = ref(true)
    const { postReportTemplatePart } = useTemplateUtils()

    watch(
      () => props.language,
      () => {
        resetDropdown()
        loadSignatures()
      }
    )
    watch(
      () => selectedUser.value,
      () => {
        if (selectedUser.value == null) {
          loadSignatures()
        } else {
          loadSignaturesForUser()
        }
      }
    )

    if (props.language) {
      loadSignatures()
    }

    // function checkDefault() {
    //   confirm.require({
    //     message: "Do you want to enable default mode ?",
    //     header: "Default",
    //     accept: () => {
    //       isDefault.value = true
    //     },
    //     reject: () => {
    //       isDefault.value = false
    //     },
    //   })
    // }

    function loadSignatures() {
      loading.value = true
      if (props.organization) {
        reportService
          .getReportTemplatesByType(
            props.type as string,
            props.language as string,
            props.organization as string
          )
          .then((result) => {
            if (result.data.length > 0) {
              imageList.value = result.data.sort((a: any, b: any) =>
                new Date(a.createdTime) > new Date(b.createdTime) ? -1 : 1
              )
            } else {
              imageList.value = []
            }
            loading.value = false
          })
      }
    }

    function loadSignaturesForUser() {
      if (props.organization) {
        reportService
          .getReportTemplatesByTypeForOrganizationUser(
            props.type as string,
            props.language as string,
            props.organization as string,
            selectedUser.value.id
          )
          .then((result) => {
            if (result.data.length > 0) {
              imageList.value = result.data.sort((a: any, b: any) =>
                new Date(a.createdTime) > new Date(b.createdTime) ? -1 : 1
              )
            } else {
              imageList.value = []
            }
            loading.value = false
          })
      }
    }

    const route = useRoute()
    const organizationService = OrganizationService.getInstance()
    const organizationId = computed(() => route.params.organization_id as string)
    const organizationUsers = ref([])
    loadOrganizationUsers()

    function loadOrganizationUsers() {
      if (organizationId) {
        organizationService.getUsersForOrganization(organizationId.value).then((result) => {
          let rows = result.rows
          for (let row of rows) {
            organizationUsers.value.push(row.user)
          }
        })
      }
    }

    async function chooseTemplateClosing(event: any) {
      if (event) {
        await postReportTemplatePart(
          imageAlias.value,
          internalAlias.value,
          props.type as string,
          allLanguages.value ? "" : (props.language as string),
          isDefault.value ? null : (props.organization as string),
          isDefault.value ? null : selectedUser?.value?.id,
          event.files[0],
          selectedUser?.value?.id ? loadSignaturesForUser : loadSignatures,
          addDialog,
          imageAlias,
          internalAlias,
          resetDropdown
        )
      }
    }

    function resetDropdown() {
      selectedUser.value = null
    }

    return {
      imageList,
      loading,
      loadSignatures,
      imageAlias,
      chooseTemplateClosing,
      internalAlias,
      isDefault,
      resetDropdown,
      selectedUser,
      organizationUsers,
      addDialog,
      state,
      allLanguages,
    }
  },
})
