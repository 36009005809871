export default {
  state: () =>
    <any>{
      saleColumns: [],
      saleFilters: null,
      saleSorting: null,
    },

  mutations: {
    replaceSaleColumns(state: any, saleColumns: any) {
      Object.assign(state.saleColumns, saleColumns)
    },

    replaceSaleFilters(state: any, saleFilters: any) {
      state.saleFilters = saleFilters
    },

    replaceSaleSorting(state: any, saleSorting: any) {
      state.saleSorting = saleSorting
    },
  },

  actions: {
    setSaleColumns(context: any, saleColumns: any) {
      context.commit("replaceSaleColumns", saleColumns)
    },

    setSaleFilters(context: any, saleFilters: any) {
      context.commit("replaceSaleFilters", saleFilters)
    },

    setSaleSorting(context: any, saleSorting: any) {
      context.commit("replaceSaleSorting", saleSorting)
    },
  },

  getters: {
    getSaleColumns(state: any) {
      return state.saleColumns
    },

    getSaleFilters(state: any) {
      return state.saleFilters
    },

    getSaleSorting(state: any) {
      return state.saleSorting
    },
  },
}
