import axios from "axios"
import ResultSet from "@/models/ResultSet"
import { useStore } from "vuex"
import Employee from "@/models/Employee"

export default class EmployeeService {
  pageSize: number = 20
  store: any

  constructor() {
    this.store = useStore()
  }

  async getAll(
    companyId: string,
    part: Number = 0,
    sort: any,
    filter: string,
    pageSize?: number
  ): Promise<ResultSet<Employee>> {
    let oThis = this
    let url =
      "users?size=" +
      (pageSize ? pageSize : this.pageSize) +
      "&page=" +
      part +
      "&sort=" +
      sort +
      "&filter=" +
      filter
    if (companyId) {
      url += "&companyId=" + companyId
    }

    if (!this.store.getters.hasEmployeesData(url)) {
      const res = await axios.get(url)
      await this.store.commit("setEmployees", {
        url,
        data: new ResultSet<any>(res.data, this.pageSize),
      })
    }
    return this.store.getters.getEmployeesData(url)
  }

  get(userID: string) {
    return axios.get("users/" + userID)
  }

  add(userData: any, companyId: string, userId: string, type: string) {
    return axios
      .post("users?companyId=" + companyId + "&userId=" + userId + "&type=" + type, userData)
      .then((res) => res.data)
  }

  changePassword(password: string) {
    return axios
      .post(
        "users/password",
        { password: password },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((res) => res.data)
  }

  update(userID: string, userData: any) {
    delete userData.password

    return axios.put("users/" + userID, userData)
  }

  delete(userID: string) {
    return axios.delete("users/" + userID)
  }

  deleteMultiple(ids: string[]) {
    let url = "users?cascade=false"

    for (let id of ids) {
      url += "&ids=" + id
    }

    return axios.delete(url).then((res) => res.data)
  }
}
