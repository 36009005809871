import axios from "axios"
import PageableResult from "@/models/PageableResult"
import Service from "@/services/Service"

export default class ReportService extends Service {
  getTemplates(language: string, organizationId: string) {
    return axios.get(
      "reporttemplates/support?language=" +
        language +
        "&organizationId=" +
        organizationId +
        // + '&computerId=' + computerId + '&userId=' + userId
        "&size=" +
        100,
      { baseURL: process.env.VUE_APP_VSOL_API }
    )
  }

  getTemplateForUserFromOrganization(language: string, organizationId: string, userId: string) {
    return axios.get(
      "reporttemplates/support?language=" +
        language +
        "&organizationId=" +
        organizationId +
        "&userId=" +
        userId +
        "&size=" +
        100,
      { baseURL: process.env.VUE_APP_VSOL_API }
    )
  }

  getReportTemplate(id: string) {
    return axios.get("reporttemplates/" + id, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  getTemplateByType(templateId: string) {
    return axios.get("templates/" + templateId, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  getReportTemplatesByType(type: string, language: string, organizationId: string) {
    return axios.get(
      "templates/support?type=" +
        type +
        "&language=" +
        language +
        "&organizationId=" +
        organizationId +
        "&size=" +
        100 +
        "&part=" +
        0 +
        "&sort=" +
        "organization",
      { baseURL: process.env.VUE_APP_VSOL_API }
    )
  }

  getReportTemplatesByTypeForOrganizationUser(
    type: string,
    language: string,
    organizationId: string,
    userId: string
  ) {
    return axios.get(
      "templates/support?type=" +
        type +
        "&language=" +
        language +
        "&organizationId=" +
        organizationId +
        "&userId=" +
        userId,
      { baseURL: process.env.VUE_APP_VSOL_API }
    )
  }

  addTemplate(
    alias: string,
    internalAlias: string,
    title: string,
    language: string,
    contendId: string,
    contentHeaderLeftId: string,
    contentHeaderRightId: string,
    headerId: string,
    footerId: string,
    termsAndConditionsId: string,
    organizationId: string
  ) {
    return axios.post(
      "reporttemplates/support?alias=" +
        alias +
        "&internalAlias=" +
        internalAlias +
        "&title=" +
        title +
        "&language=" +
        language +
        "&contentId=" +
        contendId +
        "&contentHeaderLeftId=" +
        contentHeaderLeftId +
        "&contentHeaderRightId=" +
        contentHeaderRightId +
        "&headerId=" +
        headerId +
        "&footerId=" +
        footerId +
        "&termsAndConditionsId=" +
        termsAndConditionsId +
        "&organizationId=" +
        organizationId,
      null,
      { baseURL: process.env.VUE_APP_VSOL_API }
    )
  }

  addTemplateForOrganizationUser(
    alias: string,
    internalAlias: string,
    title: string,
    language: string,
    contendId: string,
    contentHeaderLeftId: string,
    contentHeaderRightId: string,
    headerId: string,
    footerId: string,
    termsAndConditionsId: string,
    organizationId: string,
    userId: string
  ) {
    return axios.post(
      "reporttemplates/support?alias=" +
        alias +
        "&internalAlias=" +
        internalAlias +
        "&title=" +
        title +
        "&language=" +
        language +
        "&contentId=" +
        contendId +
        "&contentHeaderLeftId=" +
        contentHeaderLeftId +
        "&contentHeaderRightId=" +
        contentHeaderRightId +
        "&headerId=" +
        headerId +
        "&footerId=" +
        footerId +
        "&termsAndConditionsId=" +
        termsAndConditionsId +
        "&organizationId=" +
        organizationId +
        "&userId=" +
        userId,
      null,
      { baseURL: process.env.VUE_APP_VSOL_API }
    )
  }

  copyTemplatesFromOrganization(organizationId: string, templates: any) {
    return axios.post("reporttemplates/copy/support?organizationId=" + organizationId, templates, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  updateReportTemplate(
    id: string,
    alias: string,
    internalAlias: string,
    title: string,
    language: string,
    contentId: string,
    contentHeaderLeftId: string,
    contentHeaderRightId: string,
    headerId: string,
    footerId: string,
    termsAndConditionsId: string
  ) {
    return axios.put(
      "reporttemplates/" +
        id +
        "/templates?alias=" +
        alias +
        "&internalAlias=" +
        internalAlias +
        "&title=" +
        title +
        "&language=" +
        language +
        "&contentId=" +
        contentId +
        "&contentHeaderLeftId=" +
        contentHeaderLeftId +
        "&contentHeaderRightId=" +
        contentHeaderRightId +
        "&headerId=" +
        headerId +
        "&footerId=" +
        footerId +
        "&termsAndConditionsId=" +
        termsAndConditionsId,
      null,
      { baseURL: process.env.VUE_APP_VSOL_API }
    )
  }

  addDefaultTemplateType(
    alias: string,
    internalAlias: string,
    type: string,
    language: string,
    templateData: any
  ) {
    let url = "templates/support?alias=" + alias
    if (internalAlias !== null) {
      url += "&internalAlias=" + internalAlias
    }
    url += "&type=" + type + "&language=" + language

    return axios.post(url, templateData, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  async addTemplateType(
    alias: string,
    internalAlias: string,
    type: string,
    language: string,
    organizationId: string,
    templateData: string | Blob,
    userId?: string
  ) {
    let urlSearchParams = new URLSearchParams()
    urlSearchParams.append("alias", alias)
    urlSearchParams.append("type", type)
    urlSearchParams.append("language", language)

    if (internalAlias !== null) urlSearchParams.append("internalAlias", internalAlias)
    if (organizationId) urlSearchParams.append("organizationId", organizationId)
    if (userId) urlSearchParams.append("userId", userId)

    const url = "templates/support?" + urlSearchParams.toString()

    const form = new FormData()

    if (typeof templateData === "string") {
      form.append("part", new Blob([templateData], { type: "text/plain" }))
    } else {
      form.append("part", new Blob([templateData], { type: templateData.type }))
    }

    return await axios.post(url, form, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  readReportTemplate(id: string) {
    return axios.get("templates/" + id + "/html", { baseURL: process.env.VUE_APP_VSOL_API })
  }

  updateTemplateData(templateID: string, templateData: any) {
    return axios.put("templates/" + templateID, templateData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  updateTemplateHTML(templateID: string, templateHTML: string | Blob) {
    const form = new FormData()

    if (typeof templateHTML === "string") {
      form.append("part", new Blob([templateHTML], { type: "text/plain" }))
    } else {
      form.append("part", new Blob([templateHTML], { type: templateHTML.type }))
    }
    return axios.put("templates/" + templateID + "/template", form, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  deleteTemplate(templateID: string) {
    return axios.delete("templates/" + templateID, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  deleteReportTemplate(reportTemplateId: string) {
    return axios.delete("reporttemplates/" + reportTemplateId, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }
}
