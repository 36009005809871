
import ComputerService from "@/services/ComputerService"
import Button from "primevue/button"
import SplitButton from "primevue/splitbutton"
import { computed, defineComponent, ref } from "vue"

export default defineComponent({
  name: "TeamViewerButton",
  props: {
    computerId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const computer = ref(null)
    const computerService = new ComputerService()
    const anyDeskItem = computed(() => [
      {
        label: computer.value ? computer.value.anyDeskClientId : null,
        icon: "pi pi-copy",
        command: () => {
          copyAnyDeskId()
        },
      },
    ])

    loadComputer()

    function loadComputer() {
      computerService.getComputer(props.computerId as string).then((response) => {
        computer.value = response.data
      })
    }

    function copyAnyDeskId() {
      navigator.clipboard.writeText(computer.value.anyDeskClientId)
    }

    function onClickAnyDesk() {
      window.open("anydesk:" + computer.value.anyDeskClientId, "_blank")
    }

    return {
      computer,
      anyDeskItem,
      onClickAnyDesk,
    }
  },
})
