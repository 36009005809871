import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-483807dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "cursor-pointer panelContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationPanelMenuItem = _resolveComponent("NavigationPanelMenuItem")!

  return ($props.menuItems)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.menuItems, (item) => {
          return (_openBlock(), _createElementBlock("div", null, [
            _createVNode(_component_NavigationPanelMenuItem, { item: item }, null, 8, ["item"])
          ]))
        }), 256))
      ]))
    : _createCommentVNode("", true)
}