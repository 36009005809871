export default {
  state: () =>
    <any>{
      selectedEmployeeIds: [],
    },

  mutations: {
    addSelectedEmployee(state: any, employeeId: string) {
      if (!state.selectedEmployeeIds.includes(employeeId)) {
        state.selectedEmployeeIds.push(employeeId)
      }
    },
    addAllEmployees(state: any, employeeIds: string[]) {
      state.selectedEmployeeIds.length = 0
      state.selectedEmployeeIds = employeeIds
    },
    deleteSelectedEmployee(state: any, employeeId: string) {
      const foundIndex = state.selectedEmployeeIds.findIndex(
        (_employeeId: string) => _employeeId === employeeId
      )
      if (foundIndex !== -1) {
        state.selectedEmployeeIds.splice(foundIndex, 1)
      }
    },
    deleteAllEmployees(state: any) {
      state.selectedEmployeeIds.length = 0
    },
  },

  actions: {
    selectEmployee(context: any, employeeId: string) {
      context.commit("addSelectedEmployee", employeeId)
    },
    unselectEmployee(context: any, employeeId: string) {
      context.commit("deleteSelectedEmployee", employeeId)
    },
    selectAllEmployees(context: any, employeeIds: string[]) {
      context.commit("addAllEmployees", employeeIds)
    },
    unselectAllEmployees(context: any) {
      context.commit("deleteAllEmployees")
    },
  },

  getters: {
    getSelectedEmployeeIds(state: any) {
      return state.selectedEmployeeIds
    },
  },
}
