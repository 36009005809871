import axios from "axios"
import ResultSet from "@/models/ResultSet"

export default class RolesService {
  pageSize: number = 20

  getUserRoles(userID: string, part: Number = 0, sort: any, filter: string) {
    let oThis = this
    return axios
      .get(
        "users/" +
          userID +
          " /roles?size=" +
          this.pageSize +
          "&page=" +
          part +
          "&sort=" +
          sort +
          "&filter=" +
          filter,
        { baseURL: process.env.VUE_APP_VSOL_API }
      )
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  getOrganizationRoles(organizationID: string, part: Number = 0, sort: any, filter: string) {
    let oThis = this
    return axios
      .get(
        "organizations/" +
          organizationID +
          " /roles?size=" +
          this.pageSize +
          "&page=" +
          part +
          "&sort=" +
          sort +
          "&filter=" +
          filter,
        { baseURL: process.env.VUE_APP_VSOL_API }
      )
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  async addRole(organizationID: string, userId: string, type: string) {
    const res = await axios.post(
      "roles?organizationId=" + organizationID + "&userId=" + userId + "&type=" + type,
      {},
      { baseURL: process.env.VUE_APP_VSOL_API }
    )
    return res.data
  }

  async addCompanyRole(companyID: string, userId: string, type: string) {
    const res = await axios.post(
      "roles?companyId=" + companyID + "&userId=" + userId + "&type=" + type
    )
    return res.data
  }

  deleteRole(networkId: string) {
    return axios.delete("roles/" + networkId, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  getRoleOptions() {
    return axios.get("roles/options").then((res) => res.data)
  }

  addEmployeeRole(companyId: string, employeeId: string, role: string) {
    return axios
      .post("roles?companyId=" + companyId + "&userId=" + employeeId + "&type=" + role)
      .then((res) => res.data)
  }

  updateRole(employeeId: string, role: any) {
    return axios.put("roles/" + employeeId, role)
  }

  deleteEmployeeRole(employeeId: string) {
    return axios.delete("roles/" + employeeId)
  }
}
