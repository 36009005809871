import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createBlock(_component_AutoComplete, {
    modelValue: $setup.selectedOrganization,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedOrganization) = $event)),
    suggestions: $setup.filteredOrganizationOptions,
    placeholder: $props.placeholder,
    onItemSelect: $setup.onSelect,
    onComplete: _cache[1] || (_cache[1] = ($event: any) => ($setup.searchOrganization($event))),
    class: "switcher",
    "auto-highlight": true
  }, {
    item: _withCtx((slotProps) => [
      _createElementVNode("div", _hoisted_1, _toDisplayString($setup.organizationToString(slotProps.item)), 1)
    ]),
    _: 1
  }, 8, ["modelValue", "suggestions", "placeholder", "onItemSelect"]))
}