export default function useReleaseUtils() {
  const releaseStatusOptions = [
    {
      label: "The stability of this release is unknown. Please test this release first.",
      value: "UNKNOWN",
      icon: "ProgressQuestion",
      className: "UNKNOWN",
    },
    {
      label: "Needs testing",
      value: "TESTING",
      icon: "ProgressQuestion",
      className: "TESTING",
    },
    {
      label: "This release has proven to be stable.",
      value: "OK",
      icon: "checkboxMarkedCircle",
      className: "OK",
    },
    { label: "This release is not stable.", value: "NOK", icon: "minusCircle", className: "NOK" },
  ]

  const defaultReleaseStatus = releaseStatusOptions[0]

  return {
    releaseStatusOptions,
    defaultReleaseStatus,
  }
}
