
import { defineComponent, ref, watch } from "vue"
import NetworkCollectionRow from "./NetworkCollectionRow.vue"
import ConfirmPopup from "primevue/confirmpopup"
import Button from "primevue/button"
import TeamViewerButton from "./TeamViewerButton.vue"
import AnyDeskButton from "./AnyDeskButton.vue"
import LinkLabel from "../navigation/LinkLabel.vue"
import ComputerRunConfiguration from "@/components/computers/tools/ComputerRunConfiguration.vue"
import ComputerService from "@/services/ComputerService"
import PrimaryButton from "../buttons/PrimaryButton.vue"
import SecondaryButton from "../buttons/SecondaryButton.vue"
import TertiaryButton from "../buttons/TertiaryButton.vue"

export default defineComponent({
  components: {
    TeamViewerButton,
    AnyDeskButton,
    LinkLabel,
    ComputerRunConfiguration,
    PrimaryButton,
    SecondaryButton,
    TertiaryButton,
  },
  props: {
    organization: {
      type: Object,
      default: null,
    },
    computer: {
      type: Object,
      default: null,
    },
  },
  emits: ["value-changed", "computer-added"],
  setup(props: any, { emit }: any) {
    const items = ref([])
    const computerService = new ComputerService()
    const newComputerDialog = ref(false)
    const layout = ref("grid")
    const targetVersion = ref()
    const editVersionOverlayComponent = ref()

    function showRunConfigEditOverlay(event: any) {
      editVersionOverlayComponent.value.toggle(event)
    }

    function updateComputersOfOrganization() {
      computerService
        .setTargetRunConfiguration(
          targetVersion.value.version,
          items.value.map((item: any) => item.id)
        )
        .then(() => {
          editVersionOverlayComponent.value.toggle()
          reload()
        })
    }

    function cancelUpdateComputersOfOrganization() {
      editVersionOverlayComponent.value.toggle()
    }

    watch(
      () => props.organization,
      () => {
        reload()
      }
    )

    function reload() {
      items.value = []
      emit("computer-added")
      loadNetwork()
      if (newComputerDialog.value) newComputerDialog.value = false
    }

    loadNetwork()
    function loadNetwork() {
      items.value = props.organization.computers
    }

    return {
      items,
      reload,
      newComputerDialog,
      layout,
      targetVersion,
      editVersionOverlayComponent,
      updateComputersOfOrganization,
      cancelUpdateComputersOfOrganization,
      showRunConfigEditOverlay,
    }
  },
})
