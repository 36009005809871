import { Task } from "@/models/Task"
import axios from "axios"
import { state } from "@/utils/state"
import useTimeUtils from "@/utils/time"
import { ServerError } from "@/utils/server-error"
import isDevelopment from "@/utils/utils"
import TasksRequest from "@/components/tasks/types/TaskRequest"
import OverdueTasksRequest from "@/components/tasks/types/OverdueTasksRequest"

export default class TaskService {
  async getTasks(request: TasksRequest): Promise<Task[]> {
    let url: string = "tasks?size=100000&page=0" + request.toParameterList()

    try {
      const response = await axios.get(url)
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }

  getLatestTasksFromOrg(organizationId: string, size: number) {
    let url: string =
      "tasks?size=" +
      size.toString() +
      "&page=0&sort=plannedDate,asc&organizationId=" +
      organizationId +
      "&plannedFrom=" +
      useTimeUtils().toDateFormat(new Date(), "y-MM-dd")
    return axios.get(url).then((res) => res.data)
  }

  async getOverdueTasks(request: OverdueTasksRequest): Promise<Task[]> {
    const res = await axios.get("tasks/overdue" + request.toParameterList())
    return res.data
  }

  async getTaskById(taskId: string): Promise<Task> {
    const res = await axios.get("tasks/" + taskId)
    return res.data
  }

  async postTask(newTask: Task, organizationId: string, computerId: string) {
    let url: string = "tasks"

    if (organizationId) {
      url += (url.includes("?") ? "&" : "?") + "organizationId=" + organizationId
    }

    if (computerId) {
      url += (url.includes("?") ? "&" : "?") + "computerId=" + computerId
    }

    try {
      const response = await axios.post(url, newTask)
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }

  async editTask(taskId: string, task: any): Promise<Task> {
    try {
      const response = await axios.put("tasks/" + taskId, task)
      this.realtimePublish(task)
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }

  async uncompleteTask(taskId: string) {
    let url: string = `tasks/${taskId}/uncomplete`
    try {
      const response = await axios.post(url)
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }

  throwError = (error: any) => {
    if (error.response && isDevelopment()) {
      throw new ServerError(error.response.data.error, error.response.data.message)
    } else {
      throw new ServerError("Server error", "A server error occurred, please try again later.")
    }
  }

  async deleteTask(taskId: string) {
    let url: string = "tasks/" + taskId
    try {
      const response = await axios.delete(url)
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }

  async completeTask(taskId: string) {
    let url: string = "tasks/" + taskId + "/complete"
    try {
      const response = await axios.post(url)
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }

  async addLabelToTask(taskId: string, labelId: string) {
    let url = "tasks/" + taskId + "/label?labelId=" + labelId
    try {
      const response = await axios.post(url)
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }

  async deleteLabelFromTask(taskId: string, labelId: string) {
    let url = "tasks/" + taskId + "/label?labelId=" + labelId
    try {
      const response = await axios.delete(url)
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }

  postTaskWalterpBridge(taskId: string) {
    return axios.post("tasks/" + taskId + "/walterpBridge")
  }

  //REALTIME
  //PUBLISH
  realtimePublish(task: any) {
    const cachedSelected = task.selected
    state.realtime.socket.emit("publish", {
      room: "tasks",
      data: {
        [task.id]: {
          data: Object.assign(task, { selected: false }),
          putDate: new Date().getTime(),
        },
      },
    })
    task.selected = cachedSelected
  }

  async getSubTasks(taskId: string): Promise<Task[]> {
    const response = await axios.get("tasks/" + taskId + "/children")
    return response.data
  }

  addSubTask(subTaskId: string, parentTaskId: string, sequenceNumber?: Number) {
    return axios.post(
      "taskrelations?childId=" +
        subTaskId +
        "&parentId=" +
        parentTaskId +
        "&sequenceNumber=" +
        sequenceNumber
    )
  }

  async moveSubTask(
    subTaskId: string,
    parentTaskId: string,
    sequenceNumber: Number
  ): Promise<void> {
    try {
      const response = await axios.put(
        "taskrelations?childId=" +
          subTaskId +
          "&parentId=" +
          parentTaskId +
          "&sequenceNumber=" +
          sequenceNumber
      )
      return response.data
    } catch (error) {
      this.throwError(error)
    }
  }

  addTaskDependency(taskId: string, dependencyId: string, subTask: any) {
    return axios.post(
      "taskdependencies?taskId=" + taskId + "&dependencyId=" + dependencyId,
      subTask
    )
  }
}
