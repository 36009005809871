
import { defineComponent } from "vue"

export default {
  name: "DataCollectionListRow",
  props: {
    id: String,
    modelValue: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props: any, { emit }: any) {
    function toggleSelection(itemID: string, selected: boolean) {
      emit("selection-changed" as any, itemID, selected)
    }

    return { toggleSelection }
  },
}
