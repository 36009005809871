
import RenderInstructionRuleForm from "@/components/settings/render-instructions/RenderInstructionRuleForm.vue"
import RenderInstructionService from "@/services/RenderInstructionService"
import WindowingsValue from "@/components/settings/render-instructions/windowings/WindowingsValue.vue"
import { ref, reactive } from "vue"

export default {
  props: {
    organization: {
      type: Object,
      default: null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    duplicate: {
      type: Boolean,
      default: false,
    },
    selectedRule: {
      type: Object,
      default: null,
    },
  },
  name: "WindowingsForm",
  components: { RenderInstructionRuleForm, WindowingsValue },
  emits: ["closedialogs"],
  setup(props: any, { emit }: any) {
    const service = new RenderInstructionService("riwindowings")
    const additionalFields = reactive({ contrast: 0.0, brightness: 0.0 })
    const form = ref()

    function onCreate() {
      form.value.addRule(additionalFields)
    }

    function onEdit() {
      form.value.editRule(additionalFields)
    }

    function onRetrieve(rule: any) {
      additionalFields.contrast = rule.contrast
      additionalFields.brightness = rule.brightness
    }

    function closeDialogs() {
      emit("closedialogs")
    }

    return {
      additionalFields,
      service,
      form,
      onCreate,
      onEdit,
      onRetrieve,
      closeDialogs,
    }
  },
}
