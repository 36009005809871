<!--suppress XmlInvalidId -->
<template>
  <form @submit.prevent="onSubmit">
    <div class="formgroup-inline mt-3 mb-4 flex flex-column justify-content-center">
      <div v-if="!computer" class="field">
        <ComputerAutoComplete
          v-model="v$.computer.$model"
          :invalid="v$.computer.$error"
          placeholder="Add computer"
        />
        <inline-form-error v-if="v$.computer.$error" :errors="v$.computer.$errors" />
      </div>
      <div v-if="!organization" class="field">
        <OrganizationAutoComplete
          v-model="v$.organization.$model"
          :invalid="v$.organization.$error"
          placeholder="Add to organization"
        />
        <inline-form-error v-if="v$.organization.$error" :errors="v$.organization.$errors" />
      </div>
      <div class="field">
        <PrimaryButton :label="'Add'" />
      </div>
    </div>
  </form>
</template>

<script langs="ts">
import { ref } from "vue"
import { helpers, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import OrganizationService from "@/services/OrganizationService"

export default {
  props: {
    organization: {
      type: Object,
      default: null,
    },
    computer: {
      type: Object,
      default: null,
    },
    saved: {},
  },
  emits: ["added"],
  setup(props, { emit }) {
    const done = ref(false)
    const organizationService = OrganizationService.getInstance()

    const form = ref({
      computer: props.computer,
      organization: props.organization,
    })

    const rules = {
      computer: { required: helpers.withMessage("The computer is required", required) },
      organization: { required: helpers.withMessage("The organization is required", required) },
    }

    const v$ = useVuelidate(rules, form)

    function onSubmit() {
      v$.value.$touch()

      if (v$.value.$invalid) return

      organizationService
        .addComputerToOrganization(form.value.organization.id, form.value.computer.id)
        .then(() => {
          if (props.organization) form.value.computer = null
          if (props.computer) form.value.organization = null
          v$.value.$reset()
          emit("added", form.value)
        })
    }

    function onDone() {
      done.value = true
      setTimeout(() => {
        done.value = false
      }, 2500)
    }

    return {
      done,
      v$,
      onSubmit,
      onDone,
    }
  },
}
</script>
