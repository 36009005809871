
import { computed, ref, onMounted, onUnmounted, watch, reactive } from "vue"
import { state } from "@/utils/state"
import LinkLabel from "../navigation/LinkLabel.vue"
import TaskService from "@/services/TaskService"
import useTaskUtils from "@/utils/task"
import useTimeUtils from "@/utils/time"
import usePhoneUtils from "@/utils/phone"
import ContactService from "@/services/ContactService"

export default {
  components: { LinkLabel },
  setup() {
    const contactService = new ContactService()
    const taskService = new TaskService()
    const activeCalls = reactive(new Map())
    const { getTaskIcon } = useTaskUtils()
    const { toDateFormat } = useTimeUtils()
    const { formatPhone } = usePhoneUtils()
    const incomingCalls = computed(() =>
      Object.entries(state.phoneStatus).filter((phoneCall: any) => phoneCall[1].incoming === true)
    )

    /*onMounted(() => {
      window.addEventListener("keydown", (event) => {
        if (event.code === "F2") {
          pushCall(true, "RINGING", "+32494640058")
        }
        if (event.code === "F3") {
          pushCall(true, "RINGING", "+32494640059")
        }
        if (event.code === "F4") {
          pushCall(true, "IDLE", "+32494640058")
        }
      })
    })*/

    watch(
      () => incomingCalls.value,
      (newValue, oldValue) => {
        if (newValue.length) {
          //first filter out duplicate phonenumbers then loop over every unique incoming call
          newValue
            .filter(
              (incomingCall: any, index: number) =>
                newValue
                  .map((item: any) => item[1].phoneNumber)
                  .indexOf(incomingCall[1].phoneNumber) === index
            )
            .forEach((incomingCall: any) => {
              if (activeCalls.has(formatPhone(incomingCall[1].phoneNumber))) {
                if (incomingCall[1].status !== "RINGING") {
                  activeCalls.delete(formatPhone(incomingCall[1].phoneNumber))
                }
              } else {
                if (incomingCall[1].status === "RINGING") {
                  getIncomingCallDetails(incomingCall[0], incomingCall[1]).then((result: any) => {
                    activeCalls.set(formatPhone(incomingCall[1].phoneNumber), result)
                  })
                }
              }
            })
        } else {
          closeActiveCalls()
        }
      },
      { deep: true }
    )

    function getIncomingCallDetails(id: string, callObj: any) {
      return contactService
        .getContactByPhone(formatPhone(callObj.phoneNumber))
        .then((result: any) => {
          let activeCall: any = {
            id: id,
            status: callObj.status,
            phoneNumber: formatPhone(callObj.phoneNumber),
            startTime: callObj.startTime,
            incoming: callObj.incoming,
            details: null,
            organization: null,
            person: null,
            tasks: null,
          }
          if (result) {
            activeCall.details = result
            activeCall.organization = result.organization
            activeCall.person = result.person
          }
          if (result?.organization?.id) {
            return taskService
              .getLatestTasksFromOrg(result.organization.id, 3)
              .then((result: any) => {
                if (result && result.length) {
                  activeCall.tasks = result
                }
                return activeCall
              })
          } else {
            return activeCall
          }
        })
    }

    function closeActiveCalls() {
      activeCalls.clear()
    }

    /*function pushCall(incoming: boolean, status: string, phoneNumber: string) {
      let call = {
        "3a439cae-f454-430f-8c98-27c887610dbd": {
          status: status,
          phoneNumber: phoneNumber,
          startTime: "2023-07-13T15:20:42.922Z",
          incoming: incoming,
        },
        "3a439cae-f454-430f-8c98-27c887610dbc": {
          status: status,
          phoneNumber: phoneNumber,
          startTime: "2023-07-13T15:20:42.922Z",
          incoming: incoming,
        },
        "3a439cae-f454-430f-8c98-27c887610dba": {
          status: status,
          phoneNumber: phoneNumber,
          startTime: "2023-07-13T15:20:42.922Z",
          incoming: incoming,
        },
      }
      Object.assign(state.phoneStatus, call)
    }*/

    return {
      incomingCalls,
      //pushCall,
      activeCalls,
      getTaskIcon,
      toDateFormat,
      closeActiveCalls,
    }
  },
}
