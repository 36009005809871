
import {
  computed,
  defineComponent,
  nextTick,
  onActivated,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue"
import { useRoute, useRouter } from "vue-router"
import ResultSet from "@/models/ResultSet"

export default defineComponent({
  name: "DataCollection",
  props: {
    sortOptions: {
      type: Array,
      default: [],
    },
    sortDefault: {
      type: Object,
      default: null,
    },
    sortingRoute: {
      type: String,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    showList: {
      type: Boolean,
      default: true,
    },
    showGrid: {
      type: Boolean,
      default: true,
    },
    uniqueKey: {
      type: String,
      default: "id",
    },
    search: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: "Filter",
    },
  },
  emits: ["load-part"],
  setup(props, { emit }) {
    let part = 0
    const loadedAllData = ref(false)
    const route = useRoute()
    const router = useRouter()
    const layout = ref(props.showList ? "list" : "grid")
    const loadingPart = ref(false)
    const sortKey = ref(null)
    const scrollComponent = document.getElementsByClassName("p-dataview-content")
    const loadMoreComponent = ref()
    const selectedAll = ref(null)
    const allItems = ref([])
    const lastError = ref(null)
    const searchQuery = ref("")
    const filterOverlay = ref()
    const showFilter = ref(false)
    const filterField = ref()

    watch(
      () => searchQuery.value,
      (newQuery, oldQuery) => {
        reload()
      }
    )

    // Select the right sort option
    if (route.params.sortOptions) {
      // Get sort options from url
      sortKey.value = (props.sortOptions as any).filter(function (sortOption: any) {
        return sortOption.value === route.params.sortOptions
      })[0]
    } else if (props.sortDefault != null) {
      // Use the default instead
      sortKey.value = props.sortDefault
    }

    const loadPart = () => {
      if (!loadedAllData.value && !loadingPart.value) {
        loadingPart.value = true
        lastError.value = null

        if (sortKey.value == null) {
          emit("load-part", part, null, searchQuery.value)
        } else {
          emit("load-part", part, sortKey.value.value, searchQuery.value)
        }
      }
    }

    loadPart()

    function reload() {
      part = 0
      // selectedAll.value = null;
      loadedAllData.value = false
      allItems.value = []
      loadPart()
    }

    onMounted(() => {
      if (scrollComponent.length > 0) {
        scrollComponent[0].addEventListener("scroll", checkVisible, { passive: true })
      }

      window.addEventListener("resize", checkVisible, { passive: true })
    })

    onUnmounted(() => {
      if (scrollComponent.length > 0) {
        scrollComponent[0].removeEventListener("scroll", checkVisible)
      }

      window.removeEventListener("resize", checkVisible)
    })

    /* The loadMoreComponent will be swapped when switching views.
     * This is important since view could fit more items than the other view
     */
    watch(loadMoreComponent, (newValue, oldValue) => {
      checkVisible()
    })

    const checkVisible = () => {
      if (loadedAllData.value) return
      if (!loadMoreComponent.value) return

      let element = loadMoreComponent.value
      let container = element.offsetParent

      if (!container) return

      // Get container properties
      let cTop = container.scrollTop
      let cBottom = cTop + container.clientHeight

      // Get element properties
      let eTop = element.offsetTop
      let eBottom = eTop + element.clientHeight

      if (cBottom > eTop) {
        loadPart()
      }
    }

    function onPartLoaded(loadedPart: Number, sort: any, result: ResultSet<any>) {
      loadingPart.value = false
      loadedAllData.value = !result.moreToCome

      if (!result.rows || (result.rows && result.rows.length == 0)) {
        return
      }

      part++
      result.rows.map(function (element) {
        let item = { selected: false, data: element }

        if (selectedAll.value) {
          item.selected = true
        }

        allItems.value.push(item)
      })

      nextTick().then(() => {
        checkVisible()
      })
    }

    function onPartFailed(error: any) {
      loadingPart.value = false
      lastError.value = error
    }

    function onSortChange() {
      const _route = { name: props.sortingRoute, params: { sortOptions: sortKey.value.value } }
      router.push(_route as any)
      reload()
    }

    function onSelectAll() {
      if (selectedAll.value) {
        allItems.value.map((item) => {
          item.selected = true
        })
      } else {
        // null means
        allItems.value.map((item) => {
          item.selected = false
        })
        selectedAll.value = null
      }
    }

    watch(
      () => allItems.value,
      () => {
        if (allItems.value.length < 1) return

        if (allItems.value.filter((item) => !item.selected).length == 0) {
          selectedAll.value = true
        } else if (allItems.value.filter((item) => item.selected).length == 0) {
          selectedAll.value = null
        } else {
          selectedAll.value = false
        }
      },
      { deep: true }
    )

    const selectedItems = computed(() => allItems.value.filter((item) => item.selected))

    return {
      loadMoreComponent,
      layout,
      loadedAllData,
      loadingPart,
      sortKey,
      selectedAll,
      allItems,
      lastError,
      searchQuery,
      filterOverlay,
      showFilter,
      filterField,
      selectedItems,
      onSortChange,
      onSelectAll,
      onPartLoaded,
      onPartFailed,
      reload,
      loadPart,
    }
  },
})
