<!--suppress XmlInvalidId -->
<template>
  <div class="field col-12 md:col-6">
    <label for="username">Rotation: {{ v$.rotation.$model }}&#xb0;</label>
    <div class="flex align-items-center">
      <Button
        class="p-button-white p-button-text mr-2 p-1"
        @click="onRotateLeft"
      >
        <mdicon name="rotateLeft" />
      </Button>
      <Slider
        v-model="v$.rotation.$model"
        :min="0"
        :max="360"
        :step="90"
        class="mt-3 mb-4 flex-1"
      />
      <Button
        class="p-button-white p-button-text ml-2 p-1"
        @click="onRotateRight"
      >
        <mdicon name="rotateRight" />
      </Button>
    </div>
    <inline-form-error
      v-if="v$.rotation.$error"
      :errors="v$.rotation.$errors"
    />
  </div>
  <div class="field col-12 md:col-6">
    <label for="username">Mirror</label>
    <div>
      <InputSwitch
        v-model="v$.mirror.$model"
        :class="{ 'p-invalid': v$.mirror.$error }"
        class="mr-2"
        @change="$emit('update:modelValue', v$.mirror.$model)"
      />
      {{ v$.mirror.$model }}
    </div>
    <inline-form-error
      v-if="v$.mirror.$error"
      :errors="v$.mirror.$errors"
    />
  </div>
</template>

<script>
import { ref } from "vue"
import useVuelidate from "@vuelidate/core"
import InputSwitch from "primevue/inputswitch"

export default {
  components: { InputSwitch },
  props: {
    additionalFields: { rotation: Number, mirror: Boolean },
  },
  setup(props) {
    const additionalRules = { rotation: {}, mirror: {} }

    const v$ = useVuelidate(additionalRules, props.additionalFields)

    function onRotateLeft() {
      props.additionalFields.rotation = props.additionalFields.rotation - 90

      if (props.additionalFields.rotation < 0) {
        props.additionalFields.rotation += 360
      }
    }

    function onRotateRight() {
      props.additionalFields.rotation = props.additionalFields.rotation + 90

      if (props.additionalFields.rotation >= 360) {
        props.additionalFields.rotation -= 360
      }
    }

    return {
      v$,
      onRotateRight,
      onRotateLeft,
    }
  },
}
</script>
