<template>
  <Button class="p-button-text filter-button" @click="onFilter">
    <mdicon name="filterVariant" />
    <div v-if="activeFiltersCount > 0" class="active-filters-count">
      {{ activeFiltersCount }}
    </div>
  </Button>
  <OverlayPanel ref="filterOverlayComponent" class="fieldsFilter">
    <slot />
    <Button class="p-button-text p-2" @click="onCancelFilters"> Done </Button>
  </OverlayPanel>
</template>

<script>
import { ref } from "vue"

export default {
  name: "FilterOverlay",
  props: {
    activeFiltersCount: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const filterOverlayComponent = ref()

    function onFilter(event) {
      filterOverlayComponent.value.toggle(event)
    }

    function onCancelFilters() {
      filterOverlayComponent.value.toggle()
    }

    return {
      onCancelFilters,
      onFilter,
      filterOverlayComponent,
    }
  },
}
</script>

<style>
.fieldsFilter .p-overlaypanel-content {
  padding: 10px;
  margin-bottom: 0;
}

.filter-button {
  width: 48px;
  min-width: 0;
}

.active-filters-count {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
</style>
