import axios from "axios"
import ResultSet from "@/models/ResultSet"

export default class SettingService {
  pageSize: number = 20

  get(name: string = null) {
    let url = "defaultsettings"

    if (name !== null) {
      url += "/" + name
    }
    return axios.get(url, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  getAllByFilter(computerId: string, userId: string, organizationId: string) {
    let url = "settings/list/support"

    if (organizationId != null) {
      url += "?organizationId=" + organizationId
    }

    if (computerId != null) {
      url += organizationId != null ? "&" : "?"
      url += "computerId=" + computerId
    }

    if (userId != null) {
      url += organizationId != null || computerId != null ? "&" : "?"
      url += "userId=" + userId
    }

    url += "&size=" + 10000 + "&page=" + 0

    return axios.get(url, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  getAllRules(name: string, part: Number = 0, sort: any) {
    let url = "settings/all/support/?name=" + name + "&size=" + 1000 + "&page=" + part

    let oThis = this
    return axios
      .get(url, { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => new ResultSet<any>(res.data, 1000))
  }

  getRules(
    name: string,
    organizationId: string,
    computerId: string,
    userId: string,
    part: Number = 0,
    sort: any
  ) {
    let url = "settings/support/?name=" + name + "&size=" + this.pageSize + "&page=" + part

    if (organizationId != null) {
      url += "&organizationId=" + organizationId
    }

    if (computerId != null) {
      url += "&computerId=" + computerId
    }

    if (userId != null) {
      url += "&userId=" + userId
    }

    let oThis = this
    return axios
      .get(url, { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  add(
    settingName: string,
    value: string,
    organizationId: string,
    userId: string,
    computerId: string
  ) {
    let url = "settings/support"

    if (organizationId != null) {
      url += "?organizationId=" + organizationId
    }

    if (computerId != null) {
      url += organizationId != null ? "&" : "?"
      url += "computerId=" + computerId
    }

    if (userId != null) {
      url += organizationId != null || computerId != null ? "&" : "?"
      url += "userId=" + userId
    }

    return axios
      .post(
        url,
        {
          name: settingName,
          value: value,
        },
        { baseURL: process.env.VUE_APP_VSOL_API }
      )
      .then((res) => res.data)
  }

  editSetting(id: string, data: any) {
    let url = "settings/" + id

    return axios.put(url, data, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  editMultiple(ids: string[], settingName: string, settingValue: string) {
    let url = "settings/support?"

    for (let id of ids) {
      url += "&ids=" + id
    }

    return axios
      .put(
        url,
        {
          name: settingName,
          value: settingValue,
        },
        { baseURL: process.env.VUE_APP_VSOL_API }
      )
      .then((res) => res.data)
  }

  deleteSetting(id: string) {
    let url = "settings/" + id

    return axios.delete(url, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  deleteMultiple(ids: string[]) {
    let url = "settings?cascade=false"

    for (let id of ids) {
      url += "&ids=" + id
    }

    return axios.delete(url, { baseURL: process.env.VUE_APP_VSOL_API }).then((res) => res.data)
  }
}
