import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5fccf1e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AutoComplete, {
      class: _normalizeClass(["w-full", { 'p-invalid': $props.invalid }]),
      modelValue: $setup.selectedLutFile,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedLutFile) = $event)),
      suggestions: $setup.filteredFileOptions,
      dropdown: true,
      placeholder: $props.placeholder,
      onComplete: _cache[1] || (_cache[1] = ($event: any) => ($setup.searchLutFile($event))),
      field: (item) => item,
      forceSelection: "",
      onItemSelect: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $setup.selectedLutFile))),
      onItemUnselect: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:modelValue', $setup.selectedLutFile)))
    }, {
      item: _withCtx((slotProps) => [
        _createElementVNode("div", null, _toDisplayString(slotProps.item), 1)
      ]),
      _: 1
    }, 8, ["class", "modelValue", "suggestions", "placeholder", "field"]),
    ($setup.selectedLutFile)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          icon: "pi pi-times",
          class: "p-button-rounded p-button-secondary p-button-text clear",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:modelValue', null)))
        }))
      : _createCommentVNode("", true)
  ]))
}