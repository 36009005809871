import axios from "axios"
import ResultSet from "@/models/ResultSet"

export default class HardwareService {
  pageSize: number = 20
  protected readonly endpoint: string

  getPanels(part: Number, sort: string, filter: string, connectedComputer: string) {
    let oThis = this
    if (connectedComputer == null) {
      return axios
        .get("panels/support" + "?page=" + part + "&sort=" + sort + "&filter=" + filter, {
          baseURL: process.env.VUE_APP_VSOL_API,
        })
        .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
    } else {
      return axios
        .get(
          "panels/support/?computerId=" +
            connectedComputer +
            "&page=" +
            part +
            "&sort=" +
            sort +
            "&filter=" +
            filter,
          { baseURL: process.env.VUE_APP_VSOL_API }
        )
        .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
    }
  }

  // addPanel(name: string, number: string, serial: string, rank: string, wired: boolean, active: boolean, payload: any) {
  //     let url = this.endpoint + '/support';
  //     if (name != null) {
  //         payload.name = name;
  //     }
  //     if (number != null) {
  //         payload.number = number;
  //     }
  //     if (serial != null) {
  //         payload.serial = serial;
  //     }
  //     if (rank != null) {
  //         payload.rank = rank;
  //     }
  //     if (wired != null) {
  //         payload.wired = wired;
  //     }
  //     if (active != null) {
  //         payload.active = active;
  //     }
  //     return axios.post(url, payload, {baseURL: process.env.VUE_APP_VSOL_API}).then(res => res.data);
  // }
  //
  // editPanel(name: string, number: string, serial: string, rank: string, wired: boolean, active: boolean, payload: any) {
  //     payload.name = name;
  //     payload.number = number;
  //     payload.serial = serial;
  //     payload.rank = rank;
  //     payload.wired = wired;
  //     payload.active = active;
  //     return axios.put(this.endpoint + '/', payload, {baseURL: process.env.VUE_APP_VSOL_API});
  // }

  getMeasurers(part: Number, sort: string, filter: string, connectedComputer: string) {
    let oThis = this
    if (connectedComputer == null) {
      return axios
        .get("measurers/support" + "?page=" + part + "&sort=" + sort + "&filter=" + filter, {
          baseURL: process.env.VUE_APP_VSOL_API,
        })
        .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
    } else {
      return axios
        .get(
          "measurers/support/?computerId=" +
            connectedComputer +
            "&page=" +
            part +
            "&sort=" +
            sort +
            "&filter=" +
            filter,
          { baseURL: process.env.VUE_APP_VSOL_API }
        )
        .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
    }
  }

  // addMeasurer(port: string, active: boolean, calibration: number, payload: any) {
  //     let url = this.endpoint + '/support';
  //     if (port != null) {
  //         payload.port = port;
  //     }
  //
  //     if (active != null) {
  //         payload.active = active;
  //     }
  //
  //     if (calibration != null) {
  //         payload.calibration = calibration;
  //     }
  //     return axios.post(url, payload, {baseURL: process.env.VUE_APP_VSOL_API}).then(res => res.data);
  // }
  //
  // editMeasurer(port: string, active: boolean, calibration: number, payload: any) {
  //     payload.port = port;
  //     payload.active = active;
  //     payload.calibration = calibration;
  //     return axios.put(this.endpoint + '/', payload, {baseURL: process.env.VUE_APP_VSOL_API});
  // }

  getGenerators(part: Number, sort: string, filter: string, connectedComputer: string) {
    let oThis = this
    if (connectedComputer == null) {
      return axios
        .get("generators/support" + "?page=" + part + "&sort=" + sort + "&filter=" + filter, {
          baseURL: process.env.VUE_APP_VSOL_API,
        })
        .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
    } else {
      return axios
        .get(
          "generators/support/?computerId=" +
            connectedComputer +
            "&page=" +
            part +
            "&sort=" +
            sort +
            "&filter=" +
            filter,
          { baseURL: process.env.VUE_APP_VSOL_API }
        )
        .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
    }
  }

  getDicomApplicationEntityDetails(id: any) {
    let url = "dicomapplicationentities/" + id
    return axios.get(url, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  getDicomApplicationEntities(
    part: Number,
    sort: string,
    filter: string,
    connectedComputer: string,
    connectedOrganization: string
  ) {
    let oThis = this
    if (connectedComputer == null && connectedOrganization == null) {
      return axios
        .get(
          "dicomapplicationentities/list/support?page=" +
            part +
            "&sort=" +
            sort +
            "&filter=" +
            filter,
          {
            baseURL: process.env.VUE_APP_VSOL_API,
          }
        )
        .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
    } else if (connectedOrganization != null) {
      return axios
        .get(
          "dicomapplicationentities/list/support/?organizationId=" +
            connectedOrganization +
            "&page=" +
            part +
            "&sort=" +
            sort +
            "&filter=" +
            filter,
          {
            baseURL: process.env.VUE_APP_VSOL_API,
          }
        )
        .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
    } else {
      return axios
        .get(
          "dicomapplicationentities/list/support/?computerId=" +
            connectedComputer +
            "&page=" +
            part +
            "&sort=" +
            sort +
            "&filter=" +
            filter,
          {
            baseURL: process.env.VUE_APP_VSOL_API,
          }
        )
        .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
    }
  }

  addDicomApplicationEntity(computerId: string, organizationId: string, entity: any) {
    if (entity.purpose === "OUTGOING_STORE") {
      entity.purpose = "STORE_DCM"
    } else if (entity.purpose === "INCOMING_MWL") {
      entity.purpose = "FIND_MWL"
    } else if (
      entity.purpose === "INCOMING_STORE" ||
      entity.purpose === "OUTGOING_MWL" ||
      entity.purpose === "OUTGOING_MWL_INCOMING_STORE"
    ) {
      entity.purpose = "AQS"
    }

    let url = "dicomapplicationentities/support"
    if (computerId) {
      url += "?computerId=" + computerId
    } else if (organizationId) {
      url += "?organizationId=" + organizationId
    }

    return axios.post(url, entity, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  updateDicomApplicationEntity(id: string, entity: any) {
    let url = "dicomapplicationentities/" + id

    return axios.put(url, entity, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  deleteDicomApplicationEntity(id: string) {
    let url = "dicomapplicationentities/" + id

    return axios.delete(url, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  getProcedures(daeId: string) {
    return axios.get("procedures/" + daeId, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  addProcedure(daeId: string, procedure: any) {
    return axios.post("procedures?daeId=" + daeId, procedure, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  editProcedure(daeId: string, procedure: any) {
    return axios.put("procedures/" + daeId, procedure, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  deleteProcedure(daeId: string) {
    return axios.delete("procedures/" + daeId, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  getMappingsForDae(daeId: string) {
    let url = "dicommappingconfigs/list/dae?daeId=" + daeId

    return axios.get(url, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  getTagConfigsForDae(daeId: string) {
    let url = "dicomtagconfigs/list/dae?daeId=" + daeId

    return axios.get(url, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  // addGenerator(port: string, modulePort: string, active: boolean, moduleActive: boolean, type: string, payload: any) {
  //     let url = this.endpoint + '/support';
  //     if (port != null) {
  //         payload.port = port;
  //     }
  //     if (modulePort != null) {
  //         payload.modulePort = modulePort;
  //     }
  //     if (active != null) {
  //         payload.active = active;
  //     }
  //     if (moduleActive != null) {
  //         payload.moduleActive = moduleActive;
  //     }
  //     if (type != null) {
  //         payload.type = type;
  //     }
  //     return axios.post(url, payload, {baseURL: process.env.VUE_APP_VSOL_API});
  // }
  //
  // editGenerator(port: string, modulePort: string, active: boolean, moduleActive: boolean, type: string, payload: any) {
  //     payload.port = port;
  //     payload.modulePort = modulePort;
  //     payload.active = active;
  //     payload.moduleActive = moduleActive;
  //     payload.type = type;
  //     return axios.put(this.endpoint + '/', payload, {baseURL: process.env.VUE_APP_VSOL_API});
  // }
}
