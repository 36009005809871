import User from "@/models/User"
import { Assignee, Label, Organization, ParentTask, Task } from "@/models/Task"

export class SelectedTask extends Task {
  public overdue: boolean

  constructor(
    public assignees: Assignee[],
    public calendar: boolean,
    public category: string,
    public numberOfChildren: number,
    public completedBy: User,
    public completionTime: string,
    public computer: any,
    public createdBy: User,
    public createdTime: Date,
    public deadline: string,
    public deleted: boolean,
    public description: string,
    public id: string,
    public labels: Label[],
    public marked: boolean,
    public numberOfDependencies: number,
    public organization: Organization,
    public parent: ParentTask,
    public plannedDate: string,
    public priority: string,
    public subject: string,
    public updatedTime: Date,
    public watchers: User[],
    public selected: boolean
  ) {
    super(
      assignees,
      calendar,
      category,
      numberOfChildren,
      completedBy,
      completionTime,
      computer,
      createdBy,
      createdTime,
      deadline,
      deleted,
      description,
      id,
      labels,
      marked,
      numberOfDependencies,
      organization,
      parent,
      plannedDate,
      priority,
      subject,
      updatedTime,
      watchers
    )
    this.overdue = false
  }

  static from(task: Task, selected: boolean): SelectedTask {
    return new SelectedTask(
      task.assignees,
      task.calendar,
      task.category,
      task.numberOfChildren,
      task.completedBy,
      task.completionTime,
      task.computer,
      task.createdBy,
      task.createdTime,
      task.deadline,
      task.deleted,
      task.description,
      task.id,
      task.labels,
      task.marked,
      task.numberOfDependencies,
      task.organization,
      task.parent,
      task.plannedDate,
      task.priority,
      task.subject,
      task.updatedTime,
      task.watchers,
      selected
    )
  }

  toString() {
    return super.toString() + ", " + this.selected
  }
}
