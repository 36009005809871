
import { RouterLink } from "vue-router"
import { defineComponent, watch } from "vue"
import ComputerRunConfiguration from "@/components/computers/tools/ComputerRunConfiguration.vue"
import ComputerToolkitVersion from "@/components/computers/tools/ComputerToolkitVersion.vue"
import ComputerService from "@/services/ComputerService"
import { ref, reactive } from "vue"
import Button from "primevue/button"
import { state } from "@/utils/state"
import NetworkService from "@/services/NetworkService"
import router from "@/router/router"

export default defineComponent({
  components: { ComputerRunConfiguration, ComputerToolkitVersion },
  props: {
    computerWrapper: Object,
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "reload"],
  setup(props, { emit }) {
    const computerService = new ComputerService()
    let computerDetails = ref()
    const hasAnomalities = ref(false)
    const hasTeamviewer = ref(true)
    const hasAnyDesk = ref(true)
    const isOnlineInTeamviewer = ref(false)
    const isOnlineInAnydesk = ref(false)
    const confirmDeleteDialogVisible = ref(false)
    const networkService = new NetworkService()
    let anomalyList = reactive([])

    watch(state.onlineTeamviewer, (newValue) => {
      isOnlineInTeamviewer.value =
        newValue.indexOf(computerDetails.value?.teamViewerClientId) !== -1
    })

    watch(state.onlineAnydesk, (newValue) => {
      isOnlineInAnydesk.value = newValue.indexOf(computerDetails.value?.anyDeskClientId) !== -1
    })

    watch(
      () => props.computerWrapper,
      () => {
        getComputerDetails()
      }
    )

    getComputerDetails()
    function getComputerDetails() {
      anomalyList = []
      hasTeamviewer.value = true
      hasAnyDesk.value = true

      computerService.getComputer(props.computerWrapper?.id).then((response) => {
        computerDetails.value = response.data

        if (!computerDetails.value.teamViewerClientId) {
          hasTeamviewer.value = false
        }
        if (!computerDetails.value.anyDeskClientId) {
          hasAnyDesk.value = false
        }
        props.computerWrapper.toolkitVersion = computerDetails.value.toolkitVersion
        props.computerWrapper.toolkitTargetVersion = computerDetails.value.toolkitTargetVersion
        props.computerWrapper.currentVersion = computerDetails.value.currentVersion
        props.computerWrapper.targetVersion = computerDetails.value.targetVersion

        if (state.onlineTeamviewer && state.onlineTeamviewer.length) {
          isOnlineInTeamviewer.value =
            state.onlineTeamviewer.indexOf(computerDetails.value.teamViewerClientId) !== -1
        }

        if (state.onlineAnydesk && state.onlineAnydesk.length) {
          isOnlineInAnydesk.value =
            state.onlineAnydesk.indexOf(computerDetails.value.anyDeskClientId) !== -1
        }

        checkForAnomalies()
      })
    }

    function checkForAnomalies() {
      const pcPhysicalMemoryTotal : number = convertBytesToGB(computerDetails.value.pcPhysicalMemoryTotal)
      const cDiskFreeSpace : number = convertBytesToGB(computerDetails.value.cdiskFreeSpace)
      const rDiskFreeSpace : number = convertBytesToGB(computerDetails.value.rdiskFreeSpace)

      if (computerDetails.value.pcHostName !== "VSOL") {
        hasAnomalities.value = true
        anomalyList.push("Hostname is not VSOL")
      }

      if (computerDetails.value.osFirewallDomain == true) {
        hasAnomalities.value = true
        anomalyList.push("Firewall Domain is set to true")
      }

      if (computerDetails.value.osFirewallPrivate == true) {
        hasAnomalities.value = true
        anomalyList.push("Firewall Private is set to true")
      }

      if (computerDetails.value.osFirewallPublic == true) {
        hasAnomalities.value = true
        anomalyList.push("Firewall Public is set to true")
      }

      if (computerDetails.value.osArchitecture === "32-bits") {
        hasAnomalities.value = true
        anomalyList.push("OS Architecture is 32-bits")
      }

      if (
        computerDetails.value.osDepStatus != "AlwaysOff" &&
        computerDetails.value.systemMode == "AQS"
      ) {
        hasAnomalities.value = true
        anomalyList.push("DEP-status is not always off for AQS")
      }

      if (pcPhysicalMemoryTotal < 5) {
        hasAnomalities.value = true
        anomalyList.push("Physical memory is below 5 GB")
      }

      if (computerDetails.value.cdiskSize > 0 && cDiskFreeSpace < 10) {
        hasAnomalities.value = true
        anomalyList.push("C-disk free space is below 10 GB")
      }

      if (computerDetails.value.rdiskSize > 0 && rDiskFreeSpace < 10) {
        hasAnomalities.value = true
        anomalyList.push("R-disk free space is below 10 GB")
      }

      for (let battery of computerDetails.value.batteries) {
        if (battery.fullChargeCapacity < battery.designCapacity / 2) {
          hasAnomalities.value = true
          anomalyList.push("The battery full charge capacity is below the designcapacity")
        }
      }
    }

    let anomaliesPanel = ref()
    function showAnomalyOverlay(event: any) {
      anomaliesPanel.value.show(event)
      event.stopPropagation()
    }

    function hideAnomalyOverlay(event: any) {
      anomaliesPanel.value.hide(event)
    }

    function onClickTeamviewer(event: any) {
      if (hasTeamviewer.value) {
        window.open(
          "tvcontrol1://control?device=" + computerDetails.value.teamViewerClientId,
          "_blank"
        )
      }
      event.stopPropagation()
    }

    function onClickAnyDesk(event: any) {
      if (hasAnyDesk.value) {
        window.open("anydesk:" + computerDetails.value.anyDeskClientId, "_blank")
      }
      event.stopPropagation()
    }

    function deleteComputer() {
      confirmDeleteDialogVisible.value = true
    }

    function closeConfirmDeleteDialog() {
      confirmDeleteDialogVisible.value = false
    }

    function doDelete() {
      networkService.deleteNetwork(props.computerWrapper.id).then(() => {
        emit("reload")
      })
    }

    function openComputer() {
      router.push({
        name: "computerProfile",
        params: { computer_id: props.computerWrapper.id },
      })
    }

    function convertBytesToGB(bytes : number) : number {
      if (bytes === null) return null;
      return (bytes / (1024 * 1024 * 1024))
    }

    return {
      onClickTeamviewer,
      onClickAnyDesk,
      computerDetails,
      hasAnomalities,
      isOnlineInTeamviewer,
      deleteComputer,
      confirmDeleteDialogVisible,
      closeConfirmDeleteDialog,
      doDelete,
      isOnlineInAnydesk,
      getComputerDetails,
      anomalyList,
      showAnomalyOverlay,
      hideAnomalyOverlay,
      anomaliesPanel,
      hasTeamviewer,
      hasAnyDesk,
      openComputer,
    }
  },
})
