export default function useRoleUtils() {
  const supportedRoles = [
    { label: "Support", value: "SUPPORT", icon: "faceAgent" },
    { label: "Developer", value: "DEVELOPER", icon: "xml" },
    { label: "Admin", value: "ADMIN", icon: "crown" },
  ]

  return {
    supportedRoles,
  }
}
