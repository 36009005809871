import axios from "axios"
import ResultSet from "@/models/ResultSet"
import ChangeCompanyService from "./ChangeCompanyService"

export default class ComputerService extends ChangeCompanyService {
  constructor() {
    super("computers")
  }

  getComputers(part: Number = 0, sort: any, filter: string, version?: string): Promise<ResultSet<any>> {
    let oThis = this
    let url = "computers?size=" + 10000 + "&page=" + part + "&sort=" + sort
    if (filter) {
      url += "&filter=" + filter
    }

    if (version) {
      url += "&currentVersion=" + version
    }

    return axios.get(url).then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  getUsersForComputer(computerId: string, part: Number = 0) {
    let oThis = this
    return axios
      .get("computers/" + computerId + "/users", { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  getOrganizationsForComputer(computerId: string, part: Number = 0) {
    let oThis = this
    return axios
      .get("computers/" + computerId + " /networks?size=" + this.pageSize + "&page=" + part, {
        baseURL: process.env.VUE_APP_VSOL_API,
      })
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  // getDicomMapping(id: string) {
  //   return axios.get('dicommappings/?id=' + id, {
  //     baseURL: process.env.VUE_APP_VSOL_API,
  //   })
  // }
  //
  addDicomMapping(id: string, mappingData: any) {
    return axios.post("dicommappings?dcID=" + id, mappingData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  deleteDicomMapping(id: string) {
    return axios.delete("dicommappings/" + id, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  updateDicomMapping(id: string, mappingData: any) {
    return axios.put("dicommappings/" + id, mappingData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  // getDicomRules(id: string) {
  //   return axios.get('dicomrules/?id=' + id, {
  //     baseURL: process.env.VUE_APP_VSOL_API,
  //   })
  // }
  //
  addDicomRules(dcId: string, ruleData: any) {
    return axios.post("dicomrules?dcID=" + dcId, ruleData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  deleteDicomRule(id: string) {
    return axios.delete("dicomrules/" + id, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  updateDicomRule(id: string, dcData: any) {
    return axios.put("dicomrules/" + id, dcData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  getDicomTagConfig(id: string) {
    return axios.get("dicomtagconfigs/" + id, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  deleteDicomTagConfig(id: string) {
    return axios.delete("dicomtagconfigs/" + id, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  getDicomTagConfigsForComputer(computerId: string, part: Number = 0) {
    return axios.get("dicomtagconfigs/list/support?computerId=" + computerId + "&part=" + part, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  addDicomTagConfigsForComputer(computerId: string, configData: any) {
    return axios.post("dicomtagconfigs/support?computerId=" + computerId, configData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  updateDicomTagConfigs(configIds: [], configData: any) {
    return axios.put("dicomtagconfigs/support?ids=" + configIds, configData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  getDicomMappingConfig(id: string) {
    return axios.get("dicommappingconfigs/" + id, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  deleteDicomMappingConfig(id: string) {
    return axios.delete("dicommappingconfigs/" + id, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  getDicomMappingConfigsForComputer(computerId: string, part: Number = 0) {
    return axios.get(
      "dicommappingconfigs/list/support?computerId=" + computerId + "&part=" + part,
      {
        baseURL: process.env.VUE_APP_VSOL_API,
      }
    )
  }

  addDicomMappingConfigForComputer(computerId: string, configData: any) {
    return axios.post("dicommappingconfigs/support?computerId=" + computerId, configData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  updateDicomMappingConfigs(configIds: [], configData: any) {
    return axios.put("dicommappingconfigs/support?ids=" + configIds, configData, {
      baseURL: process.env.VUE_APP_VSOL_API,
    })
  }

  setTargetRunConfiguration(targetVersion: string, ids: string[]) {
    let url = "computers/targetrunconfiguration?runConfiguration=" + targetVersion

    return axios.put(url, ids).then((res) => res.data)
  }

  setTargetToolkitVersion(targetToolkitVersion: string, ids: string[]) {
    let url = "computers/toolkittargetversion?toolkittargetversion=" + targetToolkitVersion

    return axios.put(url, ids).then((res) => res.data)
  }

  getComputer(computerID: string) {
    return axios.get("computers/" + computerID)
  }

  addComputer(computerData: any, companyId: string) {
    let url = "computers"

    if (companyId) {
      url += "?companyId=" + companyId
    }

    return axios.post(url, computerData).then((res) => res.data)
  }

  updateComputer(computerID: string, computerData: any) {
    return axios.put("computers/" + computerID, computerData)
  }

  deleteComputer(computerID: string) {
    return axios.delete("computers/" + computerID)
  }

  getAllComputers() {
    return axios.get<any[]>("computers/list").then((res) => res.data)
  }

  getComputerLogs(computerId: string, part: number = 0, sort: any) {
    let url: string =
      "computerlogs/support?computerId=" + computerId + "&size=" + this.pageSize + "&page=" + part

    if (sort) {
      url += "&sort=" + sort
    } else {
      url += "&sort=createdTime,desc"
    }

    return axios
      .get(url, { baseURL: process.env.VUE_APP_VSOL_API })
      .then((res) => new ResultSet<any>(res.data, this.pageSize))
  }

  deleteComputerLog(logId: string) {
    let url: string = "computerlogs/" + logId + "/support"
    return axios.delete(url, { baseURL: process.env.VUE_APP_VSOL_API }).then((res) => res)
  }
}
