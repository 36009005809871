
import { computed, defineComponent, ref } from "vue"

export default defineComponent({
  name: "DataCollectionCard",
  props: {
    id: String,
    selected: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: true,
    },
    to: {
      type: Object,
      default: null,
    },
    gridItemClass: String,
  },
  emits: ["selection-changed"],
  setup(props, { emit }) {
    const classes = computed(() =>
      props.selected ? props.gridItemClass + " selected" : props.gridItemClass
    )

    function onClick(itemID: string) {
      if (!props.selectable) return

      toggleSelection(itemID)
      return true
    }

    function toggleSelection(itemID: string) {
      emit("selection-changed", itemID, !props.selected)
    }

    return {
      classes,
      onClick,
      toggleSelection,
    }
  },
})
