import axios from "axios"
import ResultSet from "@/models/ResultSet"

export default class NetworkService {
  pageSize: number = 20

  /*   getComputerOrganizations(
    computerID: string,
    part: Number = 0,
    sort: any,
    filter: string
  ): Promise<ResultSet> {
    let oThis = this
    return axios
      .get(
        "computers/" +
          computerID +
          " /networks?size=" +
          this.pageSize +
          "&page=" +
          part +
          "&sort=" +
          sort +
          "&filter=" +
          filter,
        { baseURL: process.env.VUE_APP_VSOL_API }
      )
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  } */

  getOrganizationComputerList(id: string) {
    let url = "organizations/" + id + "/computers"
    return axios.get(url).then((res) => res.data)
  }

  getOrganizationComputers(organizationID: string, part: Number = 0, sort: any) {
    let oThis = this
    return axios
      .get(
        "organizations/" +
          organizationID +
          "/networks?size=" +
          this.pageSize +
          "&page=" +
          part +
          "&sort=" +
          sort,
        { baseURL: process.env.VUE_APP_VSOL_API }
      )
      .then((res) => new ResultSet<any>(res.data, oThis.pageSize))
  }

  addNetwork(organizationID: string, computerId: string) {
    return axios
      .post(
        "networks?organizationId=" + organizationID + "&computerId=" + computerId,
        {},
        { baseURL: process.env.VUE_APP_VSOL_API }
      )
      .then((res) => res.data)
  }

  deleteNetwork(roleId: string) {
    return axios.delete("networks/" + roleId, { baseURL: process.env.VUE_APP_VSOL_API })
  }

  deleteMultiple(networkIds: string[]) {
    let url = "networks?cascade=false"

    for (let id of networkIds) {
      url += "&ids=" + id
    }

    return axios.delete(url, { baseURL: process.env.VUE_APP_VSOL_API })
  }
}
