
import { ref, defineComponent, reactive } from "vue"
import { helpers, maxValue, minValue, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import InputSwitch from "primevue/inputswitch"
import InputNumber from "primevue/inputnumber"
import useTimeUtils from "@/utils/time"
import SystemModeDropdown from "@/components/computers/tools/SystemModeDropdown.vue"
import ComputerCloneDropdown from "@/components/computers/tools/ComputerCloneDropdown.vue"
import ComputerService from "@/services/ComputerService"
import { useRouter } from "vue-router"
import { useConfirm } from "primevue/useconfirm"
import PrimaryButton from "../buttons/PrimaryButton.vue"
import CompaniesService from "@/services/CompaniesService"
import store from "@/store"

export default defineComponent({
  components: {
    ComputerCloneDropdown,
    SystemModeDropdown,
    InputSwitch,
    InputNumber,
    PrimaryButton,
  },
  props: {
    computer: {
      type: Object,
      default: null,
    },
    selectedSystemMode: {
      type: String,
      default: null,
    },
  },
  emits: ["submit", "cancel"],
  setup(props, { emit }) {
    const { absoluteDateTime } = useTimeUtils()
    const computerService = new ComputerService()
    const companyService = new CompaniesService()
    const router = useRouter()
    const confirm = useConfirm()
    const companies = reactive([])
    const selectedCompany = ref()
    const savingAttempt = ref(false)
    const companyRequiredMessage = ref("Company is required")
    const done = ref(false)

    function handleDefaultCompany() {
      companies.forEach((company: any) => {
        if (store.getters.getDefaultCompanyId === company.id) {
          selectedCompany.value = company
        }
      })
    }

    const form = ref({
      serial: props.computer == null ? "" : props.computer.serial,
      alias: props.computer == null ? "" : props.computer.alias,
      systemMode: props.computer == null ? "" : props.computer.systemMode,
      cloneType: props.computer == null ? "" : props.computer.cloneType,
      comments: props.computer == null ? "" : props.computer.comments,
      claConsoleVersion: props.computer == null ? "" : props.computer.claConsoleVersion,
      claRuVersion: props.computer == null ? "" : props.computer.claRuVersion,
      claRuPcToolVersion: props.computer == null ? "" : props.computer.claRuPcToolVersion,
      claMenusVersion: props.computer == null ? "" : props.computer.claMenusVersion,
      hotspotTaped: props.computer == null ? null : props.computer.hotspotTaped,
      toolkitVersion: props.computer == null ? null : props.computer.toolkitVersion,
      anyDeskClientId: props.computer == null ? null : props.computer.anyDeskClientId,
      teamViewerClientId: props.computer == null ? null : props.computer.teamViewerClientId,
      anyDeskVersion: props.computer == null ? null : props.computer.anyDeskVersion,
      teamViewerVersion: props.computer == null ? null : props.computer.teamViewerVersion,
      anyDeskInstallationPrefix:
        props.computer == null ? null : props.computer.anyDeskInstallationPrefix,
      betaTag: props.computer == null ? 0 : props.computer.betaTag,
      panelType: props.computer == null ? null : props.computer.panelType,
      generatorType: props.computer == null ? null : props.computer.generatorType,
      tru: props.computer == null ? false : props.computer.tru,
    })

    const rules = {
      serial: { codeRequired: helpers.withMessage("The serial is required", required) },
      alias: {},
      systemMode: {},
      cloneType: {},
      comments: {},
      claConsoleVersion: {},
      claRuVersion: {},
      claRuPcToolVersion: {},
      claMenusVersion: {},
      hotspotTaped: {},
      toolkitVersion: {},
      anyDeskClientId: {},
      teamViewerClientId: {},
      anyDeskVersion: {},
      teamViewerVersion: {},
      anyDeskInstallationPrefix: {},
      betaTag: {
        minValue: helpers.withMessage(
          "the beta tag has to be a number between 0 and 5",
          minValue(0)
        ),
        maxValue: helpers.withMessage(
          "the beta tag has to be a number between 0 and 5",
          maxValue(5)
        ),
      },
      panelType: {},
      generatorType: {},
      tru: {},
    }

    const betaTagOptions = reactive([0, 1, 2, 3, 4, 5])

    const v$: any = useVuelidate(rules, form)

    if (!props.computer) {
      companyService.getCompanies().then((result: any) => {
        if (result.data && result.data.length) {
          result.data.forEach((company: any) => {
            companies.push(company)
          })
          handleDefaultCompany()
        }
      })
    }

    function onSubmit() {
      v$.value.$touch()

      if (v$.value.$invalid) return

      if (props.computer) {
        emit("submit", form.value)
      } else {
        if (!selectedCompany.value) {
          savingAttempt.value = true
          return
        }
        emit("submit", form.value, selectedCompany.value)
      }
    }

    function onDone() {
      done.value = true
      setTimeout(() => {
        done.value = false
      }, 2500)
    }

    function onDelete() {
      computerService.deleteComputer(props.computer.id).then(() => {
        router.push({ name: "computers" })
      })
    }

    function onConfirmDelete(event: any) {
      confirm.require({
        target: event.currentTarget,
        header: "Delete computer",
        message: "Are you sure you want to remove this computer?",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-danger",
        accept: onDelete,
      })
    }

    return {
      done,
      v$,
      onSubmit,
      onDone,
      absoluteDateTime,
      betaTagOptions,
      onConfirmDelete,
      selectedCompany,
      savingAttempt,
      companyRequiredMessage,
      companies,
    }
  },
})
