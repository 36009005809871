
import { nextTick, onMounted, onUnmounted, reactive, ref, watch } from "vue"
import { helpers, minLength, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import RolesService from "@/services/RolesService"
import UserService from "@/services/UserService"
import PrimaryButton from "../buttons/PrimaryButton.vue"
import ResultSet from "@/models/ResultSet"
import LinkLabel from "../navigation/LinkLabel.vue"

export default {
  props: {
    employee: {
      type: Object,
      default: null,
    },
    company: {
      type: Object,
      required: true,
    },
    roleOptions: {
      type: Array,
      default: [],
    },
  },
  components: { LinkLabel, PrimaryButton },
  emits: ["submit"],
  setup(props: any, { emit }: any) {
    const filter = ref("")
    const timeout = ref(null)
    const userService = new UserService()
    const scrollComponent = ref()
    const loading = ref(false)
    const loadedAllData = ref(false)
    const selectedItems = ref()
    const sort = ref("")
    let part = 0

    const newUserForm = ref({
      firstName: props.employee == null ? "" : props.employee.firstName,
      lastName: props.employee == null ? "" : props.employee.lastName,
      username: props.employee == null ? "" : props.employee.username,
      email: props.employee == null ? "" : props.employee.email,
      password: props.employee == null ? "" : props.employee.password,
      role: props.employee == null ? "" : props.employee.role,
    })

    const rules = {
      firstName: { codeRequired: helpers.withMessage("The first name is required", required) },
      lastName: { codeRequired: helpers.withMessage("The last name is required", required) },
      username: { codeRequired: helpers.withMessage("The username is required", required) },
      email: { codeRequired: helpers.withMessage("The email address is required", required) },
      password:
        props.employee != null
          ? {}
          : {
              required: helpers.withMessage("The password is required", required),
              minLength: helpers.withMessage(
                "The password needs to be atleast 5 characters",
                minLength(5)
              ),
            },
      role: { roleRequired: helpers.withMessage("The role is required", required) },
    }

    const v$: any = useVuelidate(rules, newUserForm)

    function createAndAdd() {
      v$.value.$touch()
      if (v$.value.$invalid) return
      const newEmployee = {
        firstName: newUserForm.value.firstName,
        lastName: newUserForm.value.lastName,
        username: newUserForm.value.username,
        email: newUserForm.value.email,
        code: newUserForm.value.firstName.charAt(0) + newUserForm.value.lastName.charAt(0),
        password: newUserForm.value.password,
      }
      userService.addErpUser(props.company.id, newEmployee).then((result) => {
        emit("submit", props.company.id, result, newUserForm.value.role)
      })
    }

    onMounted(() => {
      scrollComponent.value = document.querySelector(
        "div.add-user-datatable div.p-datatable-wrapper"
      )
      if (scrollComponent.value) {
        scrollComponent.value.addEventListener("scroll", checkVisible, { passive: true })
      }

      window.addEventListener("resize", checkVisible, { passive: true })
    })

    onUnmounted(() => {
      if (scrollComponent.value) {
        scrollComponent.value.removeEventListener("scroll", checkVisible)
      }

      window.removeEventListener("resize", checkVisible)
    })

    watch(
      () => sort.value,
      () => {
        reload()
      }
    )

    watch(
      () => selectedItems.value,
      () => {
        showSelectionError.value = false
      }
    )

    function reload() {
      part = 0
      selectedItems.value = null
      loadedAllData.value = false
      users.value = []
      loadPart(part, sort.value, filter.value)
    }

    loadPart(part, sort.value, filter.value)

    const users = ref([])
    const existingUserRole = ref("")

    function loadPart(part: Number, sort: any, filter: string) {
      loading.value = true
      userService.getErpUsers(part, sort, filter).then((result) => {
        onPartLoaded(result)
      })
    }

    function onPartLoaded(result: ResultSet<any>) {
      loadedAllData.value = !result.moreToCome
      loading.value = false

      if (result.rows.length == 0) {
        return
      }

      part++
      result.rows.map(function (element) {
        let item = { selected: false, data: element }
        users.value.push(item)
      })

      nextTick().then(() => {
        checkVisible()
      })
    }

    function checkVisible() {
      if (loadedAllData.value) return
      if (loading.value) return

      if (
        Math.ceil(scrollComponent.value.scrollTop * 1.3) >=
        scrollComponent.value.scrollHeight - scrollComponent.value.clientHeight
      ) {
        loadPart(part, sort.value, filter.value)
      }
    }

    const showSelectionError = ref(false)
    function addEmployee() {
      if (!selectedItems.value || !selectedItems.value.length) {
        showSelectionError.value = true
        return
      }

      if (existingUserRole.value) {
        for (const item of selectedItems.value) {
          emit("submit", props.company.id, item.data.id, existingUserRole.value)
        }
      } else {
        showSelectionError.value = true
        return
      }
    }

    function search() {
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        reload()
      }, 300)
    }

    const sortColumn = (event: any) => {
      if (event.value) {
        sort.value = event.value
      } else {
        sort.value = event.sortField + "," + (event.sortOrder == 1 ? "desc" : "asc")
      }
    }

    function handleAddButtonDisabled() {
      if (existingUserRole.value && selectedItems.value && selectedItems.value.length > 0) {
        return false
      } else {
        return true
      }
    }

    return {
      v$,
      createAndAdd,
      filter,
      search,
      loading,
      selectedItems,
      scrollComponent,
      sort,
      sortColumn,
      existingUserRole,
      users,
      addEmployee,
      showSelectionError,
      handleAddButtonDisabled,
    }
  },
}
