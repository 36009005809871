
import LinkLabel from "../navigation/LinkLabel.vue"
import { defineComponent } from "vue"
export default defineComponent({
  components: { LinkLabel },
  props: {
    role: Object,
  },
  setup(props) {},
})
