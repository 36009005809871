import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64dfac36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "call-history-item-container" }
const _hoisted_2 = { class: "company" }
const _hoisted_3 = { class: "mini-link-label" }
const _hoisted_4 = {
  key: 0,
  class: "initials-recipient"
}
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$props.incomingCall.organization)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "unknown-caller call-history-item",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.openAddContactDialog && $setup.openAddContactDialog(...args)))
        }, [
          _createVNode(_component_mdicon, { name: "accountQuestion" })
        ]))
      : _createCommentVNode("", true),
    ($props.incomingCall.organization)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "known-caller call-history-item",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.goToOrg && $setup.goToOrg(...args)))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString($props.incomingCall.organization.company.code), 1)
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString($props.incomingCall.organization.code), 1),
          ($setup.answeredRecipients && $setup.answeredRecipients.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.answeredRecipients, (callRecipient) => {
                  return (_openBlock(), _createElementBlock("div", null, [
                    (callRecipient.userId && $props.supportEmployees?.find((employee) => employee.id === callRecipient.userId))
                      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($props.supportEmployees.find((employee) => employee.id === callRecipient.userId).code), 1))
                      : _createCommentVNode("", true)
                  ]))
                }), 256))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}