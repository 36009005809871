export const openInNewScreen = (
  event: MouseEvent,
  url: string,
  callback: (success: boolean) => void
) => {
  if (event.ctrlKey && !event.shiftKey) {
    window.open(url, "_blank")
    callback(true)
  } else if (event.shiftKey && !event.ctrlKey) {
    window.open(url, "_blank", "fullscreen=yes")
    callback(true)
  } else {
    callback(false)
  }
}
