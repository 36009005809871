
import useVuelidate from "@vuelidate/core"
import LutFileAutocomplete from "../luts/LutFileAutocomplete.vue"
import { ref } from "vue"

export default {
  props: {
    additionalFields: { lutFile: "" },
  },
  setup(props: any) {
    const additionalRules = { lutFile: {} }
    const v$ = useVuelidate(additionalRules, props.additionalFields)

    return { v$ }
  },
  components: { LutFileAutocomplete },
}
