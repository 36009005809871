export const userEmailsForWeekview = [
  "arno.vrancken@veterinarysolutions.be",
  "boekhouding@veterinarysolutions.be",
  "david.paeps@veterinarysolutions.be",
  "pieter.olaerts@veterinarysolutions.be",
  "lode.leen@veterinarysolutions.be",
  "william.kriekels@veterinarysolutions.be",
  "jan.geurts@veterinarysolutions.be",
  "lennert.fonteyne@veterinarysolutions.be",
  "miguel.fernandezperez@veterinarysolutions.be",
  "filip.degryse@veterinarysolutions.be",
  "benoit.deckers@veterinarysolutions.be",
  "bram.corijn@veterinarysolutions.be",
  "bjarni.bosmans@veterinarysolutions.be",
  "jurien.beckers@veterinarysolutions.be",
  "administratie@veterinarysolutions.be",
  "cedric.wens@veterinarysolutions.be",
  "bert.gybels@veterinarysolutions.be",
  //"info@veterinarysolutions.be",
]

export const employeesCallList = [
  "benoit.deckers@veterinarysolutions.be",
  "david.paeps@veterinarysolutions.be",
  "filip.degryse@veterinarysolutions.be",
  "lennert.fonteyne@veterinarysolutions.be",
  "william.kriekels@veterinarysolutions.be",
  "lode.leen@veterinarysolutions.be",
  "bram.corijn@veterinarysolutions.be",
  "bert.gybels@veterinarysolutions.be",
  "cedric.wens@veterinarysolutions.be",
]
