import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-03eb4730"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-item-dropdown-left" }
const _hoisted_2 = {
  key: 1,
  class: "menu-item"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mdicon = _resolveComponent("mdicon")!
  const _component_NavigationPanelMenuItem = _resolveComponent("NavigationPanelMenuItem", true)!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.item.items && _ctx.item.items.length > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass({ menuOpen: _ctx.menuVisible })
        }, [
          _createElementVNode("div", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args))),
            class: "menu-item-dropdown"
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("span", {
                style: _normalizeStyle('min-width:' + _ctx.widthSpacer + 'px;')
              }, null, 4),
              (_ctx.item.icon)
                ? (_openBlock(), _createBlock(_component_mdicon, {
                    key: 0,
                    name: _ctx.item.icon
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(_ctx.item.label), 1)
            ]),
            _createVNode(_component_mdicon, { name: _ctx.openMenuIcon }, null, 8, ["name"])
          ]),
          _createElementVNode("div", {
            class: _normalizeClass({ hidden: !_ctx.menuVisible })
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.items, (subItem) => {
              return (_openBlock(), _createElementBlock("div", null, [
                _createVNode(_component_NavigationPanelMenuItem, {
                  item: subItem,
                  widthSpacer: _ctx.spacerWidth,
                  onOpenMenu: _ctx.makeMenuVisible
                }, null, 8, ["item", "widthSpacer", "onOpenMenu"])
              ]))
            }), 256))
          ], 2)
        ], 2))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives((_openBlock(), _createBlock(_component_router_link, {
            to: _ctx.item.to,
            class: "menu-item-link p-ripple"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: "menu-item-space",
                style: _normalizeStyle('min-width:' + _ctx.widthSpacer + 'px;')
              }, null, 4),
              (_ctx.item.icon)
                ? (_openBlock(), _createBlock(_component_mdicon, {
                    key: 0,
                    name: _ctx.item.icon
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(_ctx.item.label), 1)
            ]),
            _: 1
          }, 8, ["to"])), [
            [_directive_ripple]
          ])
        ]))
  ]))
}